<template>
<div class="empty-folder" :style="{height: calHeight}">
  <div class="empty-info">
    <img src="../../../assets/images/icon-empty-folder.png" alt="">
    <div class="empty-title">Folder empty</div>
    <div class="empty-note">
      Manually populate with Drag-and-Drop
      <span v-if="pEdit">
        <span class="add-rule" @click="addRule">or add Rules</span>
      </span>
      to this folder.
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['isFiltered', 'addRule', 'pEdit'],
  computed: {
    calHeight () {
      if (this.isFiltered) {
        return 'calc(100vh - 295px)'
      } else {
        return 'calc(100vh - 247px)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty{

  &-folder {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
  }

  &-info {
    text-align: center;

    img {
      width: 120px;
      height: auto;
    }
  }

  &-title {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
    margin: 42px 0 8px;
  }

  &-note {
    font-size: 14px;

    .add-rule {
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}
</style>

