import api from '@/fetch/api'
import CommentHelper from '@/helpers/comment.helper';
import util from 'util';
import appConstant from '@/common/constants/app.constant'

const state = {
  notifications: [],
  unreadCount: 0
}

const getters = {
  notificationUnreadCount: state => state.unreadCount,
  notifications: state => state.notifications,
  formattedNotifications: state => {
    return state.notifications.map(notification => {
      notification.html = CommentHelper.format2html(notification.content) 
      notification.relativeTime = util.relativeTime(notification.created_at)
      return notification
    })
  }
}

const actions = {
  async getNotifications({ commit, state }, params={}) {
    let notifications = []
    !params.no_cache && util.getDataFromSw('notifications', data => {
      notifications = data ? data.notifications : [];
      commit('setNotifications', notifications)
      commit('setUnreadCount', data ? data.unread : 0)
    });
    const res = await api.get_notifications(params)
    if (!res) return;

    commit('setNotifications', res.notifications || [])
    commit('setUnreadCount', res.unread || 0)
  },
  readNotification({ commit, state }, { id }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.read_notification([id])
        commit('decrementUnreadCount');
        commit('readNotification', id);
        resolve(res);
      } 
      catch(ex) {
        reject(ex);
      }
    })
  },
  showNotifDetails({dispatch}, notif) {
    let url = "";
    switch (notif.noticeable.commentable_type) {
      case "Email":
        let mail = notif.noticeable;
        url += `mail/${notif.noticeable.commentable_id}?via=notification`;
        const para = {
          rowId: mail.commentable_id,
          lineId: mail.line_ids,
          receivedAt: mail.received_at,
          box: mail.box,
          archived: mail.archived
        };
        if (!mail.read) {
          dispatch('readEmail', para);
        }
        break;
      case "Ship":
        url += `shipDetail/${notif.noticeable.commentable_id}?via=notification`;
        break;
      case "AddressBook":
        url += `contactDetail/${notif.noticeable.commentable_id}?via=notification`;
        break;
      case "Task":
        url = `calendar/table?task_id=${notif.noticeable.commentable_id}&via=notification`
        break;
    }
    if(notif.noticeable_type == appConstant.noticeableType.ASSIGNMENT
      || notif.noticeable_type == appConstant.noticeableType.UN_ASSIGNMENT)
    {
      url += `mail/${notif.noticeable.email_id}?via=notification`;
      dispatch('readEmail', { rowId: notif.noticeable.email_id });
    }
    if(notif.noticeable_type == appConstant.noticeableType.TASK_ASSIGNMENT) {
      url += `calendar/table?task_id=${notif.noticeable.task_id}&via=notification`;
    }
    if(notif.noticeable_type == appConstant.noticeableType.TASK) {
      url += `calendar/table?task_id=${notif.noticeable.id}&via=notification`;
    }
    util.openWindow(url);
    !notif.is_read && dispatch('readNotification', {id: notif.id}).then(_ => {
      notif.is_read = true
    })
  },

  deleteAllNotifications({ commit }) {
    api.delete_all_notifications()
    commit('setUnreadCount', 0)
    commit('setNotifications', [])
  },

  setUnreadCount({ commit }, { count }) {
    commit('setUnreadCount', count)
  },

  decrementUnreadCount({commit}) {
    commit('decrementUnreadCount')
  },

  incrementUnreadCount({commit}) {
    commit('incrementUnreadCount')
  },
}

const mutations = {
  setNotifications(state, notifications) {
    state.notifications = notifications
  },

  readNotification(state, id) {
    let noti = state.notifications.find(n => n.id == id)
    noti && (noti.is_read = true)
  },

  decrementUnreadCount(state) {
    state.unreadCount > 0 && state.unreadCount--;
  },

  incrementUnreadCount(state) {
    state.unreadCount++;
    console.log("Here: ", state.unreadCount)
  },

  setUnreadCount(state, count) {
    state.unreadCount = count;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}