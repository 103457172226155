<template>
  <Modal v-model="confirmShow" :title="returnHeading()" class="dialog-no-cancel">
    <ul class="listing">
      <li v-for="(ship,index) in shipList" :key="index">
        <span @click="gotoShipDetail(ship)">
            <div class="heading">
              <i v-if="ship.public" class="iconfont-global"></i>
              <svg-sprite v-else name="ic_company" :width="16" :height="16" />
              <span class="name">{{ship.name}}</span>
              <span class="year">{{ship.year_of_build}}</span>
            </div>
        <div>
          <span class="subHeading" v-if="ship.lrimo">IMO: {{ship.lrimo}}</span>
          <span class="subHeading" v-if="ship.ex_name">Ex Name: {{ship.ex_name}}</span>
          <span class="subHeading" v-if="ship.call_sign">Call Sign: {{ship.call_sign}}</span>
        </div>
        </span>
      </li>
      <li v-if="shipList.length == 0 && !loading">No ship Found</li>
    </ul>
    <div class="loading" v-show="loading">
      <Spin size="large" fix></Spin>
    </div>
  </Modal>
</template>

<script>
  import api from "../../fetch/api.js";
  import util from "util";
  export default {
    name: "shipList",
    data() {
      return {
        shipList: [],
        confirmShow: false,
        loading: false,
        text:""
      }
    },
    methods: {
      returnHeading() {
        return "Search results for - " + this.text;
      },
      show(text) {
        this.text = text;
        this.confirmShow = true;
        if (!text || text.length == 0) {
          return;
        }
        this.getShips(text);
      },
      gotoShipDetail(ship) {
        let path = `shipDetail/${ship.id}`;
        path += ship.source ? `?source=${ship.source}` : ''
        util.openWindow(path);
      },
      getShips(text) {
        this.loading = true;
        const params = {
          key: text.trim()
        };
        api
          .get_search_ships(params)
          .then(res => {
            this.shipList = res.shared_ships.concat(res.personal_ships);
            this.loading = false;
            // this.$refs.ctxMenu.open(e);
          })
          .catch(err => {
            this.loading = false;
            console.log("error", err);
          });
      }
    },
    props: {
      mailId: {
        type: Number,
        required: false,
        default: null
      },
      from:{
        type: String,
        required: false,
        default: ''
      }
    }
  };
</script>

<style lang="scss">
  .listing {
    border: solid 1px var(--border-color);
    li {
      padding: 5px 20px;
      cursor: pointer;
      padding-bottom: 5px;
      background-color: var(--surface-color);
      list-style: none;
      & + li {
        border-top: solid 1px var(--border-color);
      }
      &:hover {
        background-color: var(--hover-color);
        color: var(--text-color);
      }
      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 14px;
          flex: 1;
          padding: 0 5px;
        }
        .year {
          font-size: 12px;
          line-height: 32px;
          text-align: right;
          color: #828d9d;
        }
      }
      .subHeading {
        display: inline-block;
        max-width: 120px;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    svg {
      fill: var(--text-color);
    }
  }
</style>


