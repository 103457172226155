<template>
  <div class="toastr-wrapper" :class="[{ stacked: !isShowAll }]">
    <Alert
      v-for="(notification, index) in listNotifications"
      :key="index"
      type="info"
      :content="sanitizeHTML(notification.content)"
      :time="notification.time"
      :start="notification.start"
      :end="notification.end"
      :style="{
        '--top-alert': `${
          8 + Math.min(listNotifications.length - index - 1, 2) * 10
        }px`,
        '--scale-alert': `${
          1 - Math.min(listNotifications.length - index - 1, 2) * 0.1
        }`,
      }"
      @close="closeNotification(notification.id)"
      @select="selectNotifDetails(notification)"
    />
    <div class="expand-action">
      <div
        v-if="listNotifications.length > 0"
        class="clear-btn"
        @click="onClear"
      >
        Clear All
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/fetch/api';
import md5 from "md5";
import Alert from "./Alert.vue";
import { mapActions, mapGetters } from 'vuex';
import CommentHelper from "@/helpers/comment.helper";
import { get } from 'lodash';
import { isEmpty } from 'lodash';

export default {
  data() {
    return {
      isShowBalloon: true,
      isShowAll: false,
      listNotifications: [],
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(["showNotifDetails"]),
    onClear() {
      this.listNotifications = [];
    },
    closeToastr(content) {
      const id = md5(content);
      this.closeNotification(id);
    },
    showToastr(detail) {
      if (!this.isShowBalloon) {
        return;
      }
      const { message, options } = detail;
      const id = md5(message.content);

      if (!message.hash || !this.listNotifications.some(n => n.hash === message.hash)) {
        this.listNotifications.push({
          id,
          content: CommentHelper.format2html(message.content),
          start: message.start,
          end: message.end,
          time: message.created_at,
          hash: message.hash,
          data: message
        });
      }

      if (options && options.autoClose) {
        setTimeout(() => {
          this.closeNotification(id);
        }, options.duration || 5000);
      }
    },
    selectNotifDetails(notification) {
      this.closeNotification(notification.id);
      this.showNotifDetails(notification.data);
    },
    closeNotification(id) {
      const idx = this.listNotifications.findIndex((el) => el.id === id);
      if (idx > -1) {
        this.listNotifications.splice(idx, 1);
      }
    },
    handleConfig(eventData) {
      const { type, value } = eventData.detail;
      switch (type) {
        case 'toggle':
          this.isShowBalloon = value;
          break;

        case 'close':
          this.closeToastr(value);
          break;

        case 'show':
          this.showToastr(value);
          break;

        default:
          break;
      }
    }
  },
  async mounted() {
    if (!isEmpty(this.userInfo.user)) {
      const res = await api.get_mentions_notification_setting()
      const config = res.usernames.find(el => el.username === get(this.userInfo, 'user.user_name', ''));
      this.isShowBalloon = !!Number(get(config, 'show_balloon_notification_user', '1'));
    }
    window.addEventListener("configToastr", this.handleConfig);

  },
  beforeDestroy() {
    window.removeEventListener("configToastr", this.handleConfig);
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
.toastr-wrapper {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-height: calc(100vh - 16px);
  overflow: auto;

  .alert-wrapper {
    cursor: pointer;
    transition: all 1s ease-in-out;
  }

  .expand-action {
    width: 320px;
    position: fixed;
    right: 8px;
    top: 8px;
    display: none;
  }
  .clear-btn {
    border-radius: 50px;
    padding: 2px 6px;
    border: 1px solid white;
    color: white;
    background-color: var(--label-color);
    margin: -6px 0 0 -30px;
    position: absolute;
    cursor: pointer;
  }

  &.stacked {
    position: relative;

    .alert-wrapper {
      transform: scale(var(--scale-alert));
      top: var(--top-alert);
      position: fixed;
      right: 8px;
      $parent: &;
    }

    &:hover {
      .expand-action {
        display: unset;
      }
      .alert-wrapper {
        position: static;
        transform: scale(1);
      }
    }
  }
}
</style>
