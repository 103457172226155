import Notification from './base/index.js';

const prefixCls = 'ivu-message';
const prefixKey = 'ivu_message_key_';

let defaultDuration = 3;
let top;
let messageInstance;
let name = 1;

const iconTypes = {
    'info': 'information-circled',
    'success': 'checkmark-circled',
    'warning': 'android-alert',
    'error': 'close-circled',
    'loading': 'load-c'
};

function getMessageInstance () {
    messageInstance = messageInstance || Notification.newInstance({
        prefixCls: prefixCls,
        styles: {
            top: `${top}px`
        }
    });

    return messageInstance;
}

function notice (content = '', btnContent = 'UNDO', duration = defaultDuration, type, onClose = function () {}, closable = false, onButtonClick = function() {}) {
    const iconType = iconTypes[type];

    // if loading
    const loadCls = type === 'loading' ? ' ivu-load-loop' : '';

    let instance = getMessageInstance();

    instance.notice({
        name: `${prefixKey}${name}`,
        duration: duration,
        styles: {},
        transitionName: 'move-up',
        content: `
            <div class="${prefixCls}-custom-content ${prefixCls}-${type}">
                <span>${content}</span>
            </div>
        `,
        btnContent: btnContent,
        onButtonClick: onButtonClick,
        onClose: onClose,
        closable: closable,
        type: 'message'
    });

    // 用于手动消除
    return (function () {
        let target = name++;
        return function () {
            instance.remove(`${prefixKey}${target}`);
        };
    })();
}

export default {
    info (options) {
        const type = typeof options;
        if (type === 'string') {
            options = {
                content: options
            };
        }
        return notice(options.content, options.btnContent, options.duration, 'info', options.onClose, options.closable, options.onButtonClick);
    },
    success (options) {
        const type = typeof options;
        if (type === 'string') {
            options = {
                content: options
            };
        }
        return notice(options.content, options.btnContent, options.duration, 'success', options.onClose, options.closable, options.onButtonClick);
    },
    warning (options) {
        const type = typeof options;
        if (type === 'string') {
            options = {
                content: options
            };
        }
        return notice(options.content, options.btnContent, options.duration, 'warning', options.onClose, options.closable, options.onButtonClick);
    },
    error (options) {
        const type = typeof options;
        if (type === 'string') {
            options = {
                content: options
            };
        }
        return notice(options.content, options.btnContent, options.duration, 'error', options.onClose, options.closable, options.onButtonClick);
    },
    loading (options) {
        const type = typeof options;
        if (type === 'string') {
            options = {
                content: options
            };
        }
        return notice(options.content, options.btnContent, options.duration, 'loading', options.onClose, options.closable, function() {});
    },
    config (options) {
        if (options.top) {
            top = options.top;
        }
        if (options.duration) {
            defaultDuration = options.duration;
        }
    },
    destroy () {
        let instance = getMessageInstance();
        messageInstance = null;
        instance.destroy('ivu-message');
    }
};
