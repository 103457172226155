<template>
  <Modal v-model="dialogShow"
         width="600"
         class="dialog mail-report"
         :closable="false"
         :mask-closable="false"
         :scrollable="false">
    <section>
      <div class="dialog_info">
        <p><span class="dark-blue">{{email.email_unique_id}}</span> |
          {{email.recipients}} recipients | on {{email.received_at | time_format}}</p>
      </div>
      <!--detail-->
      <div class="dialog-contain" v-show="!isSearch">
        <!-------------------------stats----------------------------->
        <div class="stats-contain">
          <!--percent-->
          <div class="percent-info">
            <p class="percent">{{report.percent}}%</p>
            <p class="count">{{report.count}} Recipients</p>
          </div>
          <!--select type-->
          <div class="type-info">
            <Select v-model="reportType" style="width:200px" @on-change="selectType">
              <Option value="delivered">Delivered</Option>
              <Option value="dropped">Dropped</Option>
              <Option value="deferred">Deferred</Option>
              <Option value="bounced">Bounced</Option>
              <!--<Option v-for="item in typeList" :value="item" :key="item">{{ item }}</Option>-->
            </Select>
            <p class="type-text">
              {{typeText}}
            </p>
          </div>
        </div>
        <!--delivered information(open click)-->
        <div class="delivered-info" v-if="reportType==='delivered'">
          <!--open-->
          <div class="deliver-item" :class="{'active':selectKey==='open'}" @click="selectPlate('open')">
            <p class="percent">{{open.percent}}%</p>
            <p class="count">{{open.count}} | Opened</p>
          </div>
          <!--click-->
          <div class="deliver-item" :class="{'active':selectKey==='click'}" @click="selectPlate('click')">
            <p class="percent">{{click.percent}}%</p>
            <p class="count">{{click.count}} | Clicked</p>
          </div>
          <!--spam-->
          <div class="deliver-item" :class="{'active':selectKey==='spam'}" @click="selectPlate('spam')">
            <p class="percent">{{spam.percent}}%</p>
            <p class="count">{{spam.count}} | Spam Reports</p>
          </div>
          <!--Unread-->
          <div class="deliver-item" :class="{'active':selectKey==='unread'}" @click="selectPlate('unread')">
            <p class="percent">{{unread.percent}}%</p>
            <p class="count">{{unread.count}} | Unread</p>
          </div>
        </div>
        <!--search-->
        <div class="report-search">
          <div class="search-component">
            <reportSearch :inputSize="'width: 100%'"
                          :buttonSize="'width: 160px'"
                          :placeholder="'Find email address'"
                          ref="reportSearch"
                          @searchAddress="searchAddress">
            </reportSearch>
          </div>
        </div>
        <!--address list-->
        <div class="address-wrapper">
          <div class="search-title" v-show="searchKey!=''">
            “<span>{{searchKey}}</span>” found {{filterList.length}} matches:
          </div>
          <div class="address-list">
            <div class="address-item" v-for="(item, index) in filterList" v-show="index<=filterList.length" :key="index" :title="item">
              <span>{{item}}</span>
            </div>
          </div>
        </div>
      </div>
      <!--search-->
      <div class="dialog-contain" v-show="isSearch" :class="{'search':isSearch}">
        <!--search-->
        <div class="report-search">
          <div class="search-component">
            <reportSearch :inputSize="'width: 100%'"
                          :buttonSize="'width: 160px'"
                          ref="reportSearchNum"
                          :placeholder="'Enter email address'"
                          @searchAddress="searchAddress">
            </reportSearch>
          </div>
        </div>
        <!--address list-->
        <div class="stats-search">
          <div class="stats-search-title">
            “{{searchKey}}” is found at the following:
          </div>
          <div class="stats-search-list">
            <div class="search-item" v-for="(item, index) in searchList"
                 @click="selectSearchLine(item)">
              <p class="label">
                {{item.label}}:
              </p>
              <p class="value" v-if="item.count>0">
                <span class="count">{{item.count}}</span>
                matches
                <span class="arrow-right"><i class="iconfont ch-icon-arrow-right1"></i></span>
              </p>
              <p class="noValue" v-else>
                No match found
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div slot="footer">
      <span @click="hide" class="backText">Back to report summary</span>
    </div>
  </Modal>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import util from 'util';
  import reportSearch from './reportSearch.vue';
  import {mapGetters, mapState} from 'vuex';

  export default{
    props: {
      email: {
        type: Object,
      },
      stats: {
        type: Object,
      },
    },
    data() {
      return {
        dialogShow: false,
        report: {},
//        显示状态
        isSearch: false,
//        address
        recipients: [],
//        数据
        open: {},
        spam: {},
        click: {},
        unread: {},
//       select
        reportType: 'delivered',
        typeText: 'Message has been successfully delivered to the receiving server.',
        selectKey: 'open',
//        search
        searchKey: '',
        searchList: [
          {label: 'Opened', count: 0, value: 'delivered', name: 'open'},
          {label: 'Clicked', count: 0, value: 'delivered', name: 'click'},
          {label: 'Spam Report', count: 0, value: 'delivered', name: 'spam'},
          {label: 'Unread', count: 0, value: 'delivered', name: 'unread'},
          {label: 'Dropped', count: 0, value: 'dropped', name: 'drop'},
          {label: 'Deferred', count: 0, value: 'deferred', name: 'deferred'},
          {label: 'Bounced', count: 0, value: 'bounced', name: 'bounce'},
        ]
      }
    },
    created () {
    },
    methods: {
//-------------------------显示dialog
      show(str, search){
        Object.assign(this.$data, this.$options.data());
        this.dialogShow = true;
        this.isSearch = search;
        this.open = this.stats.open;
        this.spam = this.stats.spam;
        this.click = this.stats.click;
        this.unread = this.stats.unread;
        if (search) {
          this.searchInit(str);
        } else {
          this.dataInit(str); //delivered/dropped/deferred/bounced:
        }
      },
      hide(){
        this.dialogShow = false;
        this.$refs.reportSearch.searchKey = '';
        this.$refs.reportSearchNum.searchKey = '';
      },
//----------------------------------------init
      dataInit(str){
        this.reportType = str;//select
        switch (str) {//4
          case 'delivered':
            this.report = this.stats.delivered;
            this.recipients = this.stats.open.recipients;
            this.typeText = 'Message has been successfully delivered to the receiving server.';
            break;
          case 'dropped':
            this.report = this.stats.drop;
            this.recipients = this.report.recipients;
            this.typeText = 'You may see the following drop reasons: Invalid SMTPAPI header, Spam Content (if spam checker app enabled), Unsubscribed Address, Bounced Address, Spam Reporting Address, Invalid, Recipient List over Package Quota.';
            break;
          case 'deferred':
            this.report = this.stats.deferred;
            this.recipients = this.report.recipients;
            this.typeText = 'Recipient’s email server temporarily rejected message.';
            break;
          case 'bounced':
            this.report = this.stats.bounce;
            this.recipients = this.report.recipients;
            this.typeText = 'Receiving server could not or would not accept message.';
            break;
          default:
            break;
        }
      },
      searchInit(str){//由search进入
        this.searchKey = str;
        this.$refs.reportSearch.searchKey = str;
        this.$refs.reportSearchNum.searchKey = str;
        this.searchList.forEach(item => {
          for (let name in this.stats) {//遍历对象属性名
            if (name === item.name) {
              const arr = this.stats[name].recipients.slice(0);
              item.count = this.matchCount(arr)
            }
          }
        })
      },
//------------------------------------------detail
//      delivered/dropped/deferred/bounced
      selectType(val){
//        this.searchKey = '';
//        this.$refs.reportSearch.searchKey = '';
        switch (val) {//4
          case 'delivered':
            this.report = this.stats.delivered;
            this.recipients = this.stats.open.recipients;
            this.typeText = 'Message has been successfully delivered to the receiving server.';
            break;
          case 'dropped':
            this.report = this.stats.drop;
            this.recipients = this.report.recipients;
            this.typeText = 'You may see the following drop reasons: Invalid SMTPAPI header, Spam Content (if spam checker app enabled), Unsubscribed Address, Bounced Address, Spam Reporting Address, Invalid, Recipient List over Package Quota.';
            break;
          case 'deferred':
            this.report = this.stats.deferred;
            this.recipients = this.report.recipients;
            this.typeText = 'Recipient’s email server temporarily rejected message.';
            break;
          case 'bounced':
            this.report = this.stats.bounce;
            this.recipients = this.report.recipients;
            this.typeText = 'Receiving server could not or would not accept message.';
            break;
          default:
            break;
        }
      },
      selectPlate(val){
        this.selectKey = val;
      },
      searchAddress(key){
        this.searchKey = key;
        if (this.isSearch) {
          this.$refs.reportSearch.searchKey = key;
          this.searchList.forEach(item => {
            for (let name in this.stats) {//遍历对象属性名
              if (name === item.name) {
                const arr = this.stats[name].recipients.slice(0);
                item.count = this.matchCount(arr)
              }
            }
          })
        }
      },
//-----------------------------------------search detail
      selectSearchLine(val){
        this.isSearch = false;
        this.dataInit(val.value);
        if (val.value === 'delivered') {
          this.selectKey = val.name
        }
      },
//------------------------计算匹配search的count
      matchCount(arr){
        if (arr.length !== 0) {
          let searchKey = this.searchKey && this.searchKey.toLowerCase();
          const arrFilter = arr.filter(function (item) {
            return item.toLowerCase().indexOf(searchKey.toLowerCase()) != -1;
          });
          return arrFilter.length;
        } else {
          return 0
        }
      }
    },
    computed: {
//-------------------------------------动态过滤
      filterList: function () {
        if (this.recipients.length !== 0) {
          let recipients = this.recipients;
          let searchKey = this.searchKey && this.searchKey.toLowerCase();
          return recipients.filter(function (item) {
            return item.toLowerCase().indexOf(searchKey.toLowerCase()) != -1;
          });
        } else {
          return [];
        }
      },
    },
    filters: {
      time_format (val) {
        return util.relativeDay(val) + ', ' + util.time_12(val);
      },
    },
    watch: {
//       open/click/spam
      'selectKey' () {
        switch (this.selectKey) {
          case 'open':
            this.recipients = this.open.recipients;
            break;
          case 'click':
            this.recipients = this.click.recipients;
            break;
          case 'spam':
            this.recipients = this.spam.recipients;
            break;
          case 'unread':
            this.recipients = this.unread.recipients;
            break;
          default:
            break;
        }
      }
    },
    components: {
      reportSearch,
    }

  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  /*结构*/
  .mail-report {
    section {
      position: relative;
      background: var(--component-color);
      color: var(--on-component-color);
      .dialog_info {
        padding: 10px;
        p {
          font-size: 12px;
          .dark-blue {
            font-weight: bold;
          }
          .gray {
            font-weight: bold;
          }
        }
      }
      .dialog-contain {
        position: relative;
        margin-top: 20px;
        padding: 0;
        background-color: var(--component-color);
        color: var(--on-component-color);
        border-top: none;
        border-bottom: none;
        display: flex;
        flex-direction: column;
        height: 400px;
        &.search {
          margin-top: 10px;
        }
        .stats-contain {
          display: flex;
          justify-content: space-around;
          margin-bottom: 16px;

          .percent-info {
            .percent {
              font-size: 44px;
              font-weight: bold;
              color: var(--primary-color);
              margin-bottom: 3px;
            }
            .count {
              padding-left: 5px;
              font-size: 18px;
            }
          }
          .type-info {
            .type-text {
              width: 200px;
              height: 53px;
              overflow-y: auto;
              overflow-y: overlay;
              font-size: 12px;
              margin-top: 10px;
              line-height: 1.3;
              color: var(--on-component-color);
            }
          }
        }
        .delivered-info {
          display: flex;
          min-height: 60px;
          border-top: 1px solid var(--border-color);
          margin-bottom: 13px;
          .deliver-item {
            flex: 1;
            padding: 9px 0;
            cursor: pointer;
            text-align: center;
            border-bottom: 1px solid var(--border-color);
            .percent {
              font-size: 24px;
              margin-bottom: 4px;
            }
            .count {
              font-size: 12px;
              transform: scale(0.84);
            }
            &.active {
              font-weight: bold;
              .percent {
                font-size: 24px;
                color: var(--primary-color);
                opacity: 0.87;
              }
              .count {
                font-size: 12px;
                transform: scale(0.84);
                color: var(--primary-color);
              }
              border-bottom: 3px solid var(--primary-color);
            }

          }
        }
        .report-search {
          display: flex;
          margin-bottom: 16px;

          .search-component {
            width: 100%;
            height: 40px;
          }
        }
        .address-wrapper {
          flex: 1;
          min-height: 0;
          overflow: auto;
          overflow: overlay;
          background: var(--component-color);
          padding: 8px 0 8px 13px;
          .search-title {
            line-height: 14px;
            font-size: 14px;
            margin: 6px 0;
            span {
              font-weight: bold;
            }
          }
          .address-list {
            display: flex;
            flex-wrap: wrap;
            .address-item {
              width: 280px;
              span {
                font-size: 14px;
                line-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

        }
        /*search*/
        .stats-search {
          overflow: auto;
          overflow: overlay;
          .stats-search-title {
            padding: 0 15px;
            margin-bottom: 13px;
            line-height: 23px;
            font-size: 16px;
          }
          .stats-search-list {
            height: 351px;
            overflow: auto;
            overflow: overlay;
            .search-item {
              display: flex;
              height: 40px;
              font-size: 16px;
              cursor: pointer;
              text-align: right;
              color: var(--primary-color);
              border-top: 1px solid var(--border-color);
              transition: all 0.2s ease-in-out;
              &:hover {
                background: rgba(32, 53, 128, 0.1625);
              }
              &:last-child {
                border-bottom: 1px solid var(--border-color);
              }
              .label {
                width: 35%;
                line-height: 50px;
              }
              .value, .noValue {
                width: 35%;
                margin-left: 22px;
                line-height: 50px;
                white-space: nowrap;
                .count {
                  margin-right: 9px;
                  font-weight: bold;

                }
                .arrow-right {
                  margin-left: 22px;
                  .ch-icon-arrow-right1 {
                    font-size: 16px;
                    color: rgba(25, 77, 120, 0.3);
                  }
                }
              }
            }
          }
        }
      }
    }
    .backText {
      color: var(--primary-color);
      &:hover {
        cursor: pointer;
      }
    }
  }

  /*loading 动画*/
  .spin-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding-top: 150px;
    background-color: rgba(255, 255, 255, 0.9);
    .load-text {
      font-size: 14px;
      font-weight: normal;
      margin-top: 10px;
    }
    .aside-spin-icon-load {
      animation: ani-demo-spin 1s linear infinite;
    }
  }

</style>
