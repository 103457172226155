<template>
  <div class="dynamic_wrapper" v-clickoutside="handleClose">
    <Dropdown trigger="custom" :visible="visible" placement="bottom-start" style="width: 100%">
      <Input v-model.trim="searchKey"
            class="search-box"
            :class="{'has-error': !!error}"
            :disabled="disable"
            :placeholder="placeholder"
            @on-focus="onFocus"
            @on-blur="onBlur"
            @on-keydown="pointer($event)"
            @on-enter="addPointerElement()"
            style="width: 100%"
            ref="searchboxEl" 
      />
      <span v-if="error" class="error">{{error}}</span>
      <DropdownMenu slot="list">
        <ul class="down_wrapper" v-show="searchList.length > 0">
          <div class="search_list">
            <li v-for="(item,index) in searchList" :key="index"
                class="row_item"
                :class="{'row-highlight': selectedIndex===index}"
                @click="selectedItem(index,item)"
            >
              {{item.name}}
            </li>
          </div>
        </ul>
        <!--No matching data-->
        <ul class="down_wrapper" v-show="searchList.length==0">
          <li class="no_data">No matching data</li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import debounce from 'lodash/debounce';
  import {mapGetters} from 'vuex'
  import clickoutside from '../../../utils/clickoutside';
import utils from '../../../utils';
  const categories = {
    COMPANY: 'company',
    DEPARTMENT: 'department'
  }
  export default {
    directives: { clickoutside },
    props: {
      dataList: {
        type: Array,
        default: () => [],
      },
      category: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      disable: {
        type: Boolean,
      },
      required: {
        type: Boolean
      }
    },
    data() {
      return {
        companies: [],
        departments: [],
        visible: false,
        searchKey: '',
        searchId: undefined,
        selectedIndex: -1,
        error: ''
      }
    },
    mounted () {
      const { company_name, department_name } = this.$route.query;
      if (this.category === categories.COMPANY && company_name) {
        this.searchKey = company_name;
      }
      if (this.category === categories.DEPARTMENT && department_name) {
        setTimeout(() => {
          this.searchKey = department_name;
        }, 500)
      }
    },
    watch: {
      dataList: {
        immediate: true,
        handler: function(val) {
          this.departments = val;
        }
      },
      searchKey: {
        handler: debounce(
          async function (val) {
            if (val.length > 255) {
              this.$Message.error("String length exceeds maximum length limit of 255 characters.")
              return;
            }
            this.selectedIndex = 0;
            this.$el.querySelector('.down_wrapper').scrollTop = 0;
            this.category === categories.COMPANY && (this.companies = await this.searchCompanies());
            this.category === categories.DEPARTMENT && (this.departments = this.searchDepartments());
          }, 300)
      },
      disable: function (disable) {
        disable && this.category === categories.DEPARTMENT && (this.searchKey = '');
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
      searchList: function () {
        return this.category === categories.COMPANY ? this.companies 
          : this.category === categories.DEPARTMENT ? this.departments
          : this.dataList;
      },
      pointerPosition() {
        let pointer = this.selectedIndex + 1;
        return pointer * 54;
      },
    },
    methods: {
      onFocus() {
        this.visible = true;
      },

      onBlur() {
        this.validate();
        setTimeout(() => {
          this.visible = false;
        }, 300);
        const item = this.searchList.find(item => item.name.trim() === this.searchKey.trim())
        if (!!item) {
          this.searchId = item.id;
          return
        }

        this.searchKey = ""
      },

      pointer(e) {
        if (e.keyCode === 40) { //down
          this.pointerForward()
        }
        if (e.keyCode === 38) { //up
          this.pointerBackward()
        }
      },

      pointerForward() {
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        let visibleElements = 275 / 54;
        if (s > 0) {
          if (this.selectedIndex < s - 1) {
            this.selectedIndex++;
          } else {
            this.selectedIndex = s - 1;
          }
        }
        if (obj.scrollTop <= this.pointerPosition - visibleElements * 54) {
          obj.scrollTop = this.pointerPosition - 54;
        }
      },

      pointerBackward() {
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        if (s > 0) {
          if (this.selectedIndex > 0) {
            this.selectedIndex--;
          } else {
            this.selectedIndex = 0;
          }
        }
        if (obj.scrollTop >= (this.pointerPosition - 54)) {
          obj.scrollTop = this.pointerPosition - 275;
        }
      },

      addPointerElement() {
        const s = this.searchList.length;
        if (s === 0) {
          return
        }
        if (this.selectedIndex < s && this.selectedIndex > -1) {
          let name = this.searchList[this.selectedIndex].name;
          let id = this.searchList[this.selectedIndex].id;
          let dep = this.searchList[this.selectedIndex].departments;
          this.searchKey = name;
          this.$el.querySelector('.ivu-input').blur();

          this.category === categories.COMPANY 
            && this.$emit('selectedCompany', this.searchList[this.selectedIndex]);
          this.category == categories.DEPARTMENT
            && this.$emit('selectedDepartment', this.searchList[this.selectedIndex]);
        }
      },

      handleClose(){
        this.visible = false;
      },

      searchDepartments() {
        let departments = [];
        if(this.searchKey === '') {
          this.$emit('searchDepartmentDone', this.dataList)
          return this.dataList;
        }

        departments = this.dataList && this.dataList.length > 0 
          ? this.dataList.filter((item) => item && item.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) != -1)
          : [];
        this.$emit('searchDepartmentDone', departments)
        return departments;
      },

      async searchCompanies() {
        if(this.searchKey == '') {
          this.$emit('selectedCompany', null)
          return [];
        }
        const res = await api.get_companies({ name: this.searchKey });
        const companies = res.companies || [];
        this.$emit('searchCompanyDone', companies)
        return companies;
      },

      selectedItem(index, item) {
        this.error = '';
        this.selectedIndex = index;
        this.searchKey = item.name;
        this.searchId = item.id;
        this.category === categories.COMPANY && this.$emit('selectedCompany', item)
        this.category === categories.DEPARTMENT && this.$emit('selectedDepartment', item)
      },
      validate() {
        const name = this.category == categories.COMPANY ? 'company name' 
          : this.category == categories.DEPARTMENT ? 'department name'
          : 'name';
        if(this.required && !this.searchKey) {
          this.error = `${utils.capitalizeFirstLetter(name)} should not be empty.`;
          return false;
        }

        if(!this.searchKey) {
          this.error = '';
          return true;
        }
        const valid = this.searchList.findIndex(l => l.name == this.searchKey) > -1;
        this.error = !valid ? `This ${name} does not exist.` : '';
        return valid;
      }
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .dynamic_wrapper {
    width: 100%;
    .error {
      position: absolute;
      line-height: 0;
      left: 0;
      top: 36px;
      margin-top: 12px;
      font-size: 12px;
      color: var(--error-color);
    }
    .down_wrapper {
      width: 100%;
      max-height: 250px;
      overflow: auto;
      overflow: overlay;
      text-align: left;
      .search_list {
        .row_item {
          cursor: pointer;
          color: var(--on-component-color);
          font-size: 14px;
          line-height: 1.26;
          padding: 13px 16px 14px;
          background: var(--component-color);
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: var(--hover-color);
            color: var(--text-color);
          }
          &.row-highlight {
            background-color: var(--highlight-color);
            color: var(--text-color);
          }
        }
      }
      .no_data {
        width: 100%;
        font-size: 14px;
        line-height: 45px;
        color: #bbbec4;
        text-align: center;
      }
    }
  }
</style>
<style lang="scss">
.search-box {
  &.has-error {
    .ivu-input {
      border-color: var(--error-color);
    }
  }
}
</style>
