<template>
  <resizable-block
    class="detail-wrapper"
    ref="resizePreview"
    v-if="detailShow"
    :class="{
      'half-height': singleClick && !previewVertical && !maximizedMode && !fullSize && !inappAttachmentPreview.isPreviewing,
      'half-width': singleClick && previewVertical && !maximizedMode && !fullSize && !inappAttachmentPreview.isPreviewing
    }"
    :type="!previewVertical ? 'mailHeight' : 'mailWidth'"
    :resizeHeight="singleClick && !previewVertical"
    :resizeWidth="singleClick && previewVertical"
    :isExpanded="isExpanded"
    @onResizeDone="$emit('onResizeDone', $event)"
    :style="customStyle"
  >
    <section class="detail-contain">
      <!---------------email detail-------------->
      <div class="detail-left" :class="{'expand':isExpanded}">
        <!--fixed-->
        <div class="detail-fixed" :class="{'expand':isExpanded}">
          <!--title-->
          <div class="mailDetail-subject" :class="{'subject-vertical': previewVertical}">
            <div class="subject-container" :key="mailDetail.id">
              <div class="ch-mail-subject-text">
                <div v-if="(maximizedMode || !lockedPlane || $route.name !== 'mail') && !inAttachmentPreviewModal" class="btn-back" @click.stop="closeView" title="Close">
                  <svg-sprite name="mt_close" :width="20" :height="20" />
                </div>
                <template v-if="!previewVertical">
                  <div class="line-color" :style="{'background-color': handleLineColor(mailDetail.line_ids)}"></div>
                  <div id="mail-body" class="mail-subject" :title="mailDetail.subject">{{mailDetail.subject}}</div>
                </template>
              </div>
            </div>
            <div class="expandIcon">
              <div class="operation-btns">
                <div class="operation-right">
                  <!--Smart add contact-->
                  <div
                    v-if="openAIEnabled && !previewEml && !inappAttachmentPreview.isPreviewing"
                    class="openai-wrapper"
                  >
                    <Tooltip
                      placement="left"
                      class="actionable-icon"
                      :disabled="true"
                    >
                      <span class="command-btn" @click="smartAddContact(contactTypes.PERSON)">
                        Add to persons
                      </span>
                      <Dropdown type="primary">
                        <Icon type="md-arrow-dropdown" class="triangle-icon actionable-icon"></Icon>
                        <DropdownMenu slot="list">
                          <DropdownItem @click.native="smartAddContact(contactTypes.PERSON)">
                            Add to persons
                          </DropdownItem>
                          <DropdownItem @click.native="smartAddContact(contactTypes.DEPARTMENT)">
                            Add to departments
                          </DropdownItem>
                          <DropdownItem @click.native="smartAddContact(contactTypes.COMPANY)">
                            Add to companies
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Tooltip>
                  </div>
                  <div v-if="setting_company.shipping_module_turn_off=='0' " @click="updateShipPosition">
                    <Tooltip v-if="!isSpamMail" placement="bottom-start" content="Create ship position" class="actionable-icon">
                      <svg-sprite name="mt_add_ship" :width="23" :height="18" />
                    </Tooltip>
                  </div>
                  <div v-if="setting_company.shipping_module_turn_off=='0' " @click="searchShip">
                    <Tooltip content="Lookup ship" class="actionable-icon">
                      <svg-sprite name="mt_search_ship" :width="23" :height="18" />
                    </Tooltip>
                  </div>
                  <div @click="mailEvents('resend')">
                    <Tooltip v-if="!isSpamMail" content="Resend all" class="actionable-icon">
                      <div class="btn">
                        <svg-sprite name="mt_resend_all" :width="18" :height="14" />
                      </div>
                    </Tooltip>
                  </div>
                  <div @click="printMail">
                    <Tooltip content="Print"  class="actionable-icon">
                      <a target="_blank">
                        <svg-sprite name="mt_print" :width="17" :height="17" />
                      </a>
                    </Tooltip>
                  </div>
                  <div @click="changeDirection" v-if="!previewExpanded && !maximizedMode && !inappAttachmentPreview.isPreviewing">
                    <Tooltip :content="`Preview ${!previewVertical ? 'vertically' : 'horizontally'}`" v-if="singleClick" class="actionable-icon">
                      <div class="btn btn-change-direction">
                        <svg-sprite :class="{rotate90: !previewVertical}" name="mt_preview_direction" :width="17" :height="15" />
                      </div>
                    </Tooltip>
                  </div>
                  <div v-if="!maximizedMode && !inappAttachmentPreview.isPreviewing" @click="collapseWindow">
                    <Tooltip content="Minimize to tab" class="actionable-icon">
                      <div class="btn">
                        <svg-sprite name="mt_minimize" :width="10" :height="10" />
                      </div>
                    </Tooltip>
                  </div>
                  <div v-if="!isOpeningInappWindow && singleClick && !inappAttachmentPreview.isPreviewing" @click="() => toggleExpandedPreview()">
                    <Tooltip :content="previewExpanded ? 'Minimize' : 'Maximize'" class="actionable-icon">
                      <div class="btn">
                        <svg-sprite
                          :name="previewExpanded ? 'collapse' : 'expand'"
                          :width="15"
                          :height="15" />
                      </div>
                    </Tooltip>
                  </div>
                  <div v-if="!inappAttachmentPreview.isPreviewing" @click="openNewWindow">
                    <Tooltip content="In new window" class="actionable-icon">
                      <div class="btn">
                        <svg-sprite name="mt_new_window" :width="14" :height="14" />
                      </div>
                    </Tooltip>
                  </div>
                  <div @click="expand">
                    <Tooltip content="Expand" class="actionable-icon">
                      <div class="btn">
                        <svg-sprite :class="{rotate180: isExpanded}" name="mt_open_side_pane" :width="15" :height="15" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="email-content-wrapper">
          <!--contain-->
          <ch-mail ref="emailContent"
            :userAvatar="null"
            :hideSubject="!previewVertical"
            :selectedEmail="selectedEmail"
            :mailId="mailId"
            :assignment_ids="mailDetail.assignment_ids"
            @detailHide="closeView"
            @update-email-content="handleUpdateEmailContent"
            @get-mail-detail-done="handleGetMailDetailDone"
            @markSpamDone="markAsSpamDone"
          >
            <template v-slot:loading>
              <div class="loading" v-show="loading">
                <Spin size="large" fix></Spin>
              </div>
            </template>
            <template v-slot:tabs>
              <!---------------tabs---------->
              <mailDetailTabs
                ref="mailDetailTabEl"
                v-if="previewVertical || inAttachmentPreviewModal"
                :mailId="mailDetail.id"
                :previewEmailId="previewEmailId"
                :mailDetail="mailDetail"
                :showTab="isExpanded"
                :previewVertical="previewVertical"
                @updateShipPosition="updateShipPosition()"
              />
            </template>
          </ch-mail>
        </div>
      </div>
      <mailDetailTabs
        ref="mailDetailTabEl"
        v-if="!previewVertical && !inAttachmentPreviewModal"
        :mailId="mailDetail.id"
        :previewEmailId="previewEmailId"
        :mailDetail="mailDetail"
        :showTab="isExpanded"
        :previewVertical="previewVertical"
        @updateShipPosition="updateShipPosition()"
      />
    </section>
    <Cfeedback type="Email" :id="mailDetail.id" ref="feedback"></Cfeedback>
    <updatePosition ref="updatePosition" @refreshDetail='getPositions' :emailId="mailDetail.id"></updatePosition>
    <shipList ref="shipList" :mailId="mailId" ></shipList>
    <Modal
      class-name="add-contact-modal vertical-center-dialog"
      v-model="addContactShow"
      :footer-hide="true"
      :width="80"
    >
      <AddContact
        v-if="addContactType === contactTypes.PERSON"
        :initialData="contactData"
        :loading="addContactShow"
        @done="addContactDone"
      />
      <AddDepartment
        v-else-if="addContactType === contactTypes.DEPARTMENT"
        :initialData="contactData"
        :loading="addContactShow"
        @done="addContactDone"
      />
      <AddCompany
        v-else-if="addContactType === contactTypes.COMPANY"
        :initialData="contactData"
        :loading="addContactShow"
        @done="addContactDone"
      />
    </Modal>
    <Modal
      class-name="contact-detail-modal vertical-center-dialog"
      v-model="contactDetailShow"
      :footer-hide="true"
      :width="80"
    >
      <ContactDetail
        v-if="contactDetailShow && contactDetailId"
        :selectedContactId="contactDetailId"
      />
    </Modal>
  </resizable-block>
  <!--</transition>-->
</template>

<script type="text/ecmascript-6">
import util from "util";
const mailItem = () => import("@/pages/components/Cmailitem.vue");
import Cfeedback from "../../components/Cfeedback.vue";
import updatePosition from "../../header/updatePosition/updatePosition.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import shipList from "../../components/shipList.vue";
import ResizableBlock from "@/components/ResizableBlock.vue";
import storageConstant from "@/common/constants/local-storage.constant";
import { convertBody, highlightSearch, mailDetailMixin } from "@/mixins";
import mailDetailTabs from '@/pages/mails/detail/mailDetailTabs';
import appConstant from '@/common/constants/app.constant';
import AddContact from '@/pages/contact/add/addContact.vue';
import AddDepartment from '@/pages/contact/add/addDepartment.vue';
import AddCompany from '@/pages/contact/add/addCompany.vue';
import ContactDetail from "@/pages/contact/detail/window/detailWindow.vue";

let highlightTimeout;

export default {
  name: "maildetail",
  props: {
    selectedEmail: {
      type: Object
    },
    previewEmailId: {
      type: Number
    },
    highlights: {
      type: Array
    },
    singleClick: {
      type: Boolean
    },
    shipPosition: {
      type: Boolean
    },
    fullSize: {
      type: Boolean,
      default: false
    },
    inAttachmentPreviewModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detailShow: false,
      mailId: null,
      loading: false,
      emailDetails: {},
      mailDetail: {
        to: [],
        cc: [],
        bcc: []
      },
      isExpanded: false,
      previewVertical: true,
      mailDetailFn: {}
    };
  },
  created() {
    this.detailShow = true;
  },
  mixins: [convertBody, highlightSearch, mailDetailMixin],
  provide() {
    return {
      closeView: this.closeView,
      closeMailPreview: this.closeMailPreview,
      mailDetailFn: this.mailDetailFn
    }
  },
  inject: {
    inAppState: {
      default: {}
    },
    toggleExpandedPreview: {
      default: () => {}
    }
  },
  methods: {
    ...mapMutations(["ADD_INAPP_COMPOSE_WINDOW", "REMOVE_INAPP_WINDOW", "UPDATE_INAPP_COMPOSE_DATA", "SET_MAIL_DETAIL"]),
    ...mapActions(["readEmail", "closeInappWindow"]),
    collapseWindow() {
      this.ADD_INAPP_COMPOSE_WINDOW({
        data: this.mailDetailState.content,
        id: this.previewEmailId,
        type: appConstant.inappType.PREVIEW,
        key: `preview-${Date.now()}`,
        visible: false,
        previewingInappAttachment: false,
        subject: this.selectedEmail.subject,
        close: this.closeView
      });

      this.SET_MAIL_DETAIL({});

      this.closeView();
    },
    handleUpdateEmailContent(content) {
      this.mailDetail=content;
      this.$nextTick(() => {
        this.scrollTopPreview();
      })
      this.searching && this.highlightSearchResult();
    },
    highlightSearchResult() {
      clearTimeout(highlightTimeout);
      highlightTimeout = setTimeout(() => {
        this.highlights.forEach(highlight => {
          highlight.subject && this.handleHighlightSubject(highlight.subject);
          highlight.body && this.handleHighlightBody(highlight.body);
        })
      }, 500);
    },
    handleHighlightSubject(searchKeys) {
      if (searchKeys.length === 0) return;
      const context = document.getElementById("mail-body");
      this.highlightSearch(context, searchKeys)
    },
    handleHighlightBody(searchKeys) {
      if (searchKeys.length === 0) return;
      const context = document.querySelector(".body-class");
      this.highlightSearch(context, searchKeys)
    },
    getPreviewVertical() {
      if (this.shipPosition) {
        return this.previewVertical = true;
      }
      this.previewVertical = this.setting_mails.conversation_view ? this.setting_mails.conversation_view === "true" : false;
    },
    closeMailPreview() {
      this.detailShow = false;
      this.$emit("close");
      this.SET_MAIL_DETAIL({});
    },
    closeView(option) {
      this.detailShow = false;
      this.$emit("close", option);
      this.toggleExpandedPreview && this.toggleExpandedPreview(false);

      if (this.inAppState.key) {
        this.REMOVE_INAPP_WINDOW(this.inAppState.key)
        return;
      }

      this.SET_MAIL_DETAIL({})
    },
    updateDetail(email) {
      const clonedEmail = {...email};
      delete clonedEmail.body;
      delete clonedEmail.read;

      this.$refs.emailContent && this.$refs.emailContent.updateUI(
        { email: Object.assign(this.$refs.emailContent.mailDetail, clonedEmail) }
      );
    },
    getExpanded() {
      if (this.inAttachmentPreviewModal) {
        this.isExpanded = false;
        return;
      }

      if (this.previewVertical) {
        const savedExpanded = localStorage.getItem(storageConstant.EXPAND_PREVIEW_VERTICAL);
        this.isExpanded = savedExpanded ? JSON.parse(savedExpanded) : false;
      } else {
        const savedExpanded = localStorage.getItem(storageConstant.EXPAND_PREVIEW_HORIZONTAL);
        this.isExpanded = savedExpanded ? JSON.parse(savedExpanded) : true;
      }
    },
    async pageInit() {
      this.getPreviewVertical();
      this.getExpanded();
    },
    scrollTopPreview() {
      const previewBody = document.querySelector(".body-class");
      previewBody && (previewBody.scrollTop = 0);
    },
    handleGetMailDetailDone() {
      if(!this.$refs.mailDetailTabEl) return;
      this.$refs.mailDetailTabEl.resetExtraData();
      this.isExpanded && this.$refs.mailDetailTabEl.tabChange();
    },
    getPositions() {
      this.$refs.mailDetailTabEl && this.$refs.mailDetailTabEl.getPositions();
    },
    //quick update ship position
    updateShipPosition() {
      util.functionBus.readMail && util.functionBus.readMail();
      const params = {
        id: this.mailDetail.id,
        unique_id: this.mailDetail.email_unique_id
      };
      this.$refs.updatePosition.show(params);
    },
    expand() {
      util.functionBus.readMail && util.functionBus.readMail();
      this.isExpanded = !this.isExpanded;
    },
    printMail() {
      util.functionBus.readMail && util.functionBus.readMail();
      const previewBody = document.querySelector(".body-class");
      previewBody && util.removeUnexpectedTags(previewBody, ['base']);
      window.open(`#printMail/${this.mailDetail.id}`, "_blank");
    },
    changeDirection() {
      util.functionBus.readMail && util.functionBus.readMail();
      this.previewVertical = !this.previewVertical;
      this.$emit("changeDirection", this.previewVertical);
      this.$nextTick(() => {
        this.getExpanded();
        this.searching && this.highlightSearchResult();
      })
    },
    // report error
    reportError() {
      util.functionBus.readMail && util.functionBus.readMail();
      this.$refs.feedback.show();
    },
    openNewWindow() {
      localStorage.setItem(`mail_window_data_${this.selectedEmail.id}`, JSON.stringify(this.selectedEmail))
      util.functionBus.readMail && util.functionBus.readMail();

      const folderIdQuery = this.$route_folder_id() && `folder_id=${this.$route_folder_id()}` || ``;
      const folderNameQuery = this.$route_folder_name() && `&folder_name=${this.$route_folder_name()}` || ``;
      const folderTypeQuery = this.$route.query.type && `&type=${this.$route.query.type}` || ``;
      let subUrl = `mail/${this.mailId}?${folderIdQuery}${folderNameQuery}${folderTypeQuery}`;
      if (this.$route.query.via) {
        subUrl = folderIdQuery ? `&via=notification` : 'via=notification';
      }
      if (this.inAppState.inappAttachment) {
        subUrl += `&openingAtt=${this.inAppState.inappAttachment.id}`
      }

      let subject = this.mailDetail.subject;
      subject = util.removeSearchHtml(subject);
      subject = util.filterTitle(subject);

      let popup = util.openWindow(subUrl);
      popup && (popup.onload = () => {
        popup.document.title = subject;
      })

      this.inAppState && this.closeInappWindow(this.inAppState.key)
    },
    searchShip() {
      util.functionBus.readMail && util.functionBus.readMail();
      //function to check if the selected text matches the ship detail
      let text = util.getSelectionText();
      if (!text || text.length == 0) {
        this.$Message.error("Please select text");
        return;
      }
      this.$refs.shipList.show(text);
    },
    mailEvents(value) {
      util.functionBus.readMail && util.functionBus.readMail();
      this.$refs.emailContent.openSendModal(value);
    },
    handleEsc(event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      if (event.key === 'Escape' && !this.lockedPlane) {
        this.closeView();
      }
    },
    markAsSpamDone() {
      if (!this.selectedEmail) return;

      util.functionBus.removeAndGoNext && util.functionBus.removeAndGoNext([this.selectedEmail.id]);
    }
  },
  computed: {
    ...mapGetters([
      "mailList",
      "pageSize",
      "userInfo",
      "setting_mails",
      "lines_all",
      "setting_company",
      "teamMembers",
      "previewExpanded",
      "mailDetailState",
      "isOpeningInappWindow",
      "inappAttachmentPreview"
    ]),
    ...mapState(["lines"]),
    maximizedMode() {
      return this.previewExpanded || this.isOpeningInappWindow
    },
    searching() {
      return this.$route.query.search_key || this.$route.query.search;
    },
    isSpamMail() {
      return this.mailDetail.box == 'SPAM';
    },
    lockedPlane() {
      return this.setting_mails.locked_reading_plane == "true";
    },
    previewEml() {
      return /previewEml/.test(this.$route.path)
    },
    customStyle() {
      return {'border-top': this.$route.name === 'mail' ? 'none' : '1px solid var(--border-color)'}
    }
  },
  filters: { },
  mounted() {
    var el = document.getElementById("mail-body");
    el && el.addEventListener(
      "click",
      function(e) {
        var target = e.target;
        do {
          if (target.nodeName.toUpperCase() === "A" && target.href) {
            target.target = "_blank";
            break;
          }
        } while ((target = target.parentElement));
      },
      false
    );
    window.addEventListener('keyup', this.handleEsc);
  },
  destroyed() {
    window.removeEventListener('keyup', this.handleEsc);
  },
  watch: {
    "previewEmailId": {
      immediate: true,
      handler(val) {
        this.mailId = val;
        this.pageInit();
        this.setting_mails.auto_read_email == "true" &&
        this.$parent &&
        this.$parent.readMail &&
        this.$parent.readMail();
      }
    },
    isExpanded(val) {
      if (!this.singleClick || this.inAttachmentPreviewModal) return;

      if (this.previewVertical) {
        localStorage.setItem(storageConstant.EXPAND_PREVIEW_VERTICAL, val);
      } else {
        localStorage.setItem(storageConstant.EXPAND_PREVIEW_HORIZONTAL, val);
      }
    },
    singleClick(val) {
      if (!val) {
        this.isExpanded = true;
      }
    }
  },
  components: {
    "ch-mail": mailItem,
    mailDetailTabs,
    updatePosition,
    Cfeedback,
    shipList: shipList,
    ResizableBlock,
    AddContact,
    AddDepartment,
    AddCompany,
    ContactDetail
  }
};
</script>

<style lang="scss" scoped>
.detail-wrapper {
  text-align: left;
  height: 100%;
  width: 100%;
  padding: 0;
  background: var(--surface-color);
  overflow-y: hidden;
  .detail-contain {
    display: flex;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    .detail-left {
      flex: 1;
      min-width: 0;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      border-left: solid 1px var(--border-color);
      .email-content-wrapper {
        display: flex;
        flex: 1;
        min-height: 0;
        min-width: 0;
        overflow-x: hidden;
      }
      .detail-fixed {
        width: 100%;
        z-index: 100;
        height: 40px;

        .operation-btns {
          display: flex;
          justify-content: space-between;
          text-align: left;
          .operation-right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            div + div {
              margin-left: 2px;
            }
            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              .iconfont {
                font-size: 16px;
                color: var(--text-color);
                vertical-align: middle;
              }
            }
            [class^="iconfont-"] {
              font-size: 20px;
              color: var(--text-color);
              vertical-align: middle;
            }
            .iconfont-resend-all {
              font-size: 14px;
            }
            .iconfont-preview-vertical, .iconfont-preview-horizontal {
              font-size: 16px;
            }
            img {
              width: 14px;
              vertical-align: middle;
            }
          }
          .subject-icons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--text-color);
            border: none;
            font-weight: normal;
            div {
              height: 22px;
              line-height: 22px;
              &:not(:last-child) {
                margin-right: 6.5px;
              }
            }
            .comments-icon {
              min-width: 23px;
              height: 22px;
              line-height: 18px;
              font-size: 13px;
              font-weight: bold;
              display: inline-block;
              text-align: center;
              background: url("../shape@2x.png") no-repeat;
              background-size: 23px 20px;
            }
          }
        }
        .mailDetail-subject {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 15px;
          padding-left: 5px;
          height: 100%;
          background-color: var(--component-color);
          border-bottom: 1px solid var(--border-color);
          .expandIcon {
            border: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .iconfont {
              line-height: 22px;
              font-size: 14px;
            }
          }
        }
        .subject-vertical {
          border-bottom: none;
          .ch-mail-subject-text {
            padding-left: 6px;
            .btn-back {
              border-radius: 0px;
              width: 25px;
              height: 25px;
              display: flex;
              flex: none;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .mail-info {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        overflow: auto;
        overflow: overlay;
        border-right: none;
        .mailDetail-subject {
          .subject-container {
            .ch-mail-subject-text {
              padding: 8px 6px;
              height: unset;
            }
          }
        }
      }
      .subject-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        min-width: 0;
        .ch-mail-subject-text {
          display: flex;
          flex: 1;
          flex-shrink: 0;
          margin-right: 16px;
          align-items: center;
          font-size: 16px;
          height: 22px;
          font-weight: bold;
          text-align: left;
          color: var(--text-color);
          width: 100%;
          .btn-back {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: none;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
              background-color: var(--hover-color);
            }
            i {
              font-size: 20px;
              font-weight: 700;
              padding: 6px;
            }
          }
          .line-color {
            display: inline-block;
            width: 10px;
            min-width: 10px;
            height: 10px;
            border-radius: 100%;
            margin: 0 4px;
            background-color: transparent;
          }
          .mail-subject {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .subject-icons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #8ca0b3;
          border: none;
          font-weight: normal;
          div {
            height: 22px;
            line-height: 22px;
            &:not(:last-child) {
              margin-right: 6.5px;
            }
          }
          .comments-icon {
            min-width: 23px;
            height: 22px;
            line-height: 18px;
            font-size: 13px;
            font-weight: bold;
            display: inline-block;
            text-align: center;
            background: url("../shape@2x.png") no-repeat;
            background-size: 23px 20px;
          }
        }
      }
      .subject-container-vertical {
        margin-left: -16px;
        padding: 8px 0;
      }
    }
  }
}
.half-height {
  min-height: 300px !important;
  max-height: 100% !important;
  position: relative;
  .detail-left {
    border-top: solid 1px var(--border-color);;
  }
}
.half-width {
  min-width: 400px !important;
  max-width: 100% !important;
}
.iconfont[class*="expand"] {
  color: #8ca0b3;
  font-size: 10px;
  cursor: pointer;
}
.tabContainer {
  height: calc(100vh - 58px);
}
.ch-maildetail-aside {
  flex: 0 0 auto;
  width: 400px;
  border: solid 1px #d8e2e7;
}
.ch-button-rightborder {
  border-right: solid 1px #ccc !important;
}
</style>

<style lang="scss">
.open > .ctx-menu {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: 0;
  min-width: 200px;
  width: auto !important;
  li {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  li:last-child {
    border: 0;
  }
}
.half-height {
  .menu-action-item {
    max-height: 200px;
    overflow: scroll;
  }
}
.add-contact-modal {
  .ivu-modal-content {
    height: 90vh;
  }
  .addContact {
    margin-top: 44px;
    padding-top: 8px;
    .backIcon {
      display: none;
    }
  }
}
.contact-detail-modal {
  .ivu-modal-content {
    height: 90vh;
  }
  .detail-wrapper {
    padding-top: 40px;
  }
  .ivu-modal-body {
    height: 100%;
  }
}
</style>

