<template>
  <!-------------abort send------------>
  <draggable-modal
    :show="confirmShow"
    :title="title"
    width="60%"
    :top="138"
    @close="confirmShow=false"
  >
    <!--loading-->
    <div class="loading" v-show="loading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="update-ship-position">
      <p class="positionInfo">
        {{positionInfo}}
      </p>
      <div class="row-contain">
        <!--{{checkGroup}}-->
        <div class="table-contain">
          <table class="ui table position-table ">
            <thead>
            <tr>
              <th class="checkBox">
                <Checkbox :value="isSelectAll" @on-change="selectAll"></Checkbox>
              </th>
              <th class="inputBox">Vessel</th>
              <th class="inputBox port">Port</th>
              <th class="dateDays">Open Dates</th>
              <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            <!--<tr class="">-->
            <tr class="table-row add-row"
                v-for="(item ,index) in ship_positions" :key="index">
              <td class="checkBox">
                <div class="checkGroup" @click.stop="">
                  <Checkbox-group v-model="checkGroup"
                                  class="checkGroup">
                    <Checkbox :label="index">
                      <span></span>
                    </Checkbox>
                  </Checkbox-group>
                </div>
              </td>
              <!--vessel-->
              <td class="inputBox">
                <autoInput v-model="item.ship_name"
                           :category="'vessel'"
                           :width="'width: 160px'"
                           :height="211"
                           :itemHeight="68"
                           @reportError="reportError"
                           @selected="selectDrop($event,'vessel',index)"
                           :ref="index+'vesselInput'"
                           :placeholder="''">
                </autoInput>
              </td>
              <!--port-->
              <td class="inputBox port">
                <autoInput v-model="item.port_name"
                           :category="'port'"
                           :width="'width: 140px'"
                           :height="211"
                           :itemHeight="72"
                           @reportError="reportError"
                           @selected="selectDrop($event,'port',index)"
                           :ref="index+'portInput'"
                           :placeholder="''">
                </autoInput>
              </td>
              <td class="dateDays">
                <div class="date">
                  <DatePicker
                    type="date"
                    v-model="item.open_date"
                    format="dd/MMM/yyyy"
                    :transfer="true"
                    placeholder="">
                  </DatePicker>
                </div>
                <div class="and">+</div>
                <div class="days">
                  <InputNumber v-model.trim="item.days" :min="0"></InputNumber>
                </div>
              </td>
              <td>
                <Input v-model.trim="item.note"
                       placeholder="">
                </Input>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="add-contain" v-if="errorInfo">
          <div class="addNew error" v-show="errorShow">
            <Icon type="md-alert" size="20" color="#FF4542"></Icon>
            <span class="addText error">{{errorInfo}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <div class="text" @click="addNewPosition">
        <Icon type="md-add" size="20"></Icon>
        <span class="addText">Add new ship position</span>
      </div>
      <Button type="primary" :loading="butLoading" @click="savePosition">Save</Button>
    </div>
  </draggable-modal>
</template>

<script type="text/ecmascript-6">
import api from "api";
import autoInput from "./autoInput.vue";
import draggableModal from "../../components/draggableModal.vue";

export default {
  data() {
    return {
      confirmShow: false,
      title: "",
      positionInfo: "",
      loading: false,
      butLoading: false,
      email_id: 0,
      //---------多选
      isSelectAll: true,
      checkGroup: [0, 1, 2],
      ship_positions: [
        {
          id: 0,
          ship_id: 0,
          ship_name: "",
          port_id: 0,
          port_name: "",
          note: "",
          open_date: new Date(),
          days: 0,
          checked: true,
          email_id: 0
        },
        {
          id: 0,
          ship_id: 0,
          ship_name: "",
          port_id: 0,
          port_name: "",
          note: "",
          open_date: new Date(),
          days: 0,
          email_id: 0
        },
        {
          id: 0,
          ship_id: 0,
          ship_name: "",
          port_id: 0,
          port_name: "",
          note: "",
          open_date: new Date(),
          days: 0,
          email_id: 0
        }
      ],
      errorShow: false,
      errorInfo: ""
    };
  },

  methods: {
    show(params) {
      Object.assign(this.$data, this.$options.data());
      this.confirmShow = true;
      if (params.id) {
        //edit
        this.title = "Create ship positions";
        this.positionInfo = params.unique_id;
        this.email_id = params.id;
        this.$nextTick(function() {
          this.getPositions(params.id);
        });
      } else {
        //add
        this.title = "Create ship positions";
        this.positionInfo = "Quick create ship positions ";
      }
    },
    getPositions(mailId) {
      this.loading = true;
      let _this = this;
      api
        .get_position_by_mail(mailId)
        .then(res => {
          if (res.temp_positions.length > 0) {
            this.ship_positions = res.temp_positions.map(item => {
              item.ship_id = 0;
              item.port_id = 0;
              item.email_id = _this.email_id;
              if (item.open_date) {
                let myDateArray = item.open_date.split("-");
                item.open_date = new Date(
                  myDateArray[0],
                  myDateArray[1] - 1,
                  myDateArray[2]
                );
              }
              delete item.added;
              return item;
            });
            this.$nextTick(function() {
              this.checkGroup = this.ship_positions.map((item, index) => index);
            });
          } else {
            this.ship_positions = this.ship_positions.map(item => {
              item.email_id = _this.email_id;
              return item;
            });
          }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    //      select
    selectDrop(val, type, index) {
      if (type === "vessel") {
        this.ship_positions[index].ship_id = val;
      }
      if (type === "port") {
        this.ship_positions[index].port_id = val;
      }
    },
    addNewPosition() {
      const item = {
        id: 0,
        ship_id: 0,
        ship_name: "",
        port_id: 0,
        port_name: "",
        note: "",
        open_date: new Date(),
        days: 0,
        email_id: this.email_id
      };
      this.ship_positions.push(item);
      this.checkGroup.push(this.ship_positions.length - 1);
    },
    savePosition() {
      let ship_positions = [];
      for (let index of this.checkGroup) {
        if (
          this.ship_positions[index].ship_name == "" ||
          this.ship_positions[index].port_name == ""
        ) {
          this.$Message.error("Please check your form!");
          return;
        }
        ship_positions.push(this.ship_positions[index]);
      }
      this.butLoading = true;
      const params = {
        ship_positions: JSON.stringify(ship_positions)
      };
      api
        .update_ship_position(params)
        .then(res => {
          this.butLoading = false;
          this.$Message.success("Create ship position successfully!");
          if (
            this.$route.name === "shipDetail" ||
            this.$route.name === "shipping"
          ) {
            this.$emit("refresh");
          }
          this.$emit("refreshDetail");
          this.confirmShow = false;
        })
        .catch(error => {
          console.log(error);
          this.butLoading = false;
        });
    },
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.checkGroup = this.isSelectAll ? this.ship_positions.map((item, index) => index) : [];
    },

    reportError(para) {
      this.errorShow = para.error;
      this.errorInfo = para.text;
    }
  },
  components: {
    draggableModal,
    autoInput
  }
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.update-ship-position {
  font-size: 14px;
  border-top: 1px solid var(--border-color);
  .positionInfo {
    font-size: 12px;
    color: var(--label-color);
    padding: 9px 16px;
  }
  .row-contain {
    .table-contain {
      max-height: 322px;
      overflow: auto;
      overflow: overlay;
      .position-table {
        th {
          &:not(:first-child) {
            padding-left: 0;
          }
          &.checkBox {
            width: 1%;
            min-width: 40px;
            padding-right: 0;
            padding-left: 16px;
          }
          &.inputBox {
            width: 160px;
            &.port {
              width: 140px;
            }
          }
          &.dateDays {
            width: 200px;
          }
        }
        td {
          height: 48px;
          padding: 0 10px;
          
          &:not(:first-child) {
            padding-left: 0;
          }
          &.checkBox {
            width: 1%;
            min-width: 40px;
            padding-right: 0;
            padding-left: 16px;
          }
          &.inputBox {
            width: 160px;
            &.port {
              width: 140px;
            }
          }
          &.dateDays {
            display: flex;
            align-items: center;
            .date {
              width: 125px;
            }
            .and {
              padding: 0 3px;
            }
          }
        }
      }
    }
    .add-contain {
      display: flex;
      height: 50px;
      padding-left: 16px;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      .addNew {
        display: flex;
        align-items: center;
        width: 180px;
        cursor: pointer;
        height: 50px;
        &.error {
          width: auto;
          margin-left: 25px;
        }
        .addText {
          margin-left: 12px;
          margin-top: -2px;
          &.error {
            margin-left: 8px;
            color: #ff4542;
          }
        }
      }
    }
  }
}
</style>
