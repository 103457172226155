<template>
  <div class="users-table-wrapper">
    <div class="line-table" :style="maxLineHeight">
      <div class="line-item all-users"
           @click="selectLine(-1)">
        <div class="line-name">All Lines</div>
        <div class="line-right">
          <div class="line-count">{{linesDate.length}}</div>
          <div class="line-checkbox" @click.stop="">
            <Checkbox :value="isSelectAllLines" @on-change="selectAllLines"></Checkbox>
          </div>
        </div>
      </div>
      <div class="lines-container">
        <div class="line-item"
              v-for="(item, index) in linesDate" :key="index"
              @click="selectLine(index,item)"
              :class="{'highlight':selectedLine===index}">
          <div class="line-name">{{item.line.username}}</div>
          <div class="line-right">
            <div class="line-count">{{item.users && item.users.length || 0}}</div>
            <div class="line-checkbox" @click.stop="">
              <Checkbox-group v-model="checkLineGroup" @on-change="checkedLine($event,item.line.id,index)"
                              class="checkGroup">
                <Checkbox :label="item.line.id">
                  <span></span>
                </Checkbox>
              </Checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="users-table">
      <div class="table-contain ivu-table-with-fixed-top"
           :class="{'over-height':tableData && tableData.length > 5}">
        <!--table header-->
        <div class="ivu-table-header">
          <table class="ui table access-table">
            <thead :class="{'fixedWidth':tableData && tableData.length > 5}">
            <tr>
              <th class="checkBox">
                <Checkbox :value="isSelectAllUsers" @on-change="selectAllUsers"></Checkbox>
              </th>
              <th class="name">Name</th>
              <th class="email">Email</th>
              <th class="filter">
                <i class="iconfont-filter" @click="displayFilter"></i>
              </th>
            </tr>
            <!--header Filter-->
            <tr v-if="showFilter" class="filterHeads">
              <td style="width:30px;"></td>
              <td><input type="text" placeholder="Name" v-model="filterName"></td>
              <td><input type="text" placeholder="Email" v-model="filterEmail"></td>
              <td></td>
            </tr>
            </thead>
          </table>
        </div>
        <!--table body-->
        <div class="ivu-table-body ivu-table-overflowY" :style="bodyStyle">
          <table class="ui table access-table">
            <tbody>
            <tr v-for="(item , index) in filterList" :key="index"
                class="access-row">
              <td class="checkBox">
                <div class="checkGroup" @click.stop="">
                  <Checkbox-group v-model="checkGroup" @on-change="checkedUsers($event,item.id,index)"
                                  class="checkGroup">
                    <Checkbox :label="item.id">
                      <span></span>
                    </Checkbox>
                  </Checkbox-group>
                </div>
              </td>
              <td class="name"><span>{{item.name}}</span></td>
              <td class="email"><span>{{item.email}}</span></td>
              <td class="filter"></td>
            </tr>
            <tr v-if="!tableData || tableData.length==0">
              <td colspan="4" style="text-align: center">No data found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };
  export default{
    name: 'userHybridTable',
    props: {
      linesDate: {
        type: Array,
        default: []
      },
      usersDate: {
        type: Array,
        default: []
      },
      maxLineHeight: {
        type: String,
        default: 'max-height: 292px;'
      },
      scene: {//应用场景
        type: String,
        default: 'modal'
      },
      checkedGroup: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        tableData: this.usersDate,
        selectedLine: -1,
        isSelectAllLines: false,
        isSelectAllUsers: false,
        checkLineGroup: [],
        checkGroup: this.checkedGroup,
        setGroup: new Set(),
        showFilter: false,
        filterName: '',
        filterEmail: '',
      }
    },
    beforeCreate(){ },
    created () { },
    mounted () { },
    updated() {
    },
    methods: {
      selectLine(index, item){
        this.selectedLine = index;
        const arr = this.checkGroup;
        this.checkGroup = [];
        this.tableData = index === -1 ? this.usersDate : item.users;
        this.$nextTick(function () {
          this.checkGroup = arr;
          const index = this.checkLineGroup.indexOf(item.line.id);
          this.isSelectAllUsers = index !== -1;
        })
      },
//--------------------------table
      selectAllLines () {
        this.isSelectAllLines = !this.isSelectAllLines;
        this.isSelectAllUsers = this.isSelectAllLines;
        this.checkLineGroup = this.isSelectAllLines ? this.linesDate.map(item => item.line.id) : [];
        this.checkGroup = this.isSelectAllLines ? this.usersDate.map(item => item.id) : [];
      },

      selectAllUsers(){
        this.isSelectAllUsers = !this.isSelectAllUsers;
        this.checkGroup = this.isSelectAllUsers ? this.filterList.map(item => item.id) : [];
      },

      checkedLine(arr, lineId, lineIndex){
        const index = arr.indexOf(lineId);
        if (index !== -1) {
          if (this.selectedLine === lineIndex) {
            this.isSelectAllUsers = true;
          }
          this.setGroup = new Set(this.checkGroup);
          for (let item of this.linesDate) {
            if (item.line.id === lineId) {
              let users = item.users;
              users.map(x => this.setGroup.add(x.id));
              this.checkGroup = Array.from(this.setGroup)
            }
          }
        } else {
          if (this.selectedLine === lineIndex) {
            this.isSelectAllUsers = false;
          }
          this.isSelectAllLines = false;
          let deleteIds = [];
          for (let item of this.linesDate) {
            if (item.line.id === lineId) {
              let users = item.users;
              deleteIds = users.map(x => x.id);
              users.map(x => this.checkGroup.remove(x.id));
            }
          }

          this.$nextTick(function () {
            for (let item of this.linesDate) {
              for (let i of deleteIds) {
                const userIds = item.users.map(x => x.id);
                const index = userIds.indexOf(i);
                if (index !== -1) {
                  this.checkLineGroup.remove(item.line.id)
                }
              }
            }
          })
        }
      },

      checkedUsers(arr, userId, userIndex){
        console.log(arr, this.usersDate, this.filterList)
        const index = arr.indexOf(userId);
        if (index !== -1) {
        } else {
          if (arr.length < this.usersDate.length) {
            this.isSelectAllLines = false;
          }
          if (arr.length < this.filterList.length) {
            this.isSelectAllUsers = false;
          }
          for (let item of this.linesDate) {
            const userIds = item.users.map(x => x.id);
            const index = userIds.indexOf(userId);
            if (index !== -1) {
              this.checkLineGroup.remove(item.line.id)
            }
          }
        }
      },

      displayFilter() {
        this.showFilter = !this.showFilter;
      },
    },
    filters: {},
    computed: {
      filterList: function () {
        if (this.tableData && this.tableData.length !== 0) {
          var listData = this.tableData;
          var searchName = this.filterName && this.filterName.toLowerCase();
          var searchEmail = this.filterEmail && this.filterEmail.toLowerCase();
          return listData.filter(function (item) {
            return item.name.toLowerCase().indexOf(searchName.toLowerCase()) != -1 && item.email.toLowerCase().indexOf(searchEmail.toLowerCase()) != -1;
          });
        }
      },
      bodyStyle: function () {
        if (this.showFilter) {
          if (this.scene === 'router')return 'max-height: calc(100vh - 355px)';
          if (this.scene === 'modal')return 'max-height: 205px;'
        } else {
          if (this.scene === 'router')return 'max-height: calc(100vh - 310px)';
          if (this.scene === 'modal')return 'max-height: 250px;'
        }
      }
    },
    watch: {
      'checkGroup': function () {
        this.$emit('updateCheckGroup', this.checkGroup)
      },
    },
    components: {}
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .users-table-wrapper {
    display: flex;
    flex-shrink: 0;
    background: var(--component-color);
    color: var(--on-component-color);
    border-bottom: solid 1px var(--border-color);
    .line-table {
      display: flex;
      position: relative;
      width: 216px;
      min-height: 200px;
      overflow: hidden;

      .lines-container {
        overflow: auto;
        overflow: overlay;
        margin-top: 40px;
      }
      .line-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px 0 20px;
        height: 40px;
        width: 100%;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.2s ease-in-out;
        &.all-users {
          position: absolute;
          width: 216px;
          z-index: 2;
          top: 0;
          cursor: auto;
        }
        &.highlight {
          background: var(--highlight-color);
          color: var(--text-color);
        }

        .line-name {
          width: 60%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .line-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 40%;
          text-align: right;
          .line-count {
            font-size: 14px;
            color: var(--primary-color);
            margin-right: 14px;
          }
        }

      }
    }
    .users-table {
      position: relative;
      width: calc(100% - 216px);
      border-left: solid 1px rgba(32, 53, 128, 0.162364);
      .table-contain {
        border-radius: 0;
        border-top: solid 1px rgba(32, 53, 128, 0.162364);
        &.over-height {
          border-bottom: none;
        }
        .access-table {
          min-width: 100px;
          th {
            width: 10%;
            &.checkBox {
              width: 2%;
              text-align: left;
            }
            &.name {
              width: 5%;
            }
            &.email {
              width: 12%;
            }
            &.filter {
              width: 4%;
              text-align: right;
              .iconfont-filter {
                font-size: 14px;
                vertical-align: middle;
                cursor: pointer;
              }
            }
          }
          td {
            width: 10%;
            height: 45px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.checkBox {
              width: 2%;
              white-space: normal;
            }
            &.name {
              width: 5%;
            }
            &.email {
              width: 12%;
            }
            &.filter {
              width: 4%;
            }
          }
          .filterHeads {
            td {
              border-top: dashed 1px rgba(20, 40, 74, 0.4) !important;
              border-bottom: dashed 1px rgba(20, 40, 74, 0.4) !important;
              &:first-child {
                border-left: dashed 1px rgba(20, 40, 74, 0.4);
              }
              &:last-child {
                border-right: dashed 1px rgba(20, 40, 74, 0.4);
              }
            }
            input {
              width: 100%;
              outline: none;
              border: none;
              background: transparent;
              font-size: 12px;
              font-weight: bold;
              text-align: left;
            }
          }
        }
        .ivu-table-body {
          .access-table {
            border-radius: 0;
          }
        }
        .access-row {
          td {
            transition: background-color .25s ease-in-out;
          }
        }
      }
    }
  }

</style>
