<template>
  <div class="position-relative" :class="{'isPreview':isPreview}">
    <div class="small-notice" v-if="!isPreview && !hideChatBox">
      Begin chat for <span v-html="uniqueId"></span>
    </div>
    <!--Chat List-->
    <div v-if="!hideChatBox" class="ch-chart-text-container ch-flex direction-column"
      ref="scrollDiv"
      :style="{height: height}"
    >
      <div class="ch-chart-list" ref="scrollList">
        <div v-for="(message,index) in myComments" :key="index" class="ch-chart-row">
          <!--Date-->
          <div class="ch-chat-time" v-if="!message.same_day">
            <span>{{ message.day }}</span>
          </div>
          <!--Message-->
          <div class="ch-flex" :class="{'chat-others': isOthers(message), 'chat-my': !isOthers(message)}">
            <avatar v-if="message.avatar && isOthers(message)" class="avatar" :fullname="message.user_name" :avatarURL="message.avatar" :width="35"></avatar>
            <div class="ch-chart-row-main" :class="{'info': !message.id}">
              <!--User info-->
              <div class="ch-chart-row-header" v-show="isOthers || message.created_at">
                <p v-if="message.user_name && isOthers(message)" class="username">{{ message.user_name }}</p>
                <p class="createtime">{{ message.created_at }}</p>
              </div>
              <Poptip
                :transfer="true"
                :placement="isOthers(message) ? 'top-start' : 'top-end'"
                :disabled="!menuShown(message)"
                popper-class="chat-menu"
              >
                <!--Message content-->
                <div class="ch-chart-row-content"
                  :class="[{'clickable': menuShown(message)}, {'info': !message.id}]"
                  v-html="sanitizeHTML(message.html)"
                />
                <!--Action menu-->
                <div slot="content" class="actions">
                  <template v-if="comment_type == 'Email' && message.mentions && message.mentions.length > 0">
                    <div
                      class="action-item"
                      v-for="(mention, index) in message.mentions"
                      :key="index"
                      @click="showRemoveMentionConfirm(mention)"
                    >
                      Remove mention @{{ mention.name }}
                    </div>
                  </template>
                  <template v-if="(comment_type == 'Ship' || comment_type=='AddressBook') && message.tags && message.tags.length > 0">
                    <div
                      class="action-item"
                      v-for="(tag, index) in message.tags" :key="index"
                      @click="onRemoveTag(tag, message)"
                    >
                      Remove tag #{{ tag }}
                    </div>
                  </template>
                </div>
              </Poptip>
            </div>
          </div>
        </div>
      </div>
      <span v-if="definedTagsEnabled && newTags && newTags.length > 0" class="added-tag-alert">
        <span v-if="p_tags" class="info">
          <Icon class="icon" type="ios-information-circle-outline" />
          New tag{{ newTags.length>1 ? 's' : '' }} has been added to the defined tags list.
        </span>
        <span v-if="!p_tags" class="error">
          <Icon class="icon" type="ios-alert-outline" />
          Please request access to create new tags.
        </span>
      </span>
    </div>

    <!--Chat Editor-->
    <div v-if="!hideReplyEditor" class="ch-chart-reply ch-flex align-center"
         :class="{'reply-fixed':isPreview}">
      <avatar class="avatar" :fullname="userInfo.user.name" :avatarURL="userInfo.user.avatar"></avatar>
      <div class="ui input">
        <at v-on:atShow="disabledEnter=true"
            v-on:hideShow="disabledEnter=false"
            :content="html"
            :type="comment_type">
          <div class="atwhoinput_editor"
               ref="atwhoinput"
               :data-text="placeholder"
               contenteditable
               @blur="changed"
               @paste="changed"
               @delete="changed"
               @focus="changed"
               @keydown="changed"
               @keydown.enter="submitComment">
          </div>
        </at>
      </div>
    </div>

    <!--Confirmation-->
    <Modal v-if="selectedMention"
      v-model="mentionRemoveConfirm"
      @on-ok="removeMention"
      @on-cancel="mentionRemoveConfirm=false"
    >
    Are you sure to remove mention {{selectedMention.type}} @{{selectedMention.name}}?
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import util from "../../../utils/index.js";
import api from "api";
import dayjs from "dayjs";
import { mapActions, mapGetters, mapState } from "vuex";

import At from "./At/At.vue";
import avatar from "@/pages/components/avatar";
import appConstant from "@/common/constants/app.constant";
import CommentHelper from "@/helpers/comment.helper";

export default {
  props: {
    commentable_id: {},
    comments: {
      default: () => {}
    },
    tags: {
      type: Array
    },
    height: {
      type: String,
      default: "calc(100vh - 139px)",
      required: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    comment_type: {
      type: String,
      default: "Email"
    },
    uniqueId: {
      type: String
    },
    placeholder: {
      type: String
    },
    hideChatBox: {
      type: Boolean
    },
    hideReplyEditor: {
      type: Boolean
    },
    readOnly: {
      type: Boolean
    }
  },
  components: {
    at: At,
    avatar
  },
  data() {
    return {
      comment: "",
      innerComments: this.comments,
      innerTags: this.tags || [],
      html: "",
      disabledEnter: false,
      mentionRemoveConfirm: false,
      selectedMention: undefined,

      newTags: [],
      tagAlertInterval: undefined
    };
  },
  computed: {
    ...mapGetters(["userInfo", 'teamMembers', 'setting_company']),
    ...mapState(['configuredTags']),
    myComments() {
      if (this.innerComments) {
        return this.innerComments.map((comment, i) => {
          let newcomment = Object.assign({}, comment);
          newcomment.html = CommentHelper.format2html(comment.content);
          newcomment.mentions = CommentHelper.parseMentions(comment.notifications, this.teamMembers);
          newcomment.tags = this.extractTags(comment);
          if (i > 0) {
            let lastTime = new Date(this.innerComments[i - 1].created_at).getMinutes();
            let now = new Date(newcomment.created_at).getMinutes();
            if (now - lastTime === 0) {
              delete newcomment.created_at;
              newcomment.same_day = true;
            } else {
              const lastDate = this.innerComments[i - 1].created_at;
              newcomment.same_day = dayjs(newcomment.created_at).isSame(
                dayjs(lastDate),
                "day"
              );
              newcomment.created_at = util.time_12(comment.created_at);
              newcomment.day = util.relativeDay(comment.created_at);
            }
          } else {
            newcomment.created_at = util.time_12(comment.created_at);
            newcomment.same_day = false;
            newcomment.day = util.relativeDay(comment.created_at);
          }
          return newcomment;
        });
      } else {
        return "";
      }
    },

    showCreatedTime() {
      return true;
    },
    isAdmin() {
      return this.userInfo.user.user_type === "admin";
    },
    p_tags() {
      return this.isAdmin || this.checkPermission("add_edit_delete_configured_tags");
    },
    definedTagsEnabled() {
      return this.setting_company.limit_hashtag == '1';
    }
  },
  methods: {
    ...mapActions(["sendComments"]),
    submitComment(e) {
      if (this.hideChatBox) {
        return;
      }
      let self = this;
      if (this.disabledEnter) {
        this.disabledEnter = false;
        return;
      }
      this.html = e.target.innerHTML.trim().replace(/<br>|<div>|<\/div>/g, "");
      let params = {
        commentable_id: this.commentable_id,
        commentable_type: this.comment_type,
        content: CommentHelper.html2format(this.html),
        via: this.$route.query.via
      };
      this.html = "";
      e.target.innerHTML = "";
      if (params.content) {
        this.sendComments(params).then(res => {
          util.functionBus.readMail && util.functionBus.readMail();
          this.$emit("submitDone", res.comment)
          if(res.comment && this.innerComments.findIndex(c => c.id == res.comment.id) == -1) {
            res.comment.notifications = CommentHelper.extractMentions(res.comment);
            this.innerComments.push(res.comment);
            const tags = CommentHelper.extractTags(res.comment);
            this.addNewTags(tags);
            this.showTagAlert(tags);
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          }
        });
        e.preventDefault();
        e.stopPropagation();
      } else {
        this.$Message.info("The content should not be empty.")
      }
    },
    scrollToBottom() {
      const e = this.$refs.scrollList;
      if (!e) return;
      e.scrollTop = e.scrollHeight - e.getBoundingClientRect().height;
    },
    changed(e) {
      if (e.keyCode === 13) return; //enter
      if (e.altKey && e.keyCode === 67) { // Alt + c
        return e.target.blur()
      }

      this.html = e.target.innerHTML.trim();
    },
    includeMention(commentText) {
      return /@\[(.*?)\]\((\d+)\)(\{+user+\})/g.test(commentText) || /@\[(.*?)\]\((\d+)\)(\{+team+\})/g.test(commentText);
    },
    showRemoveMentionConfirm(mention) {
      this.mentionRemoveConfirm = true;
      this.selectedMention = mention;
    },
    async removeMention() {
      await api.removeMentions([this.selectedMention.to_id], this.commentable_id);
      this.innerComments.forEach((comment, index) => {
        comment
          && comment.notifications
          && (comment.notifications = comment.notifications.filter(m => m.to_id != this.selectedMention.to_id))
        this.$set(this.innerComments, index, comment)
      });
      this.$Message.success('Mention removed');
    },
    menuShown(message) {
      if(this.readOnly)
        return false;
      return (this.comment_type == 'Email' && message.mentions && message.mentions.length > 0)
        || ((this.comment_type == 'Ship' || this.comment_type == 'AddressBook') && message.tags && message.tags.length > 0)
    },
    onRemoveComment(comment, deleteTag) {
      this.$Modal.confirm({
        title: 'Confirmation',
        content: `<p style="margin-left:-42px">Are you sure to remove this comment ${deleteTag ? ' and tags' : ''}?</p>`,
        onOk: async () => {
          const params = {
            commentable_type: this.comment_type,
            commentable_id: this.commentable_id,
            ids: [comment.id],
            delete_tag: deleteTag || false
          }
          await api.remove_comment(params);
          this.innerComments = this.innerComments.filter(c => c.id !== comment.id);
          deleteTag && this.removeTags(comment.tags);
          this.$Message.success(`Comment${deleteTag ? ' and tags' : ''} removed`);
        }
      });
    },
    onRemoveTag(tag, comment) {
      this.$Modal.confirm({
        title: 'Confirmation',
        content: `<p style="margin-left:-42px">Are you sure to remove tag #${tag} ?</p>`,
        onOk: async () => {
          const params = {
            tagable_type: this.comment_type,
            tagable_id: this.commentable_id,
            names: [tag]
          }
          await api.delete_tag(params);
          this.removeTags([tag]);
          this.$Message.success("Tag removed");
          this.$emit('tagChanged', this.innerTags);
        }
      });
    },
    removeTags(deletedTags) {
      this.innerTags = this.innerTags.filter(it => deletedTags.findIndex(t => t == it) == -1)
    },
    extractTags(comment) {
      let tags = CommentHelper.extractTags(comment);
      return tags.filter(t => this.innerTags.findIndex(it => it == t) != -1)
    },
    isOthers(message) {
      return message.user_name != this.userInfo.user.user_name;
    },
    addNewTags(tags) {
      if(!tags || tags.length == 0)
        return;
      const validTags = this.definedTagsEnabled && !this.p_tags
        ? tags.filter(tag => this.configuredTags.findIndex(ct => ct == tag) > -1)
        : tags;
      this.innerTags = [...this.innerTags, ...validTags];
      this.$emit('tagChanged', this.innerTags);
    },
    showTagAlert(tags) {
      if(!tags || tags.length == 0)
        return;
      this.newTags = tags.filter(tag => this.configuredTags.findIndex(ct => ct == tag) == -1) || [];
      if(this.newTags.length == 0)
        return;
      let second = 6;
      this.tagAlertInterval = setInterval(() => {
        second > 0 && second--;
        second == 0 && (this.newTags = []) && clearInterval(this.tagAlertInterval);
      }, 1000)
    },
    clearChatbox() {
      this.html = ""
      this.$refs.atwhoinput && (this.$refs.atwhoinput.innerHTML = '')
    }
  },
  watch: {
    comments: {
      handler: function(val, oldVal) {
        this.innerComments = val;
      }
    },
    tags: {
      handler: function(val, oldVal) {
        this.innerTags = val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.position-relative {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

[contentEditable="true"]:empty:before {
  content: attr(data-text);
  font-size: 14px;
  text-align: left;
  color: #a7beca;
  cursor: text;
}

.ch-chart-reply {
  overflow: visible;
  height: 60px;
  padding: 1em;
  border-top: solid 1px var(--border-color);
  background-color: var(--component-color);
  color: var(--on-component-color);
  flex: 0 0 4em;
  .avatar {
    position: absolute;
    display: inline-block;
    margin-right: 10px;
  }
  .input {
    width: 100%;
    .atwhoinput_editor {
      padding-left: 50px;
    }
  }
  input {
    border: none;
  }
}

.ch-chat-time {
  margin-top: 16px;
  span {
    border-radius: 5px;
    background-color: var(--component-color);
    color: var(--on-component-color);
    border: 1px solid var(--border-color);
    display: inline-block;
    padding: 4px 8px;
    font-weight: bold;
    font-size: 12px;
  }
}

.ch-chart-text-container {
  flex: 1;
  min-height: 0;
  background: var(--background-color);
  position: relative;
  .ch-chart-list {
    overflow: auto;
    overflow: overlay;
  }
  .added-tag-alert {
    position: absolute;
    bottom: 8px;
    font-size: 11px;
    left: 8px;
    .icon {
      font-size: 16px;
    }
    .info {
      color: var(--primary-color);
    }
    .error {
      color: var(--error-color);
    }
  }
}

.isPreview {
  .reply-fixed {
    min-width: 372px;
  }
}

.ch-chart-row {
  .chat-others {
    margin: 1em;
    .ch-chart-row-main {
      margin-left: 10px;
      max-width: 66.67%;
      text-align: left;
      &.info {
        width: 100%;
        max-width: 100%;
        .ch-chart-row-content {
          width: 100%;
          background: none !important;
          border: 1px solid var(--primary-color);
          border-radius: 0;
          font-style: italic;
          word-break: break-word;
        }
      }
      .ch-chart-row-header {
        display: flex;
        margin-bottom: 2px;
        align-items: baseline;
        .username {
          margin-right: 8px;
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
          text-align: left;
        }
        &.right {
          .createtime {
            font-size: 12px;
            color: #8ca0b3;
            text-align: right;
          }
        }
      }
      .ch-chart-row-content {
        background: var(--primary-contrast);
        color: var(--text-color);
        word-break: break-word;
      }
    }
  }
  .chat-my {
    margin: 4px 1em 4px 1em;
    justify-content: flex-end;
    .ch-chart-row-main {
      margin-left: 16px;
      max-width: 66.67%;
      text-align: right;
      .ch-chart-row-content {
        background: var(--blue-main);
        color: #fff;
        word-break: break-word;
      }
    }
  }
  .ch-chart-row-content {
    display: inline-block;
    padding: 6px 8px;
    border-radius: 6px;
    font-size: 12px;
    overflow: hidden;
    max-width: 100%;
    word-break: break-word;
    text-align: start;
    span,
    strong {
      color: #ffffff;
    }
  }
  .createtime {
    line-height: 19px;
    font-size: 12px;
    text-align: left;
    color: #8ca0b3;
  }
  .clickable {
    cursor: pointer;
  }
}

.atwhoinput_editor {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  height: 45px;
  font-size: 14px;
  white-space: pre-wrap;
  border: none;
  color: var(--on-component-color);

  &:focus {
    outline: none;
  }
}

.atwhoinput_editor img {
  max-width: 10em;
  vertical-align: bottom;
}
</style>

<style lang="scss">
.chat-menu {
  .action-item {
    cursor: pointer;
    text-align: left;
    padding: 4px;

    & + .action-item {
      border-top: 1px solid var(--border-color);
    }

    &:hover {
      background: var(--hover-color);
    }
  }
}
</style>
