<template>
  <div class="mail-item-contextmenu"
    :class="{'hidden': !shown}"
  >
    <!-- <div class="backdrop" @click="closeMenu"></div> -->
    <div class="content"
      ref="contextMenuContent"
      :style="{top: `${position.top}px`, left: `${position.left}px`}">
      <ul>
        <Dropdown
          v-if="!isSpamList && isSingleSelected && !inEditMode"
          placement="right"
          :transfer="true"
          class="context-menu-item"
        >
          <li @click.stop="openSendModalFromContextMenu('reply')">
            Reply
            <Icon type="ios-arrow-forward" />
          </li>
          <DropdownMenu slot="list" class="context-menu-item-dropdown-menu">
            <DropdownItem @click.native.stop="openSendModalFromContextMenu('reply', 'include_attachments')">
              Reply with attachments
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          v-if="!isSpamList && isSingleSelected && !inEditMode"
          placement="right"
          :transfer="true"
          class="context-menu-item"
        >
          <li @click.stop="openSendModalFromContextMenu('replyall')">
            Reply all
            <Icon type="ios-arrow-forward" />
          </li>
          <DropdownMenu slot="list">
            <DropdownItem @click.native.stop="openSendModalFromContextMenu('replyall', 'include_attachments')">
              Reply all with attachments
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <li v-if="isSingleSelected && !inEditMode" @click.stop="openSendModalFromContextMenu('forward')">
          Forward
        </li>
        <li v-if="isSingleSelected && !inEditMode" @click.stop="openSendModalFromContextMenu('resend')">
          Resend all
        </li>
        <li v-if="isSingleSelected && !inEditMode" @click.stop="openSendModalFromContextMenu('emailall')">
          Email all
        </li>
        <li v-if="isSingleSelected && inEditMode" @click.stop="openSendModalFromContextMenu('edit')">
          Edit
        </li>
        <Dropdown-item v-if="!isSpamList && isSingleSelected" divided style="height:0;padding:0"></Dropdown-item>
        <li
          v-if="!isSpamList && p_enabled_assignment"
          class="assignment-menu-item"
          @click.stop="onAssignmentClick"
        >
          <assignment class="assignment"
            ref="assignment"
            :subjects="emails"
            :value="emails.length == 1 ? emails[0] && emails[0].assignment_ids : []"
            :members-placement="'right'"
            :show-tooltip="false"
            :click-outside="false"
            popup-placement='right-start'
            @done="assignDone"
          >
            <span class="item">Assign to</span>
          </assignment>
        </li>
        <li
          v-if="!isSpamList"
          ref="emailFiledMenuItem"
          @click.exact.stop="showEmailFilingMenu"
        >
          File to folder
          <EmailFiling
            ref="subMenu"
            v-if="emailFilingShown"
            class="file-to-folder"
            :style="{ top: `${submenuPosition.top}px`, left: `${submenuPosition.left}px` }"
            :emails="emails"
            @done="onEmailFileDone"
          >
          </EmailFiling>
        </li>
        <li v-if="!isSpamList && !isOutboxList && !isDraftList && !isSentList && !isArchivedList && p_mark_spam"
          @click.stop="onMarkAsSpam"
        >
          Mark as spam
        </li>
        <li
          v-if="emails.some(el => !el.is_hide)"
          @click.stop="onMarkAsHidden()"
        >
          Mark as hidden
        </li>
        <li
          v-if="emails.some(el => el.is_hide)"
          @click.stop="onMarkAsUnHide()"
        >
          Unhide
        </li>
        <li v-if="isSpamList" @click.stop="onUnspam">
          Not spam
        </li>
        <li v-if="!!p_enabled_archive && !isSpamList && !isArchivedList && isSingleSelected" @click.stop="onArchiveEmail">
          Archive
        </li>
        <li v-if="isSingleSelected" @click.stop="downloadEmailFile">
          {{ downloadEmlBtn }}
        </li>
        <li v-if="emails && emails.some(e => !e.read)"
          @click.stop="onMarkAsRead"
        >
          Mark as read
        </li>
        <li v-if="p_mark_as_unread"
          @click.stop="onMarkAsUnread"
        >
          Mark as unread
        </li>
        <Dropdown-item v-if="isSingleSelected" divided style="height:0;padding:0"></Dropdown-item>
        <li v-if="isSingleSelected" @click.stop="showEmailHeader">
          Show header
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import assignment from '@/pages/components/assignment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmailFiling from '@/pages/components/emailFiling/EmailFiling.vue'
import { popupPosition, mailDetailActionMixin } from '@/mixins';

export default {
  props: {
  },
  mixins: [popupPosition, mailDetailActionMixin],
  data() {
    return {
      emails: [],
      shown: false,
      emailFilingShown: false,
      top: 0,
      left: 0,
      submenuPosition: {}
    }
  },
  computed: {
    ...mapGetters(['setting_company', 'inappAttachmentPreview', 'userInfo']),
    position() {
      let position = { top: this.top, left: this.left };
      const menu = this.$refs.contextMenuContent;
      menu && (position = this.computePosition(
        {
          top: this.top,
          left: this.left,
          right: this.left,
          bottom: this.top,
          width: 0,
          height: 0
        },
        menu,
        'right'
      ))
      return position;
    },
    // permission for delete share group
    p_mark_spam() {
      if (!this.userInfo.user) return false;
      return this.checkPermission("mark_email_as_spam");
    },
    // permission to archive email
    p_enabled_archive() {
      return this.setting_company.enable_archive == '1'
    },
    p_enabled_assignment() {
      return this.setting_company.assignment_module == '1';
    },
    p_mark_as_unread() {
      if(this.setting_company.mark_as_unread == '0')
        return false
      
      if(!this.emails || this.emails.length == 0)
        return false

      if(this.emails.length > 1)
        return false

      if(this.emails[0].username === this.userInfo.user.user_name)
        return false
      
      if(!this.emails.some(e => e.read))
        return false

      return true
    },
    isSpamList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'spam';
    },
    isArchivedList() {
      return !!this.$route_archived();
    },
    isOutboxList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'sent' && this.$route_status() == 'pending';
    },
    isDraftList() {
      return this.$route_box() && this.$route_box().toLowerCase() === 'draft';
    },
    isSentList() {
      return this.$route_status() == 'sent';
    },
    isSingleSelected() {
      return (this.emails || []).length === 1
    },
    inEditMode() {
      if (!this.isSingleSelected) return false
      return this.emails[0].status === 'schedule' || this.emails[0].box.toLowerCase() === 'draft'
    }
  },
  mounted() {
    document.documentElement.addEventListener('click', this.clickOutside)
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('click', this.clickOutside)
  },
  methods: {
    ...mapActions(['composeMail', 'lockDraft', 'readEmail']),
    ...mapMutations(['UPDATE_MAIL_LIST_ITEMS']),
    async downloadEmailFile() {
      const email = this.emails[0]
      if (!email) {
        this.closeMenu();
        return;
      }

      await this.downloadEml(email.id, email.subject);
      this.closeMenu();
    },
    showEmailHeader() {
      const email = this.emails[0]
      if (!email) {
        this.closeMenu();
        return;
      }

      this.showHeader(email.id)
      this.closeMenu();
    },
    handleReadEmail(email) {
      const para = {
        rowId: email.id,
        lineId: email.line_ids,
        receivedAt: email.received_at,
        box: email.box,
        archived: email.archived
      };
      this.UPDATE_MAIL_LIST_ITEMS({
        ids: [email.id],
        changedProps: (_) => ({ read: true })
      })
      this.readEmail(para);
    },
    async openSendModalFromContextMenu(val, extraAction) {
      const email = this.emails[0]
      if (!email) {
        this.closeMenu();
        return;
      }

      if (this.inappAttachmentPreview.isPreviewing) {
        this.$store.commit("UPDATE_INAPP_ATTACHMENT_PREVIEW", {isPreviewing: false, attachment: null, maximized: false});
      }

      if (val === "edit" && email.status === 'schedule') {
        this.requestAbortSend([email.id]);
      }

      const isEditDraft = val === "edit" && email.box.toLowerCase() === 'draft'
      if(isEditDraft) {
        await this.lockDraft(email.id)
      }

      this.handleReadEmail(email);
      const normalFolderType = this.$route_folder_type() === "normal";

      let currentOpenMailbox = this.$route.query.line_id
      if (!currentOpenMailbox && this.$route.query.tab && this.$route.query.tab.includes("line_")) {
        currentOpenMailbox = this.$route.query.tab.split("_")[1]
      }

      const urlObj = {
        path: "sendMail",
        query: {
          method: val,
          id: email.id,
          folder_id: normalFolderType && this.$route_folder_id() ? this.$route_folder_id() : null,
          folder_name: normalFolderType && this.$route_folder_name() ? this.$route_folder_name() : null,
          line_id: currentOpenMailbox ? currentOpenMailbox : null,
          action: extraAction ? extraAction : null
        },
        inNewWindow: true
      }

      this.composeMail(urlObj);
      this.closeMenu();
    },
    onAssignmentClick() {
      this.emailFilingShown = false;
    },
    assignDone() {
      this.closeMenu()
    },
    onEmailFileDone(folders) {
      if(folders && folders.length > 0) {
        this.emails = this.emails.map(mail => {
          mail.filed = true;
          return mail;
        })
      }
      this.closeMenu();
    },
    closeMenu() {
      if(this.shown) {
        this.shown = this.emailFilingShown = false;
        this.$emit('onClose')
        this._closeAssignmentMenu();
      }
    },
    showEmailFilingMenu(event) {
      this._closeAssignmentMenu();
      this.emailFilingShown = !this.emailFilingShown;
      this._showSubmenu(event);
    },
    onMarkAsUnHide() {
      this.emails && this.$emit('markAsUnHide', this.emails.filter(el => el.is_hide).map(m => m.id));
      this.closeMenu();
    },
    onMarkAsHidden() {
      this.emails && this.$emit('markAsHidden', this.emails.filter(el => !el.is_hide).map(m => m.id));
      this.closeMenu();
    },
    onMarkAsSpam() {
      this.emails && this.$emit('markAsSpam', this.emails.map(m => m.id))
      this.closeMenu();
    },
    onUnspam() {
      this.emails && this.$emit('unSpam', this.emails.map(m => m.id))
      this.closeMenu();
    },
    onArchiveEmail() {
      this.emails && this.$emit('archive', this.emails.map(m => m.id))
      this.closeMenu();
    },
    onMarkAsRead() {
      this.emails && this.$emit('markAsRead', this.emails.map(m => m.id))
      this.closeMenu();
    },
    onMarkAsUnread() {
      this.emails && this.$emit('markAsUnread', this.emails.map(m => m.id))
      this.closeMenu();
    },
    clickOutside(e) {
      if(e.target && e.target.closest('.mail-item-contextmenu'))
        return;
      for(let i = 0; i < e.target.classList.length; i++) {
        if(e.target.classList[i] && e.target.classList[i].includes('ivu-modal'))
          return;
      }
      this.closeMenu();
    },
    _showSubmenu(event) {
      this.$nextTick(_ => {
        if(!this.$refs.subMenu || !this.$refs.emailFiledMenuItem)
          return;
        this.submenuPosition = this.computePosition(this.$refs.emailFiledMenuItem, this.$refs.subMenu.$el, 'right');
      })
    },
    _closeAssignmentMenu() {
      this.$refs.assignment && this.$refs.assignment.closePopup();
    }
  },
  components: {
    assignment,
    EmailFiling
  }
}
</script>

<style lang="scss" scoped>
  .mail-item-contextmenu {
    &.hidden {
      visibility: hidden;
    }
    .backdrop {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
    }
    .content {
      position: fixed;
      z-index: 1000;
      width: 180px;
      left: -180px;
      font-size: 14px;
      border-radius: 3px;
      background-color: var(--component-color);
      color: var(--on-component-color);
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid var(--border-color);

    ul {
      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        list-style-type: none;
        height: 35px;
        padding: 0 16px;

        &.assignment-menu-item {
          padding: 0;
          .item {
            width: 180px;
            display: block;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: var(--hover-color);
        }

        .assignment span {
          padding: 8px 16px;
          display: block;
          font-size: 14px;
        }
        .file-to-folder {
          position: fixed;
        }
      }
    }

    .context-menu-item {
      display: block;
      width: 100%;
    }
  }
}
</style>
