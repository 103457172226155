<template>
  <Tooltip :content="statusClass.tooltip" placement="top" transfer>
    <i class="iconfont status" :class="statusClass.name"></i>
  </Tooltip>
</template>

<script>
  module.exports = {
    props: {
      status: {
        default: 'failed',
        required: true
      }
    },
    computed: {
      statusClass(){
        switch (this.status) {
          case 'received':
            return {
              name: 'iconfont-mail-in',
              tooltip: 'Received',
            }
          case 'sent':
            return {
              name: 'iconfont-mail-sent',
              tooltip: 'Sent',
            }
          case 'failed':
            return {
              name: 'ch-icon-cross_1',
              tooltip: 'Failed',
            }
          case 'pending':
            return {
              name: 'ch-icon-icon-loading',
              tooltip: 'Processing',
            }
          case 'progress':
            return {
              name: 'ch-icon-icon-loading',
              tooltip: 'Processing',
            }
          case 'draft':
            return {
              name: 'iconfont-draft',
              tooltip: 'Draft',
            }
          case 'delay':
            return {
              name: 'ch-icon-icon-hourglass1',
              tooltip: 'Delay',
              width: 14,
              height: 14,
            }
          case 'schedule':
            return {
              name: 'ch-icon-calendar',
              tooltip: 'Schedule',
            }
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .status {
    font-size: 18px;
    &[class^="iconfont-"],
    &.ch-icon-icon-hourglass1,
    &.ch-icon-cross_1,
    &.ch-icon-calendar {
      margin-left: 5px;
    }
    &.iconfont-mail-in {
      color: #E46317 !important;
    }
    &.iconfont-mail-sent {
      color: limegreen !important;
    }
  }

</style>
