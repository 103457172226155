import { fromEvent } from "rxjs";
import { filter } from 'rxjs/operators';
import Mark from "mark.js";
import appConstant from "../common/constants/app.constant";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import utils from "@/utils";
import DOMPurify from 'dompurify'
import api from "@/fetch/api";
import util from "util";

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
    node.setAttribute('title', node.href || '');
  }
});

export const globalMixin = {
  methods: {
    checkPermission: function(name) {
      const userInfo = this.$store.getters.userInfo;
      const permissions = this.$store.getters.setting_permissions;
      if (userInfo.user && userInfo.user.user_type === "admin") {
        return true;
      } else if (
        permissions &&
        permissions[name] &&
        userInfo.user &&
        userInfo.user.user_type === "member"
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleLineColor(lineIds) {
      const defaultColor = "transparent";
      if (!lineIds || lineIds.length > 1)
        return defaultColor;

      let lines = this.lines_all.full_lines;
      (!lines || lines.length == 0) && (lines = this.lines_all.lines || []);
      const line = lines.find(line => line.id === lineIds[0]);
      if (!line) return defaultColor;
      return line.colour;
    },
    openExternalUrl(url) {
      let convertedURL = url;
      if (!/http/.test(url)) {
        convertedURL = `http://${url}`;
      }
      window.open(convertedURL, "_blank");
    },
    $route_folder_id(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.folder_id;
      if (tab && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab && tab.data && tab.data.id;
    },
    $route_folder_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.folder_name;
      if (tab && tab.data && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab.data.name || tab.label;
    },
    $route_folder_type(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.type;
      if (tab && tab.data && tab.name_type == appConstant.tabTypes.FOLDER)
        return tab.data.folder_type || tab.data.type;
    },
    $route_line_id(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.line_id;
      if (tab && tab.name_type == appConstant.tabTypes.LINE)
        return tab.data && tab.data.id;
    },
    $route_line_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab) {
        const line = this.lines && this.lines.find(l => l.id == this.$route_line_id());
        return line && `${this.$route_box_name()} (${line.username})`;
      }
      if (tab && tab.name_type == appConstant.tabTypes.LINE)
        return tab && tab.data && `${tab.data.parentName} (${tab.data.name})`;
    },
    $route_box_name(routeQuery) {
      const query = routeQuery || this.$route.query;
      if(query.me)
        return "@ Mentions"
      if(query.is_star == 'true')
        return "Starred Mails"
      if (query.archived)
        return "Archive";
      if (query.box === "Draft")
        return "Draft";
      if (query.box === "Sent" && query.status === 'pending')
        return "Outbox";
      if (query.status === "sent")
        return "Sent";
      if (query.box)
        return utils.capitalizeFirstLetter(query.box);
      return "Mail Activity";
    },
    $route_box(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.box ? query.box.toLowerCase() : undefined;
      if (tab && tab.name_type == appConstant.tabTypes.LINE && tab.data) {
        return tab.data.parentId == 'outbox' ? 'sent'
          : !['archived', 'sent', 'mails'].includes(tab.data.parentId) ? tab.data.parentId
            : undefined;
      }
    },
    $route_archived(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.archived;
      if (tab && tab.name_type == appConstant.tabTypes.LINE) {
        return tab && tab.data && tab.data.parentId && tab.data.parentId.toLowerCase() == 'archived' || undefined;
      }
    },
    $route_status(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab);
      if (!tab)
        return query.status;
      if (tab && tab.data && tab.data.parentId && tab.name_type == appConstant.tabTypes.LINE) {
        return tab.data.parentId.toLowerCase() == 'outbox' ? 'pending'
          : tab.data.parentId.toLowerCase() == 'sent' ? 'sent'
            : undefined;
      }
    },
    $route_livefeed_id(routeQuery) {
      const query = routeQuery || this.$route.query;
      if (!query)
        return;
      const tab = this.openingTabs.find(t => t.name == query.tab && t.name_type == appConstant.tabTypes.LIVE_FEED);
      if (!tab)
        return query.live_feed_id;
      return tab.data && tab.data.id
    },
    $route_livefeed_name() {
      if(!this.liveFeeds) return ''
      const livefeed = this.liveFeeds.find(l => l.id == this.$route_livefeed_id())
      return livefeed ? livefeed.name : ''
    },
    sanitizeHTML(html) {
      const config = {
        WHOLE_DOCUMENT: true,
        FORBID_TAGS: ['text'],
        ADD_TAGS: ["mce-cur", "mce-mm"]
      };
      return DOMPurify.sanitize(html, config);
    },
    sanitizeTextHTML(html) {
      const config = {
        FORBID_TAGS: ['text'],
        ADD_TAGS: ["mce-cur", "mce-mm"]
      };
      return DOMPurify.sanitize(html, config);
    },
    convertedClassToInlineStyles(htmlString) {
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");

        const cssRules = [];

        // Extract <style> tags
        const styleElements = Array.from(doc.querySelectorAll("style"));

        styleElements.forEach((styleEl) => {
          try {
            const sheet = new CSSStyleSheet();
            sheet.replaceSync(styleEl.textContent); // Attempt to parse CSS rules
            cssRules.push(...sheet.cssRules); // Collect valid rules
          } catch (error) {
            console.warn("Skipped invalid or malformed CSS:", error);
          }
        });

        // Clone the document to avoid mutating the original input
        const clonedDoc = doc.cloneNode(true);
        // Apply collected CSS rules to matching elements as inline styles
        cssRules.forEach((rule) => {
          if (rule.selectorText) {
            const elements = clonedDoc.querySelectorAll(rule.selectorText);
            elements.forEach((el) => {
              const isRemovePosition = rule.style.getPropertyValue("position") === "fixed";
              if (isRemovePosition) {
                el.remove();
                return;
              }
              const existingStyles = el.getAttribute("style") || "";
              // Collect existing styles into an object for proper merging
              const existingStyleObj = existingStyles
                .split(";")
                .filter(Boolean)
                .reduce((acc, style) => {
                  const [key, value] = style.split(":").map((s) => s.trim());
                  if (key && value) acc[key] = value;
                  return acc;
                }, {});

              // Collect new styles from the CSS rule
              const newStyleObj = Array.from(rule.style).reduce((acc, prop) => {
                acc[prop] = rule.style.getPropertyValue(prop).trim();
                return acc;
              }, {});

              // Merge both existing and new styles
              const mergedStyles = { ...existingStyleObj, ...newStyleObj };

              // Convert merged styles back to a proper inline style string
              const styleString = Object.entries(mergedStyles)
                .map(([key, value]) => `${key}: ${value};`)
                .join(" ");

              el.setAttribute("style", styleString);
            });
          }
        });
          // Remove all id and class attributes in the body
        const bodyElements = clonedDoc.querySelectorAll("*");
        bodyElements.forEach((el) => {
          el.removeAttribute("id");
          el.removeAttribute("class");
        });


        // Serialize the modified document back to an HTML string
        const serializer = new XMLSerializer();
        return serializer.serializeToString(clonedDoc);
      } catch (e) {
        console.error("Error processing HTML:", e);
        // Return the original HTML string if any error occurs
        return htmlString;
      }
    },
  },
  computed: {
    ...mapGetters(['openingTabs', 'lines_all', 'setting_mails', 'liveFeeds']),
    composeInNewWindow() {
      return this.setting_mails.compose_message_in_new_window === "true";
    },
    inSeparateWindow() {
      return ['previewEmlWindow', 'mailDetailWindow', 'sendMailWindow'].includes(this.$route.name)
    }
  }
};

export const zohoRegister = {
  mounted() {
    if (window.ZohoHCAsap) return;
    const devMode = /staging|localhost/.test(location.href);

    const d = document;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = devMode
      ? "https://desk.zoho.com/portal/api/web/inapp/381504000000551073?orgId=686867340"
      : "https://desk.zoho.com/portal/api/web/inapp/381504000000551153?orgId=686867340";
    d.getElementsByTagName("head")[0].appendChild(s);

    window.ZohoHCAsap = function (a, b) {
      ZohoHCAsap[a] = b;
    };
    window.ZohoHCAsapReady = function (o) {
      if (
        ((window.ZohoHCAsap__asyncalls = window.ZohoHCAsap__asyncalls || []),
        window.ZohoHCAsapReadyStatus)
      ) {
        o && window.ZohoHCAsap__asyncalls.push(o);
        for (var a = window.ZohoHCAsap__asyncalls, s = 0; s < a.length; s++) {
          var n = a[s];
          n && n();
        }
        window.ZohoHCAsap__asyncalls = null;
      } else o && window.ZohoHCAsap__asyncalls.push(o);
    };
    ZohoHCAsapReady(function () {
      ZohoHCAsap.Action("hideLauncher");
    });
  }
}

const convertHtmlToPlainText = html => {
  return html.replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\\&/g, "&amp;")
    .replace(/\\"/g, "&quot;")
    .replace(/\\'/g, "&apos;")
}

export const convertBody = {
  methods: {
    convertBody(email) {
      if (!email.body) return "";
      if (email.body_type === "plain_text") {
        email.body = convertHtmlToPlainText(email.body);
      }
      email.body = email.body.replace(/<a/g, "<a target='_blank'")
      return email.body;
    }
  }
};

export const hotkeyHandler = {
  data() {
    return {
      hotkeySubcription: undefined
    }
  },
  mounted() {
    this.registerHotkeyHandler();
  },
  destroyed() {
    this.removeHotkeyHandler();
  },
  methods: {
    registerHotkeyHandler() {
      this.hotkeySubcription = fromEvent(document, 'keydown')
      .pipe(
        filter(event => !event.repeat) // Using filter to remove repeat event when long press.
      )
      .subscribe(e => {
        const isActionKey = e.key == 'Escape' || e.ctrlKey || e.metaKey || e.shiftKey || e.altKey;
        const { nodeName, isContentEditable } = document.activeElement;
        const type = document.activeElement.getAttributeNode("type");

        if (isContentEditable)
          return

        switch (nodeName) {
          case 'INPUT':
            if(type && type.value != 'checkbox')
              return
            break;
          case 'TEXTAREA':
          case 'SELECT':
            if(!isActionKey)
              return;
        }
        this.handleHotkey(e)
      })
    },
    removeHotkeyHandler() {
      this.hotkeySubcription.unsubscribe(); // Unsubscribe to remove all event listeners.
    },
    handleHotkey(_e) {  } // abstract method, will be overrided by component.
  }
}

export const assignmentSocket = {
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    ...mapMutations(["UPDATE_MAIL_LIST_ITEMS"]),
    handleAssignmentSocket(data, emailDetail) {
      switch(data.action) {
        case 'create':
          const assignmentItem = {
            id: data.id,
            assignable_id: data.assignable_id,
            email_id: data.email_id,
            assignable_type: data.assignable_type
          }

          emailDetail
            && !emailDetail.assignment_ids.some(a => a.assignable_id == data.assignable_id)
            && emailDetail.assignment_ids.push(assignmentItem);

          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (email) => {
              if (!email.assignment_ids) return {};

              const existing = email.assignment_ids.some(a => a.assignable_id === data.assignable_id);
              if (existing) return {};

              return { assignment_ids: [...email.assignment_ids, assignmentItem] || [] }
            }
          })
          break;
        case 'delete':
          if(emailDetail) {
            const index = emailDetail.assignment_ids.findIndex(a => a.assignable_id == data.assignable_id)
            index > -1 && emailDetail.assignment_ids.splice(index, 1);
          }

          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (email) => ({ assignment_ids: email.assignment_ids.filter(a => a.assignable_id !== data.assignable_id) || [] })
          })
          break;
        case 'closed':
          emailDetail && (emailDetail.closed = data.closed)
          this.UPDATE_MAIL_LIST_ITEMS({
            ids: [data.email_id],
            changedProps: (_) => ({ closed: data.closed })
          })
          break;
      }
    }
  }
}

export const mailDetailMixin = {
  data() {
    return {
      subscription: undefined,

      contactTypes: {
        PERSON: 'person',
        DEPARTMENT: 'department',
        COMPANY: 'company'
      },
      addContactType: undefined,
      addContactShow: false,
      contactData: undefined,
      addContactTimeout: undefined,

      contactDetailShow: false,
      contactDetailId: undefined
    }
  },
  computed: {
    openAIEnabled() {
      return this.setting_company.openai_integration === '1' && (!!this.setting_company.openai_api_key || !!this.setting_mails.openai_api_key)
    }
  },
  mounted() {
    this.subscribeWSChannel();
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
  methods: {
    subscribeWSChannel() {
      this.subscription = this.$ActionCable.subscriptions.create({channel: 'NotificationsChannel'}, {
        connected: () => {
          console.log('Mail detail WS connected');
        },
        received: (data) => {
          switch (data.type) {
            case "OpenaiResponse":
              this.addContactTimeout && clearTimeout(this.addContactTimeout)
              if(!data.message || data.message.length == 0) {
                this.addContactFail()
                return;
              }
              try {
                let body = utils.validateJSON(data.message.body)
                const _contactData = JSON.parse(body)
                if(_contactData && _contactData.level && _contactData.level.toLowerCase()  === 'contact' && _contactData.name) {
                  this.contactData = _contactData
                  return
                }
                this.addContactFail()
              } catch(err) {
                this.addContactFail()
              }
              break;
          }
        },
        disconnected: () => {
          console.log('Mail detail WS disconnected');
        }
      });
    },
    async smartAddContact(type) {
      const text = utils.getSelectionText()
      if(!text)
        return this.$Message.info('Please select text')

      this.addContactShow = true
      this.addContactType = type
      this.contactData = undefined
      await api.add_contact_openai(text).catch(_ => {
        this.addContactShow = false
      })
      this.addContactTimeout && clearTimeout(this.addContactTimeout)
      this.addContactTimeout = setTimeout(() => {
        if(this.addContactShow && !this.contactData) {
          this.$Message.error('Request timed out. Please check your connection or try again later')
          this.addContactShow = false
        }
      }, 120000)
    },
    addContactDone(addressBookId) {
      this.addContactShow = false
      this.contactDetailId = addressBookId
      this.$Message.success({
        render: h => {
          return h(
            'span', { style: {'margin-left': '-32px'} },
            [
              'Add contact successfully.',
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.$Message.destroy()
                      this.contactDetailShow = true
                    }
                  },
                  style: {
                    'cursor': 'pointer',
                    'margin-left': '16px',
                    'text-decoration': 'underline'
                  }
                },
                'View'
              ),
            ]
          )
        },
        duration: 10000,
        closable: true
      },)
    },
    addContactFail() {
      this.addContactShow = false
      this.$Message.error('Sorry, we could not convert your text. Please try another')
    }
  }
}
let markInstance;
export const highlightSearch = {
  methods: {
    highlightSearch(context, searchKeys) {
      markInstance = new Mark(context);
      searchKeys.forEach((keyword) => {
        keyword = utils.escapeSpecialCharacters(keyword);
        const regex = new RegExp(`(?<!\\w)${keyword}`, "gim");
        markInstance.markRegExp(regex, {
          separateWordSearch: false,
          element: 'span',
          className: 'text-highlight'
        });
      });
    },
  },
};

export const searchActions = {
  methods: {
    handleSearchAgain() {
      this.reloadRouter();
    },
    handleExitSearch() {
      this.reloadRouter("exit-search");
    },
    reloadRouter(action) {
      const { query, path } = this.$route;
      if (action === "exit-search") {
        delete query.search_key;
        delete query.search_id;
      }
      delete query.rand;
      let rebuiltQuery = `${path}?`;
      const queryArr = [];
      for (const key in query) {
        queryArr.push(`${key}=${encodeURIComponent(query[key])}`);
      }
      rebuiltQuery += queryArr.join("&");
      const rand = +new Date();
      this.$router.replace(rebuiltQuery + `&rand=${rand}`);
    },
  },
};

export const popupPosition = {
  methods: {
    computePosition(rel, popupEl, position, padding={top: 8, right: 4, bottom: 8, left: 4}) {
      if(!rel || !popupEl)
        return;
      let top, left, right;
      const relElRect = rel.getBoundingClientRect ? rel.getBoundingClientRect() : rel;
      const popupElRect = popupEl.getBoundingClientRect();

      const exceedTop = relElRect.top < popupElRect.height + padding.top;
      const exceedRight = relElRect.right + popupElRect.width + padding.right > innerWidth;
      const exceedBottom = relElRect.top + popupElRect.height > innerHeight;
      const exceedLeft = relElRect.left < popupElRect.width + padding.left;

      switch(position) {
        case('top'):
          top = exceedTop && !exceedBottom ? relElRect.top + relElRect.height + padding.top : relElRect.top - popupElRect.height - padding.top;
          left = relElRect.left;
          right = window.innerWidth - relElRect.right;
          break
        case('right'):
          top = exceedBottom && !exceedTop ? relElRect.bottom - popupElRect.height : relElRect.top;
          left = exceedRight && !exceedLeft ? relElRect.left - popupElRect.width - padding.right : relElRect.left + relElRect.width + padding.right;
          break
        case('bottom'):
          top = exceedBottom && !exceedTop ? relElRect.top - popupElRect.height - padding.bottom : relElRect.top + relElRect.height + padding.bottom;
          left = exceedRight && !exceedLeft ? relElRect.right - popupElRect.width : relElRect.left;
          right = window.innerWidth - relElRect.right;
          break
        case('left'):
          top = exceedBottom && !exceedTop ? relElRect.bottom - popupElRect.height : relElRect.top;
          left = exceedLeft && !exceedRight ? relElRect.left + relElRect.width + padding.left : relElRect.left - popupElRect.width - padding.left;
          break
      }
      return { top, left, right };
    }
  }
}

export const tabUtil = {
  methods: {
    ...mapMutations(['SET_ACTIVE_TAB']),
    setActiveAndGotoTab(tab) {
      this.SET_ACTIVE_TAB(tab);
      const routeData = {
        path: this.$route.path,
        query: {
          tab: tab.name !== 'mail-activity' ? tab.name : undefined,
          subtab: undefined
        }
      }
      this.$router.replace(routeData).catch(() => {});
    },
  }
}

export const addUserMixin = {
  methods: {
    validateUsername(value) {
      if(value == '') {
        return 'Please enter username'
      } else if (value.length < 3 || value.length > 20) {
        return 'Must be at least 3 characters but not more than 20 characters'
      } else if(!/^[a-zA-Z0-9.-]+$/.test(value)) {
        return 'Only English alphabetic characters, numbers, hyphens (-), and dots (.) allowed'
      }
    },
    validatePassword(value) {
      if (value === '')
        return 'Please enter password';

      let num = this.checkStrong(value);
      if (num === 0)
        return 'Please enter at least 8 characters';
      if (num > 0 && num < 3)
        return 'Must contain at least 1 letter, 1 number and 1 special character';
    },
    validateConfirmPassword(value, pass) {
      if (value === '')
        return 'Please enter password confirmation';
      if (value !== pass)
        return 'The passwords do not match';
    },
    checkStrong(val){
      var modes = 0;
      if (val.length < 8) return 0;
      if (/\d/.test(val)) modes++;
      if (/[a-z]/.test(val)) modes++;
      if (/\W/.test(val)) modes++;
      return modes;
    },
  }
}

export const openNewTabMixin =  {
  mixins: [tabUtil],
  computed: {
    ...mapGetters(['openingTabs']),
  },
  methods: {
    ...mapMutations([
      'ADD_OPEN_TAB',
    ]),
    onOpenInTab(tabData) {
      const existingTab = this.openingTabs.find(t => t.name === this.tabName && t.name_type === tabData.name_type);
      if (existingTab) {
        this.setActiveAndGotoTab(existingTab);
        return;
      }

      const openedTab = this.openingTabs.find(t => t.name === tabData.name);
      if (!!openedTab) {
        this.setActiveAndGotoTab(openedTab);
        return;
      }

      if (this.openingTabs.length >= appConstant.maximumOpenTabs) {
        this.$Message.error("You have reached the maximum number of tabs.");
        return;
      }

      api.add_tab(tabData).then(res => {
        this.ADD_OPEN_TAB({ tab: res });
        this.setActiveAndGotoTab(res);
      })
    },
  }
}

export const beforeLeaveConfirmation = {
  data() {
    return {
      nextRoutePath: ''
    }
  },
  created() {
    window.addEventListener("beforeunload", this.onWindowClose);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.onWindowClose);
  },
  beforeRouteLeave (to, from , next) {
    if(this.user.loggingOut)
      next();

    this.askBeforeLeave(
      () => {
        this.nextRoutePath = to.fullPath;
        next(false);
      },
      () => {
        next();
      }
    )
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations(['SET_LOGGING_OUT']),
    ...mapActions(['Logout']),
    askBeforeLeave(hasChangedCallback, noChangedCallback) {
      if(this.hasChanged()) {
        this.confirmBeforeLeave = true;
        hasChangedCallback && hasChangedCallback();
      } else {
        noChangedCallback && noChangedCallback();
      }
    },
    onWindowClose(e) {
      if(this.hasChanged()) {
        e.preventDefault();
        e.returnValue = false;
      }
      return undefined;
    },
    logout() {
      this.Logout().then(() => {
        this.$router.push('/login');
        this.SET_LOGGING_OUT(false);
      });
    },
  },
  watch: {
    "user.loggingOut"(val) {
      val && this.askBeforeLeave(
        undefined,
        () => this.logout()
      );
    }
  }
}

export const searchFolderMixin = {
  data() {
    return {
      folderToSearch: undefined,
      searchFolderActions: {
        HIGHLIGHT: 0,
        OPEN_FOLDER: 1
      }
    }
  },
  methods: {
    searchDone() {
      let folderEl = this.$refs[`folderItem${this.folderToSearch.id}`];
      if(!folderEl || !folderEl[0])
        return this.folderToSearch = null;

      folderEl = folderEl[0]
      switch(this.folderToSearch.action) {
        case this.searchFolderActions.HIGHLIGHT:
          folderEl.highlightFolder();
          folderEl.$el && folderEl.$el.scrollIntoView && folderEl.$el.scrollIntoView({ behavior: 'smooth', block:'center' });
          break;
        case this.searchFolderActions.OPEN_FOLDER:
          folderEl.selectFolder();
          break;
      }
      this.folderToSearch = null;
    },
  }
}

export const draggableModalMixin = {
  mounted() {
    this.$nextTick(_ => {
      this.handleDragging()
    })
  },
  methods: {
    handleDragging() {
      const originalHandleMoveEnd = this.$refs.draggableModalEl.handleMoveEnd
      this.$refs.draggableModalEl.handleMoveEnd = () => {
        originalHandleMoveEnd && originalHandleMoveEnd()
        const top = this.$refs.draggableModalEl.$el.querySelector('.ivu-modal-content-drag').style.top
        if(top && parseInt(top) < 0) {
          this.$refs.draggableModalEl.$el.querySelector('.ivu-modal-content-drag').style.top = 0
        }
      }
    }
  }
}

export const undoActionMixin = {
  methods: {
    showUndoMessage(messageRender, onUndoClick) {
      this.$Message.destroy()
      return this.$Message.info({
        render: h => {
          return h('div', {class: ['undo-message']}, [
            h(
              'span',
              {class: ['undo-content', 'text-ellipsis']},
              [...messageRender(h)]
            ),
            h(
              'span',
              {
                class: ['undo-button'],
                on: { click: onUndoClick }
              },
              'Undo'
            )
          ])
        },
        closable: true,
        duration: 10
      });
    }
  }
}

export const addContactMixin = {
  props: {
    initialData: Object,
    loading: Boolean
  },
  methods: {
    populateInitialData() {
      if(!this.initialData) {
        this.resetFormData()
        return
      }

      this.addLoading = false
      let { contact_items, ...data } = this.initialData

      Object.keys(this.formValidate).forEach(k => {
        k in data && (this.formValidate[k] = data[k])
      })

      if(!this.initialData.contact_items)
        return

      const emails = this.initialData.contact_items.filter(i => i.item_type === 'email' && !!i.value)
      emails && emails.length > 0 && (this.formValidate.emails = emails)

      const phones = this.initialData.contact_items.filter(i => i.item_type === 'phone' && !!i.value)
      phones && phones.length > 0 && this.phoneDynamic && (this.phoneDynamic.items = phones)

      const ims = this.initialData.contact_items.filter(i => i.item_type === 'im' && !!i.value)
      ims && ims.length > 0 && this.imDynamic && (this.imDynamic.items = ims)
    },
    resetFormData() {
      this.formValidate = {
        name: '',
        level: 'contact',
        avatar: {},
        company_name: '',
        department_name: '',
        job_title: '',
        postal_address: '',
        city: '',
        state: '',
        postal_code: '',
        country_id: 0,
        website: '',
        note: '',
        first_name: '',
        last_name: '',
        emails: [{'item_type': 'email', 'value': '', 'value_type': 'work', 'default': true}],
        salutation: [{'item_type': 'salutation', 'value': '', 'value_type': '', 'default': true}],
      }
      this.phoneDynamic = {
        items: [
          {'item_type': 'phone', 'value': '', 'value_type': 'mobile', 'default': true},
        ]
      }
      this.imDynamic = {
        items: [
          {'item_type': 'im', 'value': '', 'value_type': 'skype', 'default': true},
        ]
      }
    }
  },
  watch: {
    initialData() {
      this.populateInitialData()
    },
    loading: {
      immediate: true,
      handler(val) {
        this.$nextTick(_ => {
          this.addLoading = val
        })
      }
    }
  }
}

export const mailDetailActionMixin = {
  data() {
    return {
      downloadEmlBtn: "Download"
    }
  },
  methods: {
    ...mapMutations(['DELETE_MAIL_LIST_ITEMS']),
    showHeader(emailId) {
      utils.functionBus.readMail && utils.functionBus.readMail();
      let url = this.$route.query.via ? `#/originalMail/${emailId}?via=notification` : `#/originalMail/${emailId}`;
      window.open(url, "_blank");
    },
    async downloadEml(emailId, emailSubject="") {
      try {
        this.downloadEmlBtn = ".....";
        const fileName = emailSubject.replace(/\W/g, "_").concat(".eml");

        const res = await api.get_email_details({
          email_id: emailId,
          only_eml: true
        })

        if (!res) return;

        await utils.downloadFile(res.email.eml, fileName)
      } catch (error) {
        this.$Message.error("Failed to download this file");
      } finally {
        this.downloadEmlBtn = "Download";
      }
    },
    async requestAbortSend(ids) {
      await api.abort_emails({email_ids: ids});
      if (!utils.functionBus.getCacheKey) return;

      this.DELETE_MAIL_LIST_ITEMS({
        ids: [...ids],
        currentCacheKey: utils.functionBus.getCacheKey()
      })
      utils.resetCachedEmails("box=draft");
    }
  }
}

export const mailSearchMixin = {
  computed: {
    ...mapGetters(['setting_mails'])
  },
  methods: {
    applySearchMethods(text) {
      const searchMethod = this.setting_mails.default_mail_search_method
      if(!searchMethod) 
        return text

      switch(searchMethod) {
        case appConstant.defaultSearchMethods.EXACT_MATCH:
          return `"${text}"`
        case appConstant.defaultSearchMethods.CONTAIN_OR:
          return text
        case appConstant.defaultSearchMethods.CONTAIN_AND:
          const keywords = text.split(' ')
          if(!keywords || keywords.length === 0) 
            return text
          return keywords.map(k => `+${k}`).join(' ')
        default:
          return text
      }
    }
  }
}

export const mailAddressMixin = {
  computed: {
    ...mapGetters(['lines_all'])
  },
  methods: {
    handleSyncList(value = { address: [''], username: [''] })  {
      const res = [];
      value.address.forEach((address, index) => {
        // Create a new object with the current address and corresponding username
        const lineItem = this.lines_all.full_lines?.find(item => item?.email === address);
        res.push({
          address: address,
          username: lineItem ? lineItem?.display_name : value.username[index]
        });
      });
      return res;
    },
    handleSyncItem(value = { address: '', username: '' })  {
      let res = value;
      const lineItem = this.lines_all.full_lines?.find(item => item?.email === res?.address);
      res.username = lineItem && lineItem?.display_name ? lineItem?.display_name : res.username; 
      return res;
    }
  }
}

export const mailColumnMixin = {
  computed: {
    mailFromDisplayType() {
      if (!this.$store.state.user.mailsSettings?.email_display_from_type)
        return "";
      return this.$store.state.user.mailsSettings?.email_display_from_type;
    },
    emailFrom() {
      const displayHtml = this.item.htmlFrom || "";
      let displayText = "";
      if (this.item?.box === "SENT") displayText = this.item.username;
      else
        displayText =
          this.mailFromDisplayType === "email"
            ? this.item.from
            : this.item.username;
      return this.sanitizeTextHTML(this.searching ? displayHtml : displayText);
    },
  },
  methods: {
    displayToType(to) {
      const type = this.$store.state.user.mailsSettings?.email_display_to_type || "username";
      return this.sanitizeTextHTML(to[type]) || "";
    }
  }
};

export const roosterButtonMixins = {
  computed: {
    selectedOption() {
      return {
        key:
          this.button.getSelectedValue(this.toolbarButtonStatus) ||
          this.button.options[0].key,
        value:
          this.button.getSelectedValue(this.toolbarButtonStatus) ||
          this.button.options[0].value,
      };
    },
    buttonWidth() {
      return this.button.width || "50px";
    },
  },
  methods: {
    handleClickToolbarButton() {
      this.button.selected && this.button.action(this.button.selected);
      this.button.defaultAction && this.button.defaultAction();
    },
    hideMenu() {
      this.menuVisible = false;
    },
  },
  mounted() {
    window.addEventListener("close-toolbar-modal-menu", this.hideMenu);
  },
  beforeDestroy() {
    window.removeEventListener("close-toolbar-modal-menu", this.hideMenu);
  },
}

export const preferencesMixins = {
  data() {
    return {
      originalData: () => {},
    }
  },
  methods: {
    hasChanged() {
      return !utils.compare2Object(
        this.formData, 
        this.originalData, 
        [], 
        { excludeEmptyProperties: true }
      )
    },
    discardChange() {
      Object.assign(this.formData, this.originalData);
    },
    updateOriginalData() {
      Object.assign(this.originalData, this.formData);
    }
  },
}

export const domSanitizeMixins = {
  data() {
    return {
      innerStyles: []
    }
  },
  methods: {
    clearInnerStyles() {
      this.innerStyles.forEach(style => {
        document.head.removeChild(style);
      });
      this.innerStyles = [];
    },
    sanitizeCssStyles(el) {
      this.clearInnerStyles();
      const clonedEl = el.cloneNode(true);
      clonedEl.querySelectorAll('link').forEach((linkEl) => linkEl.remove());

      let styles = clonedEl.querySelectorAll('style');
      if (!styles || styles.length === 0) return clonedEl;

      let style, mediaRules, styleRules = [];
      for(let i = 0; i < styles.length; i++) {
        style = styles[i];
        document.head.appendChild(style) && this.innerStyles.push(style);
        if(!style || !style.sheet || !style.sheet.cssRules || style.sheet.cssRules.length == 0)
          continue;
        styleRules = [...styleRules, ...findRulesByType(style.sheet.cssRules, CSSRule.STYLE_RULE)];
        mediaRules = findRulesByType(style.sheet.cssRules, CSSRule.MEDIA_RULE) || [];
        for(let j=0; j < mediaRules.length; j++ ) {
          mediaRules[j] && (styleRules = [...styleRules, ...findRulesByType(mediaRules[j].cssRules, CSSRule.STYLE_RULE)]);
        }
      }
      if(!styleRules || styleRules.length == 0) return clonedEl;

      let styleRule;
      for(let i = 0; i < styleRules.length; i++) {
        styleRule = styleRules[i];
        let selectors = styleRule.selectorText.split(',')
        styleRule.selectorText = selectors.map(s => `.cdk-2020 ${s}`).join(' ');
      }
      return clonedEl; // Return the sanitized element

      function findRulesByType(rules, type) {
        if(!rules || rules.length == 0)
          return [];
        let result = [];
        for(let i = 0; i < rules.length; i++) {
          rules[i] && rules[i].type == type && result.push(rules[i])
        }
        return result;
      }
    },
    overrideImageURL(el, attachments) {
      const clonedEl = el.cloneNode(true); // Clone the original element
      let images = clonedEl.querySelectorAll("img");
      if (!images || images.length === 0) return clonedEl;

      const inlines = attachments
        ? attachments.filter((e) => e.disposition === "inline")
        : [];

      for (const image of images) {
        if (image) {
          // Image with insecure domain
          if (image.src && /^http:\/\//.test(image.src)) {
            image.src = `https://images.weserv.nl/?url=${encodeURIComponent(
              image.src
            )}`;
            image.crossOrigin = "";
          }

          // External images not uploaded by the system
          const isExternalImage =
            !image.src.startsWith("data") &&
            inlines.findIndex(
              (i) =>
                util.getBaseImageURL(image.src) ===
                  util.getBaseImageURL(i.original_file_url) ||
                image.src === `cid:${i.attachment_cid}`
            ) === -1;

          // Skip loading inline images with excessively long URLs
          if (isExternalImage) {
            image.loading = "lazy";
            if (image.src.length >= 10000) {
              image.src = ""; // Clear the source for long URLs
            }
          }
        }
      }

      return clonedEl;
    },
  },
}
