<template>
  <Modal v-model="showModal"
         :title="`Fleet Details - ${group.name}`"
         :styles="{top: modalTop}"
         :scrollable="false"
         :mask-closable="false"
         width="770"
         class="dialog dialog-no-cancel">
    <!--loading-->
    <div class="loading" v-show="loading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="folder">
      <div class="ch-flex folder-detail">
        <div class="folder-info">
          <div class="title">
            <i class="iconfont-ship"></i>
            <span>Fleet Type</span>
          </div>
          <div class="info">{{ group.group_type === 'normal' ? 'Normal' : 'Defined Search' }} Fleet</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont-user"></i>
            <span>Created By</span>
          </div>
          <div class="info">{{ group.creater }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont ch-icon-calendar"></i>
            <span>Created On</span>
          </div>
          <div class="info">{{ group.created_at | timeFormat }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont-user"></i>
            <span>Last Edited By</span>
          </div>
          <div class="info">{{ group.updater }}</div>
        </div>
        <div class="folder-info">
          <div class="title">
            <i class="iconfont ch-icon-calendar"></i>
            <span>Last Edited On</span>
          </div>
          <div class="info">{{ group.updated_at | timeFormat }}</div>
        </div>
      </div>
      <div class="rules" v-if="rules.length>0">
        <div class="rules-title">
          File in fleet if an <span class="highlight">{{database}}</span>
          meets <span class="highlight">all</span> filter(s):
        </div>
        <div class="rules-container">
          <div class="rule-item" v-for="rule in rules" :key="rule.id">
            <div class="item-field highlight">{{ rule.field | category }}</div>
            <div class="item-info">{{rule.strategy}}</div>
            <div class="item-contain highlight">{{ rule.content }} </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script type="text/ecmascript-6">
  import api from 'api'
  import dayjs from "dayjs";
  import {mapState, mapActions, mapGetters} from 'vuex'

  export default {
    data () {
      return {
//        显示/loading
        showModal: false,
        loading: false,
//        data
        group: {},
        rules: [],
      }
    },

    methods: {
      show (id) {
        this.showModal = true;
        this.getDetail(id)
      },
      getDetail(id){
        this.loading = true;
        api.get_fleet_detail(id).then(res => {
          if (res.ship_group) this.group = res.ship_group;
          if (res.rules) this.rules = res.rules;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
        })
      }

    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      modalTop(){
        if (this.rules.length > 3) {
          return 'calc(50vh - 290px)'
        } else {
          return 'calc(50vh - 180px)'
        }
      },
//      判断是搜索的哪个database
      database(){
        if (this.group.public) {
          return 'MarineTraffic'
        } else {
          return (this.userInfo.company && this.userInfo.company.name) || '';
        }
      }
    },
    filters: {
      timeFormat (val) {
        if (!val) return '-'
        return dayjs(val).format('DD MMM YYYY, h:mm a')
      },
      category (val) {
        switch (val) {
          case 'name':
            return 'Name';
            break;
          case 'lrimo':
            return 'IMO';
            break;
          case 'ex_name':
            return 'Ex Name';
            break;
          case 'ship_status':
            return 'Status';
            break;
          case 'year_of_build':
            return 'Year of Build'
            break;
          case 'type_level2':
            return 'Type';
            break;
          case 'type_level5':
            return 'Category'
            break;
          case 'deadweight':
            return 'DWT'
            break;
          case 'group_beneficial_owner':
            return 'Owner';
            break;
          case 'grain_capacity':
            return 'Grain';
            break;
          case 'bale_capacity':
            return 'Bale';
            break;

          case 'call_sign':
            return 'Call Sign';
            break;
          case 'flag_name':
            return 'Flag';
            break;
          case 'classification_society':
            return 'Class';
            break;
          case 'ice_capability_descriptive_narrative':
            return 'ICE';
            break;
          case 'gross_tonnage':
            return 'GT';
            break;
          case 'net_tonnage':
            return 'NT';
            break;
          case 'panama_cana_net_tonnage':
            return 'PCNT';
            break;
          case 'suez_canal_net_tonnage':
            return 'SCNT';
            break;
          case 'depth':
            return 'Depth';
            break;
          case 'draught':
            return 'Draught';
            break;
          case 'length_overall_loa':
            return 'LOA';
            break;
          case 'length_between_perpendiculars_lbp':
            return 'LBP';
            break;
          case 'breadth':
            return 'BEAM';
            break;
          case 'speed':
            return 'Speed';
            break;
          case 'hull_type':
            return 'Hull Type';
            break;
          case 'tonnes_per_centimetre_immersion_tpci':
            return 'TPCI';
            break;
          case 'number_of_decks':
            return 'Decks';
            break;
          case 'cargo_capacities_narrative':
            return 'Cargo Desc';
            break;
          case 'gear_descriptive_narrative':
            return 'Gear Desc';
            break;
          case 'ship_manager':
            return 'Manager';
            break;
          case 'operator':
            return 'Operator';
            break;
          case 'last_update_date':
            return 'Last Updated';
            break;
          case 'number_of_holds':
            return 'Holds';
            break;
          case 'holds_descriptive_narrative':
            return 'Holds Desc';
            break;
          case 'number_of_hatches':
            return 'Hatches';
            break;
          case 'hatches_descriptive_narrative':
            return 'Hatches Desc';
            break;
          case 'gearless':
            return 'Gearless';
            break;
          case 'teu':
            return 'TEU';
            break;
          case 'number_of_tanks':
            return 'No. of Tanks';
            break;
          case 'tanks_descriptive_narrative':
            return 'Tanks Desc';
            break;
          case 'number_of_pumps':
            return 'No. of Pumps';
            break;
          case 'liquid_capacity':
            return 'Tank (cbm)';
            break;
          case 'cargo_tank_coatings':
            return 'Tank Coated';
            break;
          case 'tank_coatings_narrative':
            return 'Coat Desc';
            break;
          case 'slop_tank_capacity':
            return 'Slop (cbm)';
            break;
          case 'slop_tank_coatings':
            return 'Slop Coated';
            break;
          case 'special_tanks_descriptive_narrative':
            return 'Special Tanks Desc';
            break;
          case 'gas_capacity':
            return 'Gas (cbm)';
            break;
          case 'segregated_ballast_capacity':
            return 'SBT (cbm)';
            break;
          case 'clean_ballast_capacity':
            return 'CBT (cbm)';
            break;
          case 'crude_oil_washing_cow':
            return 'COW';
            break;
          case 'inert_gas_system_igs':
            return 'IGS';
            break;
          default:
            break
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .folder {
    background-color: var(--component-color);
    border: 1px solid var(--border-color);
    font-size: 14px;
    color: var(--on-component-color);
    .folder-detail {
      border-bottom: 1px solid var(--border-color);
      flex-wrap: wrap;
    }
    .folder-info {
      width: 33.33%;
      padding: 16px;
      .title {
        color: var(--label-color);
        i {
          margin-right: 8px;
        }
      }
      .info {
        color: var(--on-component-color);
        margin-top: 8px;
      }
    }
    .rules {
      .rules-title {
        padding: 16px;
      }
      .rules-container {
        max-height: 200px;
        overflow: auto;
        overflow: overlay;
        border-top: 1px solid var(--border-color);
        .rule-item {
          display: flex;
          padding: 8px 16px;
          border-top: 1px solid var(--border-color);
          &:first-child{
            border-top:none;
          }
          .item-field {
            width: 140px;
          }
          .item-info {
            margin: 0 40px;
          }
          .item-contain {
            max-width: 460px;
          }
        }
      }
      .highlight {
        color: var(--primary-color);
        font-weight: bold;
      }
    }

  }
</style>
