import Vue from "vue";
import dayjs from "dayjs";
import store from "../vuex";

Vue.filter("format_date_time", (data, format) => {
  if (!data) return "";
  const date_format = format || store.getters.setting_mails.date_format;
  const date = dayjs(data);
  switch (date_format) {
    case "12":
      return date.format("D MMM YYYY, hh:mm a");
    case "24":
      return date.format("D MMM YYYY, HH:mm");
    case "24_a":
      return date.format("DD/MM/YY HH:mm");
    case "24_b":
      return date.format("MM/DD/YY HH:mm");
    default:
      return date.format("D MMM YYYY, HH:mm");
  }
});

Vue.filter("capitalize", value => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("timeFormat", val => {
  if (!val) return '-'
  return dayjs(val).format('DD MMM YYYY, h:mm a')
})
