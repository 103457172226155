import utils from "@/utils";
import dayjs from "dayjs";
import googleCalendarTimezones from "../timezones/google_calendar_timezones.json";
import outlookCalendarTimezones from "../timezones/outlook_calendar_timezones.json";
import ewsCalendarTimezones from "../timezones/ews_calendar_timezones.json";

const mailboxStatuses = {
  LINE_STATUS_RUNNING: 0,
  LINE_STATUS_NOT_RUNNING: 1,
};

const folderSearchTypes = {
  ALL: "all",
  PERSONAL_SEARCH: "personal",
  SHARE_SEARCH: "share",
};

const directory = {
  ALL: "All",
  MY_WORKSPACE: "MY WORKSPACE",
  SHARED: "SHARED",
};

const twoFACodeLength = 6;

const folderCategory = {
  STAR: "star",
  PERSONAL: "personal",
  SHARE: "share",
};

const contactCategory = {
  PERSONAL: "personal",
  SHARE: "share",
};

const verificationStatus = {
  NOT_ENABLE: 1,
  ENABLED_NOT_VERIFIED: 2,
  ENABLED_VERIFIED: 3,
};

const themeNames = {
  ORIGINAL: "original",
  TWILIGHT: "twilight",
};

const assignedTo = {
  ALL: "all",
  OPEN: "open",
  UNASSIGNED: "unassigned",
  ASSIGNED: "assigned",
  ASSIGNED_TO_ME: "assigned_to_me",
  CLOSED: "closed",
  FOCUSED: "focused",
};

const mailTabs = {
  ALL: "all",
  OPEN: "open",
  ASSIGNED_TO_ME: "me",
  CLOSED: "closed",
};

const assignableType = {
  USER: "User",
  ROLE: "Role", // Not support at the moment
};

const noticeableType = {
  ASSIGNMENT: "Assignment",
  UN_ASSIGNMENT: "Unassignment",
  COMMENT: "Comment",
  TASK_ASSIGNMENT: "TaskAssignment",
  TASK: "Task",
};

const pageNames = {
  MAIL_ACTIVITY: "Mail Activity",
};

const folderType = {
  NORMAL: "normal",
  DYNAMIC: "dynamic",
  VIEW: "view",
};

const outlookCalendarColors = [
  {key: 0, value: "#0078D4"},
  {key: 1, value: "#038387"},
  {key: 2, value: "#498205"},
  {key: 3, value: "#003966"},
  {key: 4, value: "#744DA9"},
  {key: 5, value: "#E3008C"},
  {key: 6, value: "#DA3B01"},
  {key: 7, value: "#C50F1F"},
  {key: 8, value: "#69797E"},
];

const gmailCalendarColors = [
  {key: 1, value: "#ac725e"},
  {key: 2, value: "#d06b64"},
  {key: 3, value: "#f83a22"},
  {key: 4, value: "#fa573c"},
  {key: 5, value: "#ff7537"},
  {key: 6, value: "#ffad46"},
  {key: 7, value: "#42d692"},
  {key: 8, value: "#16a765"},
  {key: 9, value: "#7bd148"},
  {key: 10, value: "#b3dc6c"},
  {key: 11, value: "#fbe983"},
  {key: 12, value: "#fad165"},
  {key: 13, value: "#92e1c0"},
  {key: 14, value: "#9fe1e7"},
  {key: 15, value: "#9fc6e7"},
  {key: 16, value: "#4986e7"},
  {key: 17, value: "#9a9cff"},
  {key: 18, value: "#b99aff"},
  {key: 19, value: "#c2c2c2"},
  {key: 20, value: "#cabdbf"},
  {key: 21, value: "#cca6ac"},
  {key: 22, value: "#f691b2"},
  {key: 23, value: "#cd74e6"},
  {key: 24, value: "#a47ae2"},
]

const tabPrefixes = {
  FOLDER: "fid_",
  LINE: "line_",
  LIVE_FEED: "lf_",
};
const tabTypes = {
  PAGE: "page",
  ASSIGNMENT: "assignment",
  FOLDER: "folder",
  LINE: "line",
  LIVE_FEED: "liveFeed"
};

const mailMergeVariables = [
  "sal_or_name",
  "salutation",
  "title",
  "name",
  "first_name",
  "last_name",
  "company",
];

const mailVariables = [
  "date",
  "time",
  "ref",
];

const templateVariables = {
  ref: "ref",
  date: "date",
  time: "time",
  date_time: () => dayjs(new Date()).format("D MMM YYYY, h:mm:ss a"),
  ref_num: () => `${utils.getUserId()}${Math.floor(new Date().getTime() / 1000)}`
};

const taskStatus = { OPEN: 'open', ASSIGNED_TO_ME: 'assigned_to_me', CLOSED: 'closed' }
const calendarEventTypes = { EVENT: 'event', TASK: 'task' }
const calendarResources = {
  local: "MTInbox",
  outlook: "Outlook",
  gmail: "Gmail",
  ews: "EWS"
}

const eventDeleteTypes = { THIS: 'this', ALL: 'all'}

const helpCenter = { VERSION: "help_center_version" };

const timeZones = {
  outlook: outlookCalendarTimezones.sort((a, b) => a.offset - b.offset),
  gmail: googleCalendarTimezones.sort((a, b) => a.offset - b.offset),
  ews: ewsCalendarTimezones.sort((a, b) => a.offset - b.offset),
  others: [
    { name: "UTC-11", value: -11, offset: -11 },
    { name: "(UTC-10)Hawaiian Standard Time", value: -10, offset: -10 },
    { name: "(UTC-9)Alaskan Standard Time", value: -9, offset: -9 },
    { name: "(UTC-8)Pacific Standard Time", value: -8, offset: -8 },
    { name: "(UTC-7)Mountain Standard Time (Mexico)", value: -7, offset: -7 },
    { name: "(UTC-6)Central Standard Time", value: -6, offset: -6 },
    { name: "(UTC-5)US Eastern Standard Time", value: -5, offset: -5 },
    { name: "(UTC-4)Atlantic Standard Time", value: -4, offset: -4 },
    { name: "(UTC-3)SA Eastern Standard Time", value: -3, offset: -3 },
    { name: "(UTC-2)Mid-Atlantic Standard Time", value: -2, offset: -2 },
    { name: "(UTC-1)Azores Standard Time", value: -1, offset: -1 },
    { name: "(UTC)GMT Standard Time", value: 0, offset: 0 },
    { name: "(UTC+1)Central Europe Standard Time", value: 1, offset: 1 },
    { name: "(UTC+2)Egypt Standard Time", value: 2, offset: 2 },
    { name: "(UTC+3)Turkey Standard Time", value: 3, offset: 3 },
    { name: "(UTC+4)Afghanistan Standard Time", value: 4, offset: 4 },
    { name: "(UTC+5)West Asia Standard Time", value: 5, offset: 5 },
    { name: "(UTC+6)Bangladesh Standard Time", value: 6, offset: 6 },
    { name: "(UTC+7)North Asia Standard Time", value: 7, offset: 7 },
    { name: "(UTC+8)Singapore Standard Time", value: 8, offset: 8 },
    { name: "(UTC+9)North Korea Standard Time", value: 9, offset: 9 },
    { name: "(UTC+10)E. Australia Standard Time", value: 10, offset: 10 },
    { name: "(UTC+11)Magadan Standard Time", value: 11, offset: 11 },
    { name: "(UTC+12)Fiji Standard Time", value: 12, offset: 12 }
  ].sort((a, b) => a.offset - b.offset)
};

const inappType = {
  COMPOSE: "Compose",
  PREVIEW: "Preview",
};

const alertType = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning'
}

const configMappingVar = {
  "@mention": "notification_mentions_user",
  "assignment": "notification_assignment_user",
  "balloon": "show_balloon_notification_user",
};

const colourList = [
  {
    value: "#ff4542",
    label: "Code Red",
    color: "background-color: #ff4542"
  },
  {
    value: "#FF7256",
    label: "Coral",
    color: "background-color: #FF7256"
  },
  {
    value: "#9e42ff",
    label: "Code Purple",
    color: "background-color: #9e42ff"
  },
  {
    value: "#CD69C9",
    label: "Orchid",
    color: "background-color: #CD69C9"
  },
  {
    value: "#1eb7b0",
    label: "Bright Teal",
    color: "background-color: #1eb7b0"
  },
  {
    value: "#00e56f",
    label: "Code Green",
    color: "background-color: #00e56f"
  },
  {
    value: "#ffcb00",
    label: "Code Yellow",
    color: "background-color: #ffcb00"
  },
  {
    value: "#0097fb",
    label: "Code Blue",
    color: "background-color: #0097fb"
  },
  {
    value: "#66b1cb",
    label: "Milky Blue",
    color: "background-color: #66b1cb"
  },
  {
    value: "#9ab0c8",
    label: "Grey Oracle",
    color: "background-color: #9ab0c8"
  }
];

const bookmarkType = {
  DEFAULT: 'default',
  INVISIBLE: 'invisible'
}

const attachmentPreviewType = {
  IN_POPUP: 'popup',
  IN_NEW_WINDOW: 'window',
  AS_TAB: 'tab'
}

const defaultSearchMethods = {
  EXACT_MATCH: 'exact_match',
  CONTAIN_OR: 'contain_or',
  CONTAIN_AND: 'contain_and'
}

const bookmarkRedirectPath = ['/mails']

const trigonalTag = 'trigonal'

const buttonType = {
  toggle: "toggle-button",
  colorPicker: "color-picker",
  tablePicker: "table-picker",
  selectPicker: "select-picker",
  actionPicker: "action-picker",
  modal: "modal-button"
};

const maximumLoadedInlineImages = 12

const maximumOpenTabs = 15

const mailMethods = {
  REPLY: 'reply',
  REPLY_ALL: 'replyall',
  FORWARD: 'forward',
  EMAIL_ALL: 'emailall',
  EDIT: 'edit',
  COMPOSE: 'compose'
}

const displayInlineType = ['message/rfc822']

export default {
  colourList,
  configMappingVar,
  mailboxStatuses,
  folderSearchTypes,
  directory,
  twoFACodeLength,
  folderCategory,
  verificationStatus,
  themeNames,
  assignedTo,
  mailTabs,
  assignableType,
  noticeableType,
  pageNames,
  tabPrefixes,
  tabTypes,
  folderType,
  outlookCalendarColors,
  gmailCalendarColors,
  mailMergeVariables,
  mailVariables,
  templateVariables,
  taskStatus,
  calendarEventTypes,
  calendarResources,
  timeZones,
  eventDeleteTypes,
  helpCenter,
  inappType,
  alertType,
  bookmarkType,
  attachmentPreviewType,
  defaultSearchMethods,
  bookmarkRedirectPath,
  trigonalTag,
  buttonType,
  maximumLoadedInlineImages,
  maximumOpenTabs,
  mailMethods,
  contactCategory,
  displayInlineType
};
