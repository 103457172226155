import { SET_SELECTED_NAV, SET_CONTACT_NAV_GROUPS } from "../../mutation-type";

const state = {
  selectedType: '',
  selectedId: null,
  hidden: false,
  contactNavGroups: {
    shares: [],
    personals: [],
    stars: []
  }
};

const mutations = {
  [SET_SELECTED_NAV](state, obj) {
    state.selectedType = obj.selectedType
    state.selectedId = obj.selectedId
    state.hidden = obj.hidden
  },
  [SET_CONTACT_NAV_GROUPS](state, params) {
    state.contactNavGroups = params;
  }
}

export default {
  state,
  mutations
};
