<template>
  <div>
    <Modal v-model="dialogShow"
           width="700"
           class="dialog mail-report dialog-no-cancel"
           :title="!failedView ? 'Email Broadcast Delivery Report' : 'Error Report'"
           :closable="false"
           :mask-closable="false"
           :scrollable="false"
           :ok-text="'Close'"
           @on-ok="hide">
      <a class="modal-close" @click="hide">
        <Icon type="md-close" size="26" color="#8ca0b3"></Icon>
      </a>
      <section v-if="!failedView">
        <div class="dialog_info">
          <p><span class="dark-blue">{{email.email_unique_id}}</span> was sent to
            <span class="gray">{{email.recipients}}</span>
            recipients on {{email.received_at }}</p>
        </div>
        <div class="dialog-contain">
          <!--loading-->
          <div class="spin-loading" v-if="loading">
            <Spin>
              <Icon type="ios-loading" size='38' class="aside-spin-icon-load"></Icon>
              <div class="load-text">Loading report data …</div>
            </Spin>
          </div>
          <!-------------------------stats----------------------------->
          <div class="stats-wrapper">
            <div class="stats-item delivered" @click="openDetail('delivered')">
              <div class="percent-info">
                <p class="percent">{{delivered.percent}}%</p>
                <p class="type">Delivered
                  <span class="arrow-right"><i class="iconfont ch-icon-arrow-right1"></i></span></p>
              </div>
              <div class="count-info">
                <div class="count-item">
                  <p class="percent">{{open.percent}}%</p>
                  <p class="type">Opened</p>
                </div>
                <div class="count-item">
                  <p class="percent">{{click.percent}}%</p>
                  <p class="type">Clicked</p>
                </div>
                <div class="count-item">
                  <p class="percent">{{spam.percent}}%</p>
                  <p class="type">Spam Reports</p>
                </div>
                <div class="count-item">
                  <p class="percent">{{unread.percent}}%</p>
                  <p class="type">Unread</p>
                </div>
              </div>
            </div>
            <div class="stats-item dropped" @click="openDetail('dropped')">
              <div class="percent-info">
                <p class="percent">{{dropped.percent}}%</p>
                <p class="type">
                  Dropped
                  <span class="arrow-right"><i class="iconfont ch-icon-arrow-right1"></i></span>
                </p>
              </div>
              <div class="count-info">
                <div class="count-text">
                  <p class="count">{{dropped.count}}</p>
                  <p class="text">Recipients</p>
                </div>
              </div>
            </div>
            <div class="stats-item deferred" @click="openDetail('deferred')">
              <div class="percent-info">
                <p class="percent">{{deferred.percent}}%</p>
                <p class="type">Deferred
                  <span class="arrow-right"><i class="iconfont ch-icon-arrow-right1"></i></span></p>
              </div>
              <div class="count-info">
                <div class="count-text">
                  <p class="count">{{deferred.count}}</p>
                  <p class="text">Recipients</p>
                </div>
              </div>
            </div>
            <div class="stats-item bounced" @click="openDetail('bounced')">
              <div class="percent-info">
                <p class="percent">{{bounced.percent}}%</p>
                <p class="type">Bounced
                  <span class="arrow-right">
                  <i class="iconfont ch-icon-arrow-right1"></i>
                </span>
                </p>
              </div>
              <div class="count-info">
                <div class="count-text">
                  <p class="count">{{bounced.count}}</p>
                  <p class="text">Recipients</p>
                </div>
              </div>
            </div>
          </div>
          <!--search-->
          <div class="report-search">
            <div class="search-component">
              <reportSearch :inputSize="'width: 370px'"
                            :buttonSize="'width: 190px'"
                            :placeholder="'Enter email address'"
                            ref="reportSearch"
                            @openSearch="openSearch"></reportSearch>
            </div>
          </div>
        </div>
      </section>
      <!--Failure Report-->
      <section v-else>
        <div class="dialog-failed-contain">
          {{failedReport}}
        </div>
      </section>
      <!--report detail-->
    </Modal>
    <reportDetail ref="reportDetail"
                  :stats="stats"
                  :email="email"></reportDetail>
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import util from 'util';
  import reportDetail from './reportDetail.vue';
  import reportSearch from './reportSearch.vue';
  import {mapGetters, mapState} from 'vuex';

  export default{
    data() {
      return {
        dialogShow: false,
        loading: false,
        butLoading: false,
        mailId: null,
//        数据
        email: {},
        stats: {},
        delivered: {},
        dropped: {},
        deferred: {},
        bounced: {},
        open: {},
        spam: {},
        click: {},
        unread: {},
//        failed 邮件发送失败
        failedView: false,
        failedReport: '',
      }
    },
    created () {
    },
    computed: {
      ...mapGetters([
        "setting_mails",
      ])
    },
    methods: {
//-------------------------显示dialog
      show(id, status){
        Object.assign(this.$data, this.$options.data());
        this.dialogShow = true;
        if (status === 'failed') {
          this.getFailedReport(id);
          this.failedView = true;
        } else {
          this.getReport(id);
        }
      },
      hide(){
        this.dialogShow = false;
      },
//-------------------------请求数据
      getReport(id){
        this.loading = true;
        api.get_mail_report(id).then((res) => {
          this.email = res.email;
          this.stats = res.stats;
//          stats:{},delivered: {},dropped: {},deferred: {},bounced: {},open: {},spam: {},click: {},unread: {},
          this.delivered = res.stats.delivered;
          this.dropped = res.stats.drop;
          this.deferred = res.stats.deferred;
          this.bounced = res.stats.bounce;
          this.open = res.stats.open;
          this.spam = res.stats.spam;
          this.click = res.stats.click;
          this.unread = res.stats.unread;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        });
      },
      getFailedReport(id){
        this.loading = true;
        api.get_failed_report(id).then((res) => {
          this.failedReport = res.content;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          console.log(error);
        });
      },
//-------------------------展开详情
      openDetail(str){
        this.$refs.reportDetail.show(str, false)
      },
//-------------------------search
      openSearch(key){
        if (key === '')return;
        this.$refs.reportDetail.show(key, true);
        this.$nextTick(function () {
          this.$refs.reportSearch.searchKey = '';
        });
      },
    },
    filters: {
      time_format (val) {
        return util.relativeDay(val) + ', ' + util.time_12(val);
      },
    },
    components: {
      reportDetail,
      reportSearch,
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  /*结构*/
  .mail-report {
    .modal-close {
      position: absolute;
      right: 15px;
      top: 19px;
      overflow: hidden;
      cursor: pointer;
    }
    section {
      .dialog_title {
        padding: 19px 21px;
        h2 {
          font-size: 20px;
        }
      }
      .dialog_info {
        background: var(--component-color);
        color: var(--on-component-color);
        padding: 6px 14px;
        p {
          font-size: 12px;
          .dark-blue {
            color: var(--primary-color);
            font-weight: bold;
          }
          .gray {
            font-weight: bold;
          }
        }
      }
      .dialog-contain {
        position: relative;
        margin-top: 0;
        padding: 0;
        background-color: var(--component-color);
        color: var(--on-component-color);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        .stats-wrapper {
          display: flex;
          flex-wrap: wrap;
          .stats-item {
            width: 50%;
            padding: 15px 0;
            cursor: pointer;
            text-align: center;
            transition: all 0.2s ease-in-out;
            border-bottom: 1px solid var(--border-color);
            &:hover {
              background: var(--hover-color);
            }
            &.delivered, &.deferred {
              border-right: 1px solid var(--border-color);
            }
            .percent-info {
              margin: 0 20px;
              text-align: center;
              .percent {
                font-size: 36px;
                color: var(--primary-color);
                font-weight: 600;
                line-height: 1;
              }
              .type {
                position: relative;
                font-size: 24px;
                .arrow-right {
                  position: absolute;
                  right: 42px;
                  .ch-icon-arrow-right1 {
                    font-size: 20px;
                  }
                }
              }
            }
            .count-info {
              display: flex;
              align-items: center;
              height: 60px;
              .count-item {
                flex: 1;
                padding: 4px 0;
                &:last-child {
                  border-right: none;
                }
                .percent {
                  font-size: 16px;
                  margin-bottom: 4px;
                }
                .type {
                  position: relative;
                  font-size: 12px;
                  transform: scale(0.84);
                }
              }
              .count-text {
                width: 100%;
                text-align: center;
                .count {
                  font-size: 24px;
                }
                .text {
                  padding-left: 8px;
                  font-size: 12px;
                  transform: scale(0.84);
                }
              }
            }
          }
        }
        .report-search {
          display: flex;
          height: 80px;
          .search-component {
            height: 40px;
            margin: auto;
          }
        }
      }
      .dialog-failed-contain {
        max-height: 300px;
        min-height: 200px;
        overflow: auto;
        overflow: overlay;
        padding: 16px 21px;
        font-size: 14px;
        background: var(--background-color);
        color: var(--text-color);
      }
    }
  }

  /*loading 动画*/
  .spin-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding-top: 150px;
    background-color: rgba(255, 255, 255, 0.9);
    .load-text {
      font-size: 14px;
      font-weight: normal;
      margin-top: 10px;
    }
    .aside-spin-icon-load {
      animation: ani-demo-spin 1s linear infinite;
    }
  }

</style>
