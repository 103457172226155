/**
 * Created by zxy on 2018/4/26.
 */
import api from 'api'
import util from 'util'
import {SET_SHIP_ADVANCE} from '../../mutation-type'

const advance_search = util.storeWithExpiration.get('shipAdvance') || {};

const state = {
  rules: advance_search || ''
};
const getters = {
  shipAdvance: state => state,
};
const mutations = {
  [SET_SHIP_ADVANCE]: (state, params) => {
    state.rules = params;
  },
};

const actions = {
  saveShipAdvance({commit}, params){
    commit(SET_SHIP_ADVANCE, params);
    // params= JSON.stringify(params);
    util.storeWithExpiration.set('shipAdvance', params, 0);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
