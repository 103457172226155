import api from "api";
import util from "util";

import {
  UPDATE_CONTACT_DATA,
  RESET_CONTACT_PRE_UPDATE,
  SET_CONTACT_PRE_UPDATE,
  SET_CONTACT_GROUPS
} from "../../mutation-type";

const defaultVal = {
  notData: false,
  totalNum: 0,
  currentPage: 1,
  tableData: [],
  error: null
};

const state = {
  contactList: Object.assign({}, defaultVal),
  contactGroups: [],
  contactPreUpdate: {
    currentPage: null,
    currentPath: null,
    currentTab: null,
    currentLevel: null
  }
};

const getters = {
  contactData: state => state.contactList,
  contactPreUpdate: state => state.contactPreUpdate,
  contactGroups: state => state.contactGroups
};

const updateContactData = (commit, response) => {
  let data = Object.assign({}, defaultVal);
  if (
    response &&
    response.address_books &&
    response.address_books.length != 0
  ) {
    let tableData = response.address_books;
    const responseData = {
      notData: false,
      totalNum: response.total,
      currentPage: response.page,
      level: response.level,
      tableData
    };
    data = Object.assign({}, data, responseData);
  } else {
    data = Object.assign({}, data, response);
  }
  commit(UPDATE_CONTACT_DATA, data);
};

const mutations = {
  [UPDATE_CONTACT_DATA]: (state, data) => {
    state.contactList = Object.assign({}, state.contactList, data);
  },
  [SET_CONTACT_PRE_UPDATE]: (state, params) => {
    state.contactPreUpdate = Object.assign({}, state.contactPreUpdate, params);
  },
  [RESET_CONTACT_PRE_UPDATE]: state => {
    state.contactPreUpdate = {
      currentPage: null,
      currentPath: null,
      currentTab: null,
      currentLevel: null,
      currentAddBookId: null,
      currentDepartmentId: null,
      currentCompany: null,
      currentDepartment: null
    };
  },
  [SET_CONTACT_GROUPS]: (state, data) => {
    state.contactGroups = data;
  }
};

const actions = {
  async getContactList({ commit }, params) {
    const {
      address_group_id,
      address_book_id,
      department_id,
      level,
      page,
      comPerson
    } = params;
    const publicParam = params.public;
    let key;
    key = `${page}${level ? "-" + level : ""}${
      address_book_id ? "-" + address_book_id : ""
    }${department_id ? "-" + department_id : ""}`;
    key = address_group_id ? `${address_group_id}-${key}` : `${String(publicParam)}-${key}`;

    !comPerson && util.getDataFromSw(`address_books-${key}`, data => {
      updateContactData(commit,Object.assign({}, data, { level: params.level }));
    });
    const data = await api.get_contact_list(params).catch(_ => {
      updateContactData(commit,Object.assign({}, defaultVal, { level: params.level }));
    });
    if(data) {
      updateContactData(commit,Object.assign({}, data, { level: params.level }));
    }
  },
  async getContactGroups({ commit }, params) {
    const res = await api.get_search_list(params).catch(error => console.log(error));
    if (!res) return;
    commit("SET_CONTACT_GROUPS", res.address_groups);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
