<template>
  <div class="positions-wrapper">
    <div class="small-notice">{{noticeEmailNumber}}</div>
    <div class="loading" v-if="loadingThread && threadPage <= 1">
      <Spin size="large" fix></Spin>
    </div>
    <div class="more-loader">
      <div v-if="loadingThread && threadPage > 1" class="loader"></div>
    </div>
    <div class="thread">
      <div class="ch-thread-mail-container">
        <RecycleScroller
          :items="threadEmails"
          :item-size="target_id ? 85 : 67"
          key-field="id"
        >
        <template v-slot="{ item }">
          <div
            class="thread-item"
            :class="{'current-mail': currentThreadMailId===item.id, 'original-mail': originalEmailId===item.id}"
            :id="`thread-${item.id}`"
            @click="$event => onSingleClickEmail($event, item)"
          >
            <avatar :fullname="item.username" v-if="!target_id" />
            <div class="thread-info" :title="item.from">
              <div class="thread-info__from" v-if="!target_id">{{item.username || item.from}}</div>
              <div class="thread-info__subject" v-else>{{item.subject || "No subject"}}</div>
              <div class="thread-info__excerpt" v-if="target_id">{{item.plain_text_preview || '-'}}</div>
              <div class="thread-info__date">
                <ch-status :status="item.status"></ch-status>
                {{item.received_at | format_date_time}}
              </div>
            </div>
          </div>
        </template>
        </RecycleScroller>
      </div>
    </div>
  </div>
</template>
<script>
import util from "util";
import { mapState, mapActions } from "vuex";
import Avatar from "@/pages/components/avatar.vue";
import Cstatus from "@/pages/components/Cstatus";
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  data() {
    return {
      currentThreadMailId: null,
      mailDetail: {},
      threadPage: 1,
      singleClickTimeout: null,
      prevThreadMailId: null
    };
  },
  props: {
    originalMail: {
      type: Object,
      default: ()=>{}
    },
    target_id: {
      type: Number
    }
  },
  mounted() {
    this.target_id && this.scrollToLoadMore()
  },
  methods: {
    ...mapActions(["readEmail", "loadThreadEmails"]),
    scrollToLoadMore() {
      const threadEl = document.querySelector(".thread");
      threadEl && util.scrollToLoadMore(threadEl, {
        bottomDistance: 50,
        scrollToBottomCallback: () => this.loadMore(),
      });
    },
    loadMore() {
      if (this.threadEmails.length >= this.maxThreadEmail || this.loadingThread) return;
      this.loadEmails();
    },
    loadEmails() {
      this.threadPage += 1;
      const params = {
        target_id: this.target_id,
        page: this.threadPage,
        history: this.target_id ? false : true
      }
      this.loadThreadEmails(params);
    },
    onSingleClickEmail($event, email) {
      if ($event.detail === 2) {
        email && this.onDblClickEmail(email.id);
        return;
      }

      !this.prevThreadMailId && (this.prevThreadMailId = this.currentThreadMailId);
      this.currentThreadMailId = email.id;

      this.singleClickTimeout = setTimeout(async () => {
        this.prevThreadMailId = null;

        if (!email.read && this.originalMail) {
          const para = {
            rowId: email.id,
            lineId: this.originalMail.line_ids,
            receivedAt: this.originalMail.received_at,
            box: this.originalMail.box,
            archived: this.originalMail.archived
          };
          await this.readEmail(para);
        }
        this.$emit("updateThreadMailId", email.id);
        this.mailDetailFn.getEmailDetail && this.mailDetailFn.getEmailDetail(email.id)
      }, 300)
    },
    onDblClickEmail(id) {
      if (this.singleClickTimeout){
        clearTimeout(this.singleClickTimeout);
        this.singleClickTimeout = null;
      }

      this.currentThreadMailId = this.prevThreadMailId;
      this.prevThreadMailId = null;

      this.openNewWindow(id);
    },
    openNewWindow(emailId) {
      const url = `mail/${emailId}`;
      const popup = util.openWindow(url);
    }
  },
  inject: {
    mailDetailFn: {
      default: {}
    }
  },
  computed: {
    ...mapState(["threadEmails", "maxThreadEmail", "loadingThread"]),
    noticeEmailNumber() {
      if (this.threadEmails.length > 1) {
        return `There are ${this.threadEmails.length} emails in this thread.`;
      } else {
        return `There is only ${this.threadEmails.length} email in this thread.`;
      }
    },
    originalEmailId() {
      return this.$store.state.originalEmailId;
    }
  },
  components: {
    Avatar,
    "ch-status": Cstatus,
    RecycleScroller
  },
  watch: {
    originalMail: {
      immediate: true,
      handler(mail) {
        if (!mail) return;
        this.currentThreadMailId = mail.id;
        if (!mail.isOriginal) return;
        this.$store.commit("ADD_THREAD_EMAILS", {
          originalEmailId: this.originalEmailId || mail.id,
          emails: []
        });
      }
    },
    threadEmails(emails) {
      if (!this.originalMail) return;

      if (emails && emails.length > 1) {
        this.$nextTick(() => {
          const currentThread = document.getElementById(
            `thread-${this.originalMail.id}`
          );
          currentThread && currentThread.scrollIntoView();
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.thread {
  flex: 1;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
}
.ch-thread-mail-container {
  padding: 1px 0;
}
.thread-item {
  display: flex;
  background-color: var(--background-color);
  padding: 10px;
  margin: 0px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  .thread-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    min-width: 0;
    &__from, &__subject {
      font-size: 14px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__excerpt {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__date {
      i {
        font-size: 12px;
        margin-right: 2px;
      }
    }
  }
}
.current-mail {
  box-shadow: 0px 0px 0px 2px var(--primary-color) inset;
}
.original-mail {
  background-color: var(--highlight-color) !important;
  border: none;
}
</style>
