<template>
  <div class="inapp-preview">
    <div v-show="inAppState.visible" @click.stop class="inapp-compose-wrapper">
      <MailDetail ref="MailDetail" :selectedEmail="inAppState.data" :previewEmailId="inAppState.id" :highlights="[]"
        :singleClick="true" class="expandedPreview" :style="{ zIndex: isOpeningInappWindow ? 100 : 1000 }" />
    </div>
  </div>
</template>
  
<script>
import { computed } from 'vue';
import { mapGetters } from 'vuex';
const MailDetail = () => import('@/pages/mails/detail/mailDetail.vue');

export default {
  data() {
    return {}
  },
  props: ["inAppState"],
  provide() {
    return {
      inAppState: computed(() => this.inAppState)
    }
  },
  computed: {
    ...mapGetters(["isOpeningInappWindow"]),
  },
  components: {
    MailDetail
  },
  watch: {
    "$route.query"() {
      this.$store.commit("INAPP_WINDOW_MINIMIZE_ALL");
    }
  },
}
</script>
    
<style lang="scss">
.inapp-preview {
  z-index: 102;
}

.inapp-compose-wrapper {
  min-width: 670px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  z-index: 2;
}

.compose-header {
  width: 100%;
  height: 40px;
  background-color: var(--background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.inapp-compose-header-btn {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
  }

  .icon-edit {
    font-size: 10px;
    padding: 0;
  }
}
</style>
  