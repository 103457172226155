/* eslint-disable no-console */

import { register } from "register-service-worker";
let statusInterval, updateInterval, count = 1
if (process.env.NODE_ENV === "production") {
  register("./sw.js", {
    registrationOptions: {
      updateViaCache: "none",
      scope: "./"
    },
    ready() {},
    registered(registration) {
      console.log('Service worker registration succeeded.')
      window._newVersion = false
      window._newVersionClass = undefined
      count = 1
      clearInterval(statusInterval)
      clearInterval(updateInterval)
      setInterval(() => {
        console.log('Service worker is updating.')
        registration.update()
      }, 1000*60*60)
    },
    cached() {},
    updatefound() {},
    updated() {
      console.log("New content is available; please refresh.");
      if(!window._newVersion) {
        window._newVersion = true;
        if (/mail\/|sendMail|printMail/i.test(location.hash)) return
        let el = document.querySelector(".new-version")
        el && updateCssClass(el, 'green')
        el && (statusInterval = setInterval(() => {
          count++
          count == 2 && updateCssClass(el, 'orange')
          count > 2 && updateCssClass(el, 'red')
        }, 1000*60*60*24))
      }
      function updateCssClass(el, classname) {
        el.classList.add(classname)
        window._newVersionClass = classname
      }
    },
    offline() {},
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
} else {
  navigator.serviceWorker && navigator.serviceWorker.register('/sw.js').then(function (registration) {
    console.log('Service worker registration succeeded.');
  }, /*catch*/ function (error) {
    console.log('Service worker registration failed.');
  });
}