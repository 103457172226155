/**
 * Created by zxy on 2017/12/20.
 */
import api from 'api'
import util from 'util'
import {SET_BASIC_DATA, TOGGLE_PIN_MENU} from '../mutation-type'
import storageConstant from '@/common/constants/local-storage.constant';

const basicData = util.storeWithExpiration.get(storageConstant.BASIC) || {};

const state = {
    //add ship select
    flag_names: basicData.flag_names || [],
    hull_types: basicData.hull_types || [],
    number_of_decks: basicData.number_of_decks || [],
    ship_status: basicData.ship_status || [],
    shiptype_groups: basicData.shiptype_groups || [],
    type_level5: basicData.type_level5 || [],
    //add contact select
    countries: basicData.countries || [],
    //profile
    timezoneList: basicData.timezones || [],
    pinnedMenu: localStorage.getItem('pin_menu') === 'true'
  }
;
const getters = {
  basicData: state => state,
};
const mutations = {
  //ship basic select
  [SET_BASIC_DATA]: (state, params) => {
    //ship
    state.flag_names = params.flag_names;
    state.hull_types = params.hull_types;
    state.number_of_decks = params.number_of_decks;
    state.ship_status = params.ship_status;
    state.shiptype_groups = params.shiptype_groups;
    state.type_level5 = params.type_level5;
    //contact
    state.countries = params.countries;
    //profile
    state.timezoneList = params.timezones;
    util.storeWithExpiration.set(storageConstant.BASIC, params, 0);
  },
  [TOGGLE_PIN_MENU]: (state, param) => {
    state.pinnedMenu = !state.pinnedMenu
    localStorage.setItem('pin_menu', state.pinnedMenu)
  }
};

const actions = {
  //----------------------------ship
  loadBasicData({commit}){
    api.get_basic()
      .then((res) => {
        commit(SET_BASIC_DATA, res);
      })
      .catch((error) => {
        console.log(error);
        commit(SET_BASIC_DATA, {});
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
