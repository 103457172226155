<template>
  <div class="dynamic_wrapper" v-clickoutside="handleClose">
    <Dropdown trigger="custom" :visible="visible" placement="bottom-start" :style="width">
      <!--:disabled="disable"-->
      <Input v-model.trim="searchKey"
             :class="{'error':error}"
             @on-focus="searchByKey"
             @on-blur="handleBlur"
             :placeholder="placeholder"
             @on-keydown="pointer($event)"
             @on-enter="addPointerElement"
             style="width: 100%">
      </Input>
      <DropdownMenu slot="list">
        <ul class="down_wrapper" v-show="searchList.length>0&&searchKey!=''" :style="maxHeight">
          <div class="search_list">
            <li v-for="(item,index) in searchList"
                class="row_item"
                :class="{'row-highlight': listSelect===index}"
                @click.prevent.stop="selectList(index,item)"
                :key="index">
              <!--vessel-->
              <div class="vessel-item" v-show="category=='vessel'">
                <p class="name">{{item.name}}</p>
                <p class="info">DWT: {{item.deadweight}} | Flag: {{item.flag_name}}</p>
                <p class="info">Ex Name: {{item.ex_name}}</p>
                <p class="info">Type: {{item.type_level2}}</p>
              </div>
              <!--port-->
              <div class="port-item" v-show="category=='port'">
                {{item.name}}
              </div>
            </li>
          </div>
        </ul>
        <!--No matching data-->
        <ul class="down_wrapper" v-show="searchList.length==0&&searchKey!=''">
          <li class="no_data" v-show="category=='vessel'">
            No matching data!<br>
            You may continue to add <br>
            this entry as a new ship
          </li>
          <li class="no_data" v-show="category=='port'">
            No matching data!<br>
            You may continue to add<br>
            this entry as a new port
          </li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import debounce from 'lodash/debounce'; //引入lodash
  import {mapGetters} from 'vuex'
  import clickoutside from '../../../utils/clickoutside';

  export default{
    name: 'autoInput',
    directives: {clickoutside},
    props: {
      value: {//v-model
        default: '',
      },
      width: {
        type: String,
      },
      height: {
        type: Number,
      },
      itemHeight: {
        type: Number,
      },
      placeholder: {
        type: String,
      },
      category: {
        type: String,
      },
//      disable: {
//        type: Boolean,
//      }
    },
    data() {
      return {
        dataList: [],
        companies: [],
        visible: false,
        searchKey: '',
        listSelect: -1,
        error: false,
      }
    },
    created () {
      this.searchKey = this.value
    },
    computed: {
//-------------------------------------动态过滤
      searchList: function () {
        return this.dataList;
      },
//----------------------------下拉框最大宽度
      maxHeight: function () {
        return 'max-height:' + this.height + 'px';
      },
//      选中框位置
      pointerPosition(){
        let pointer = this.listSelect + 1;
        return pointer * this.itemHeight;
      },
    },
    methods: {
//------------------------focus
      searchByKey(){
        this.visible = true;
      },
      handleBlur(){
        setTimeout(() => {//点击延时
          this.visible = false;
        }, 100);
        this.reportError();
      },
//----点击其余地方 隐藏dropDown
      handleClose(){
        this.visible = false;
      },
//     this.searchKey为空时 显示警告
      reportError(){
        if (this.searchKey === '') {
          let str = this.category;
          str = str.substring(0, 1).toUpperCase() + str.substring(1);
          this.error = true;
          const para = {
            error: true,
            text: str + ' cannot be empty'
          };
          this.$emit('reportError', para)
        } else {
          this.error = false;
          const para = {
            error: false,
            text: ''
          };
          this.$emit('reportError', para)
        }
      },
//----------------------------点击选中
      selectList(index, item){
        this.listSelect = index;
        this.searchKey = item.name;
      },
//----------------------------键盘事件 up/down
      pointer(e){
        if (e.keyCode === 40) {//down
          this.pointerForward()
        }
        if (e.keyCode === 38) {//up
          this.pointerBackward()
        }
      },
//-----------------------向下 down
      pointerForward(){
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        let visibleElements = this.height / this.itemHeight;
        if (s > 0) {
          if (this.listSelect < s - 1) {
            this.listSelect++;
          } else {
            this.listSelect = s - 1;
          }
        }
        if (obj.scrollTop <= this.pointerPosition - visibleElements * this.itemHeight) {
          obj.scrollTop = this.pointerPosition - this.itemHeight;
        }
      },
//-----------------------向上 UP
      pointerBackward() {
        let s = this.searchList.length;
        let obj = this.$el.querySelector('.down_wrapper');
        if (s > 0) {
          if (this.listSelect > 0) {
            this.listSelect--;
          } else {
            this.listSelect = 0;
          }
        }
        if (obj.scrollTop >= (this.pointerPosition - this.itemHeight)) {
          obj.scrollTop = this.pointerPosition - this.height;
        }
      },
//----------------------------up/down 回车选中
      addPointerElement(){
        const s = this.searchList.length;
        if (s === 0) {
          return
        }
        if (this.listSelect < s && this.listSelect > -1) {
          this.searchKey = this.searchList[this.listSelect].name;
          this.$el.querySelector('.ivu-input').blur();
        }
      },

    },
    filters: {},
    watch: {
//    检测输入框变化
      searchKey: debounce(
        function () {
//          参数初始化
          this.listSelect = 0;
          this.$el.querySelector('.down_wrapper').scrollTop = 0;
          if (this.category === 'vessel') {
            if (this.searchKey !== '') {//动态过滤
              api.search_ship_by_name(this.searchKey).then((res) => {
                this.dataList = res.ships;
              }).catch((error) => {
                console.log(error)
              });
            }
          }
          if (this.category === 'port') {
            if (this.searchKey !== '') {//动态过滤
              api.search_port(this.searchKey).then((res) => {
                this.dataList = res.ports;
              }).catch((error) => {
                console.log(error)
              });
            }
          }
          this.$nextTick(function () {
            if (this.searchList.length === 0) {
              this.$emit('selected', 0)
            } else {
              let index = this.searchList.findIndex((item) => {
                return item.name == this.searchKey;
              });
              if (index !== -1) {
                this.$emit('selected', this.searchList[index].id)
              } else {
                this.$emit('selected', 0)
              }
            }
          });
          this.$emit('input', this.searchKey)
        },
        100

      ),
      value () {
        this.searchKey = this.value
      }
//      检测disable 如果为true department 置空
//      disable: function () {
//        if (this.category === 'department') {
//          if (this.disable) {
//            this.searchKey = '';
//          }
//        }
//      },

    },
    components: {}
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .dynamic_wrapper {
    width: 100%;
    .down_wrapper {
      /*width: 100%;*/

      overflow: auto;
      overflow: overlay;
      text-align: left;
      .search_list {
        .row_item {
          cursor: pointer;
          color: var(--on-component-color);
          font-size: 14px;
          line-height: 1.26;
          padding: 6px 3px 6px 8px;
          border-bottom: solid 1px var(--border-color);
          .vessel-item {
            .name {
              font-size: 14px;
              margin-bottom: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .info {
              font-size: 12px;
              transform: scale(0.8);
              margin-left: -10%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: var(--hover-color);
            color: var(--text-color); 
          }
          &.row-highlight {
            background-color: var(--highlight-color);
            color: var(--text-color); 
          }
        }
      }
      .no_data {
        width: 100%;
        white-space: nowrap;
        padding: 0 6px;
        font-size: 12px;
        transform: scale(0.8);
        margin-left: -10%;
        color: #666;
        text-align: left;
      }
    }
  }
</style>
