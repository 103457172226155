import {
  LOADING_CALENDAR,
  LOADING_CALENDAR_EVENTS,
  LOADING_FORM_SUBMIT,
  SET_CALENDAR_EVENTS,
  UPDATE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT,
  SET_SELECTED_CALENDAR_RESOURCES,
  SET_CALENDAR_RESOURCES,
} from "../../mutation-type";
import api from "api";

import { Message } from "iview";

import appConstant from "@/common/constants/app.constant";
import { Toastr } from "@/main";
import md5 from "md5";

const EVENT_NOTIFY_BEFOFRE = 10; // minutes

let selectedColors = {};
const calendarResourceMap = {}
const currentEventDateRange = {
  start: new Date().toISOString(),
  end: new Date().toISOString()
}
const eventMap = {}
let eventNotifyTimeout = new Map()

function handleColor(colorCode, type) {
  if (colorCode !== "") {
    selectedColors[type].push(colorCode);
    return colorCode;
  }

  const colors = type === "gmail" ? appConstant.gmailCalendarColors : appConstant.outlookCalendarColors;
  const colorList = colors.map(({value}) => value);
  const availableColors = colorList.filter(
    (c) => !selectedColors[type].includes(c)
  );

  if (availableColors.length <= 0) {
    selectedColors[type] = [];
    return handleColor(colorCode, type);
  }

  selectedColors[type].push(availableColors[0]);
  return availableColors[0];
}

function correctTimezoneOutlook(e) {
  if (!/Z|\+|(UTC)/gi.test(e.start.toString())) {
    e.start = e.start + "Z";
    e.end = e.end + "Z";
  }
}

function handleAfterCreateUpdateEvent(event, eventource) {
  event.color = eventource.converted_auto_color;
  event.all_day && (event.allDay = true);

  if (event.eid) {
    event.id = event.eid;
    correctTimezoneOutlook(event);
  }

  event.editable = eventource.editable;
  event.groupId = event.type = appConstant.calendarEventTypes.EVENT;

  addEventReminder(event);
}

function addEventReminder(event) {
  const id = event.id || event.eid

  if (eventNotifyTimeout.has(id)) {
    clearTimeout(eventNotifyTimeout.get(id))
  }

  if (new Date(event.start) < new Date() || event.all_day) return

  const duration = +new Date(event.start) - EVENT_NOTIFY_BEFOFRE * 60 * 1000 - +new Date();

  eventNotifyTimeout.set(
    id,
    setTimeout(() => {
      Toastr.dispatch("show", {message: {
        content: `<b>${event.title}</b>`,
        start: event.start,
        hash: md5(`${event.title}-${event.start}`)
      }});
    }, Math.max(duration, 0))
  )
}

const savedCalendarResources = JSON.parse(localStorage.getItem("selected-calendar-resources")) || []

const state = {
  loadingCalendar: false,
  loadingCalendarEvents: false,
  loadingFormSubmit: false,
  calendarEvents: [],
  calendarResources: [],
  selectedResources: [...new Set(savedCalendarResources)]
};

const getters = {
  calendar: (state) => state,
  filteredCalendarEvents: (state) =>
    state.calendarEvents.filter((c) => {
      return (c.type == appConstant.calendarEventTypes.TASK || state.selectedResources.includes(c.calendar_id));
    }),
  calendarResources: (state) => state.calendarResources,
  loadingCalendar: (state) => state.loadingCalendar,
  loadingCalendarEvents: (state) => state.loadingCalendarEvents,
  loadingFormSubmit: (state) => state.loadingFormSubmit,
  defaultCalendar: (state) => state.calendarResources.find(c => !!c.default)
};
const mutations = {
  [LOADING_CALENDAR]: (state, status) => {
    state.loadingCalendar = status;
  },
  [LOADING_CALENDAR_EVENTS]: (state, status) => {
    state.loadingCalendarEvents = status;
  },
  [LOADING_FORM_SUBMIT]: (state, status) => {
    state.loadingFormSubmit = status;
  },
  [SET_CALENDAR_EVENTS]: (state, source) => {
    state.calendarEvents = source;
    state.loading = false;
  },
  [UPDATE_CALENDAR_EVENT]: (state, event) => {
    let index = state.calendarEvents.findIndex(e => e.id == event.id && e.type == event.type);
    index > -1 && state.calendarEvents.splice(index, 1, event);
  },
  [DELETE_CALENDAR_EVENT]: (state, { id, type }) => {
    const index = state.calendarEvents.findIndex(e => e.type == type && e.id == id);
    state.calendarEvents.splice(index, 1);
  },
  [SET_SELECTED_CALENDAR_RESOURCES]: (state, resources) => {
    state.selectedResources = [...new Set(resources)];
    localStorage.setItem(
      "selected-calendar-resources",
      JSON.stringify(state.selectedResources)
    );
  },
  [SET_CALENDAR_RESOURCES]: (state, resources) => {
    state.calendarResources = resources;
    resources.forEach(r => calendarResourceMap[r.id] = r)
  },
};
const actions = {
  async getCalendarEvents({ commit, state, dispatch }, params = {}) {
    if (new Date(currentEventDateRange.start) > new Date(params.start)) currentEventDateRange.start = params.start.toString()
    if (new Date(currentEventDateRange.end) < new Date(params.end)) currentEventDateRange.end = params.end.toString()

    try {
      commit(LOADING_CALENDAR_EVENTS, true);
      eventNotifyTimeout.clear()

      const allPromise = state.selectedResources
        .filter(id => !!calendarResourceMap[id])
        .map(id => {
        const calendar = calendarResourceMap[id]

        return api.get_events_by_date({
          start: params.start || currentEventDateRange.start,
          end: params.end || currentEventDateRange.end,
          line_id: calendar.calendar_type === 'local' ? null : calendar.line_id,
          calendar_id: calendar.calendar_type === 'local' ? calendar.id : calendar.cid
        });
      })

      await Promise.all(allPromise)
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_CALENDAR_EVENTS, false);
    }
  },
  async getCalendarEventsByIds({commit}, ids) {
    const allPromise = ids.map(id => {
      const calendar = calendarResourceMap[id]

      return api.get_events_by_date({
        start: currentEventDateRange.start,
        end: currentEventDateRange.end,
        line_id: calendar.calendar_type === 'local' ? null : calendar.line_id,
        calendar_id: calendar.calendar_type === 'local' ? calendar.id : calendar.cid
      });
    })

    await Promise.all(allPromise)
  },
  setCalendarEvents({commit}, events) {
    let listData = [];
    listData = events.map((e) => {
      e.eid && correctTimezoneOutlook(e);
      e.groupId = e.type = appConstant.calendarEventTypes.EVENT;
      e.all_day && (e.allDay = true);
      !!e.eid && (e.id = e.eid);
      state.calendarResources.forEach((c) => {
        if ([c.id, c.cid].includes(e.calendar_id)) {
          e.color = c.converted_auto_color;
          e.editable = c.editable;
        }
      });

      eventMap[e.id] = e
      return e;
    });

    if (listData.length === 0) return;

    state.calendarEvents = state.calendarEvents.filter(e => {
      return e.calendar_id !== listData[0].calendar_id
    })

    listData.forEach(addEventReminder)

    commit(SET_CALENDAR_EVENTS, [...state.calendarEvents, ...listData]);
  },
  async createCalendarEvent({ commit, state }, params) {
    try {
      commit(LOADING_FORM_SUBMIT, true);
      const resource = state.calendarResources.find(
        (c) => c.id === params.calendar_id
      );
      if (!resource) return;
      const data = {
        event: {
          title: params.title,
          body: params.body,
          body_type: params.body_type,
          start: params.start,
          end: params.end,
          all_day: params.all_day,
          email_id: params.email_id,
          calendar_id: params.calendar_id,
          start_timezone: !["local", "ews"].includes(params.calendar_type)  ? params.start_timezone : undefined,
          attendees: params.attendees,
          recurrence: params.recurrence,
          location: params.location
        },
      };
      data.line_id =
        params.calendar_type === "local" ? undefined : resource.line_id;
      data.calendar_type = params.calendar_type;
      data.calendar_id = params.calendar_id;

      const res = await api.add_calendar_event(data);
      if (!res || !res.event) return

      handleAfterCreateUpdateEvent(res.event, resource);

      res.event.color = resource.converted_auto_color;
      res.event.all_day && (res.event.allDay = true);
      res.event.eid && (res.event.id = res.event.eid);
      res.event.editable = resource.editable;
      res.event.groupId = res.event.type = appConstant.calendarEventTypes.EVENT;
      commit(SET_CALENDAR_EVENTS, [...state.calendarEvents, res.event]);
      commit(LOADING_FORM_SUBMIT, false);
    } catch (error) {
      console.log(error);
      commit(LOADING_FORM_SUBMIT, false);
      throw(error);
    }
  },
  async getEventDetail({ commit, state }, params) {
    commit(LOADING_FORM_SUBMIT, true);
    const res = await api.get_event_detail(params)
    commit(LOADING_FORM_SUBMIT, false);
    return res && res.event ? res.event : res;
  },
  async updateCalendarEvent({ commit, state }, params) {
    try {
      commit(LOADING_FORM_SUBMIT, true);
      const resource = state.calendarResources.find(
        (c) => c.id === params.calendar_id
      );
      if (!resource) return;

      const data = {
        event: {
          id: params.eid || params.id,
          eid: params.eid || null,
          title: params.title,
          body: params.body,
          body_type: params.body_type,
          start: params.start,
          end: params.end,
          all_day: params.all_day,
          color: resource.converted_auto_color,
          email_id: params.email_id,
          calendar_id: params.calendar_id,
          start_timezone: !["local", "ews"].includes(params.calendar_type)  ? params.start_timezone : undefined,
          attendees: params.attendees,
          recurrence: params.recurrence,
          location: params.location
        }
      };
      data.line_id =
        params.calendar_type === "local" ? undefined : resource.line_id;
      data.calendar_type = params.calendar_type;
      data.calendar_id = params.calendar_id;

      delete data.event.color;
      const res = await api.update_calendar_event(data);

      if (res && res.event) {
        handleAfterCreateUpdateEvent(res.event, resource);
        const newSource = state.calendarEvents.map(e => {
          return e.id == res.event.id
            ? Object.assign({}, e, res.event)
            : e;
        });
        commit(SET_CALENDAR_EVENTS, newSource);
        commit(LOADING_FORM_SUBMIT, false);
      }
    } catch (error) {
      console.log(error);
      commit(LOADING_FORM_SUBMIT, false);
      throw(error);
    }
  },
  async deleteCalendarEvent({ commit, state }, params) {
    const key = params.type == appConstant.eventDeleteTypes.ALL ? 'seriesMasterId'
      : "id";
    try {
      commit(LOADING_FORM_SUBMIT, true);
      const newSource = state.calendarEvents.filter(
        (e) => e[key] !== params.id
      );
      commit(SET_CALENDAR_EVENTS, newSource);
      Message.success(`Delete ${ params.type == appConstant.eventDeleteTypes.ALL ? 'events' : 'an event' } successfully.`);

      delete params.type
      api.delete_calendar_event(params);
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_FORM_SUBMIT, false);
    }
  },
  async getCalendarResources({ state, commit }) {
    try {
      commit(LOADING_CALENDAR, true);
      selectedColors = {
        local: [],
        outlook: [],
        gmail: [],
        ews: []
      };

      const res = await api.get_calendars();
      if (res) {
        let resource = res.calendars || [];

        const localCalendars = resource
          .filter((r) => r.calendar_type === "local")
          .map((r) => {
            const colorObj = appConstant.outlookCalendarColors.find(cl => cl.key == r.color) || {key: "-1", value: ""};
            r.converted_auto_color = handleColor(colorObj.value, "local");
            return r;
          });
        const outlookCalendars = resource
          .filter((r) => r.calendar_type === "outlook")
          .map((r) => {
            const colorObj = appConstant.outlookCalendarColors.find(cl => cl.key == r.color) || {key: "-1", value: ""};
            r.converted_auto_color = handleColor(colorObj.value, "outlook");
            r.localId = r.id;
            r.id = r.cid;
            return r;
          });
        const gmailCalendars = resource
          .filter((r) => r.calendar_type === "gmail")
          .map((r) => {
            const colorObj = appConstant.gmailCalendarColors.find(cl => cl.key == r.color) || {key: "0", value: ""};
            r.converted_auto_color = handleColor(colorObj.value, "gmail");
            r.localId = r.id;
            r.id = r.cid;
            return r;
          });
        const ewsCalendars = resource
          .filter((r) => r.calendar_type === "ews")
          .map((r) => {
            const colorObj = appConstant.gmailCalendarColors.find(cl => cl.key == r.color) || {key: "0", value: ""};
            r.converted_auto_color = handleColor(colorObj.value, "ews");
            r.localId = r.id;
            r.id = r.cid;
            return r;
          });
        const allResources = [
          ...localCalendars,
          ...outlookCalendars,
          ...gmailCalendars,
          ...ewsCalendars
        ]
        commit(SET_CALENDAR_RESOURCES, allResources);
        state.selectedResources.length === 0 && commit(SET_SELECTED_CALENDAR_RESOURCES, allResources.map(r => r.id));
      }
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_CALENDAR, false);
    }
  },
  async createCalendarResource({ commit, dispatch }, params) {
    try {
      commit(LOADING_CALENDAR, true);
      const res = await api.add_calendar({
        calendar: params.formData,
        line_id: params.formData.line_id,
        calendar_type: params.calendarType,
      });

      const calendarColors = params.calendarType === "gmail" ? appConstant.gmailCalendarColors : appConstant.outlookCalendarColors;
      const colorObj = calendarColors.find(cl => cl.key == res.color) || {value: ""};
      res.converted_auto_color = handleColor(colorObj.value, params.calendarType);

      res.cid && (res.id = res.cid);
      await dispatch("getCalendarResources");
      commit(SET_SELECTED_CALENDAR_RESOURCES, [
        ...state.selectedResources,
        res.id,
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_CALENDAR, false);
    }
  },
  async updateCalendarResource({ commit, state }, params) {
    const formData = params.formData;
    try {
      commit(LOADING_CALENDAR, true);
      let newSource = state.calendarResources.map((e) => {
        if (
          (e.id && e.id == formData.id) ||
          (e.cid && e.cid === formData.cid)
        ) {
          const calendarColors = formData.calendar_type === "gmail" ? appConstant.gmailCalendarColors : appConstant.outlookCalendarColors;
          const colorObj = calendarColors.find(cl => cl.key == formData.color) || {value: ""};

          formData.converted_auto_color = handleColor(
            colorObj.value,
            params.calendarType
          );
          return Object.assign({}, e, formData);
        }

        formData.default && (e.default = false);
        return e;
      });
      commit(SET_CALENDAR_RESOURCES, newSource);

      const newEvents = state.calendarEvents.map((e) => {
        if ([formData.id, formData.cid].includes(e.calendar_id)) {
          e.color = formData.converted_auto_color;
        }
        return e;
      });
      commit(SET_CALENDAR_EVENTS, newEvents);

      const updatedData = { ...formData };
      updatedData.id = updatedData.localId || updatedData.id;

      delete updatedData.selected;
      delete updatedData.converted_auto_color;
      delete updatedData.localId;

      api.update_calendar({
        calendar: updatedData,
        line_id: updatedData.line_id,
        calendar_type: params.formData.calendar_type,
      });
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_CALENDAR, false);
    }
  },
  async deleteCalendarResource({ commit, state }, params) {
    try {
      commit(LOADING_CALENDAR, true);
      const key = params.calendar_type === "local" ? "id" : "cid";
      const toDeleteCalendar = state.calendarResources.find(
        (r) => r[key] === params.id
      );
      selectedColors[params.calendar_type] = selectedColors[
        params.calendar_type
      ].filter((c) => c !== toDeleteCalendar.converted_auto_color);

      let newSource = state.calendarResources.filter((r) => r.id !== params.id);
      commit(SET_CALENDAR_RESOURCES, newSource);

      commit(
        SET_SELECTED_CALENDAR_RESOURCES,
        state.selectedResources.filter((r) => r !== params.id)
      );
      const newEvents = state.calendarEvents.filter(
        (e) => e.calendar_id !== params.id
      );
      commit(SET_CALENDAR_EVENTS, newEvents);
      Message.success("Delete calendar successfully.");

      params.id = params.localId || params.id
      delete params.localId

      api.delete_calendar(params);
    } catch (error) {
      console.log(error);
    } finally {
      commit(LOADING_CALENDAR, false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
