<template>
  <Modal v-model="showSearchModal"
    class="dialog advance_dialog"
    :mask-closable="false"
    :ok-text="'Search'"
    @on-ok="beforeSubmit"
    width="770"
  >
    <div class="dialog-header" slot="header">
      <h2>{{modalTitle}}</h2>
    </div>

    <div class="dialog-contain">
      <RuleCriteria
        v-if="showSearchModal"
        ref="ruleCriteriaEl"
        descText="Search through"
        :criteria="criteria"
        :definedRules="JSON.parse(JSON.stringify(advancedSearchRules))"
        :initialRules="isEdit ? [] : defaultRules"
      />
    </div>
  </Modal>
</template>

<script type="text/ecmascript-6">
import {mapState, mapActions, mapGetters} from 'vuex'
import { advancedSearchRules } from '@/common/constants/rules.constant';
import RuleCriteria from '@/pages/components/ruleCriteria.vue';

  export default{
    props: {},
    data() {
      return {
        showSearchModal: false,
        searchLoading: false,
        modalTitle: 'Advanced Search',
        criteria: undefined,
        isEdit: false,
        advancedSearchRules: advancedSearchRules,
        defaultRules: [
          'lines', 
          'from',
          'to',
          'subject',
          'body',
          'received_at',
          'keyword',
          'has_comment', 
          'has_attachment',
          'high_importance'
        ]
      }
    },
    methods: {
      ...mapActions([
        'saveMailAdvance'
      ]),
      show(){
        Object.assign(this.$data, this.$options.data());
        this.criteria = {
          search_in_enable: !!this.$route.query.search_key || this.$route.query.folder_id || this.$route.query.line_id,
          mail_scope: 'both',
          match_type: 'every',
          line_ids: [],
          items: []
        }
        if(this.$route.query.search === "advance") {
          this.editInit();
          this.isEdit = true;
        }
        this.showSearchModal = true;
      },
      editInit() {
        this.modalTitle = 'Edit advanced search';
        this.criteria = {
          search_in_enable: this.mailAdvance.search_in_enable,
          line_ids: this.mailAdvance.line_ids.length === 0 ? [] : this.mailAdvance.line_ids.split(',').map(item => parseInt(item)),
          match_type: this.mailAdvance.match_type,
          mail_scope: this.mailAdvance.mail_scope,
          items: JSON.parse(this.mailAdvance.rules)
        }
      },
      async beforeSubmit() {
        const criteria = await this.validate()
        if(!criteria) return
        
        this.criteria = criteria;

        this.saveMailAdvance({
          rules: this.criteria.items,
          line_ids: this.criteria.line_ids,
          match_type: this.criteria.match_type,
          mail_scope: this.criteria.mail_scope,
          search_in_enable: this.criteria.search_in_enable
        });

        const keyword = criteria.items.find(i => i.field === 'keyword')
        const searchQuery = { 
          search_key: keyword && keyword.content ? keyword.content : undefined,
          search: 'advance',
          edit: this.isEdit,
          random: Math.random()
        }
        const url = {
          path: this.criteria.search_in_enable ? this.$route.path : 'mails',
          query: this.criteria.search_in_enable ? {...this.$route.query, ...searchQuery} : searchQuery
        }
        this.$router.push(url);

        this.showSearchModal = false;
      },
      async validate() {
        if(!this.$refs.ruleCriteriaEl) return false

        const criteria = await this.$refs.ruleCriteriaEl.getRuleCriteria()
        if(!criteria) return false

        return criteria
      }
    },
    computed: {
      ...mapGetters([
        'mailAdvance'
      ]),
      ...mapState([
        'lines'
      ])
    },
    components: {
      RuleCriteria
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .dialog {
    .dialog-contain {
      margin-top: 0;
      padding: 0;
      min-height: 120px;
    }
  }
</style>
