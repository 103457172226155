var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toastr-wrapper",class:[{ stacked: !_vm.isShowAll }]},[_vm._l((_vm.listNotifications),function(notification,index){return _c('Alert',{key:index,style:({
      '--top-alert': `${
        8 + Math.min(_vm.listNotifications.length - index - 1, 2) * 10
      }px`,
      '--scale-alert': `${
        1 - Math.min(_vm.listNotifications.length - index - 1, 2) * 0.1
      }`,
    }),attrs:{"type":"info","content":_vm.sanitizeHTML(notification.content),"time":notification.time,"start":notification.start,"end":notification.end},on:{"close":function($event){return _vm.closeNotification(notification.id)},"select":function($event){return _vm.selectNotifDetails(notification)}}})}),_c('div',{staticClass:"expand-action"},[(_vm.listNotifications.length > 0)?_c('div',{staticClass:"clear-btn",on:{"click":_vm.onClear}},[_vm._v(" Clear All ")]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }