<template>
  <Modal v-model="confirmShow"
         title="Tag Selected Emails"
         width="410"
         class="dialog"
         :footer-hide="true"
         @on-cancel="close"
  >
    <!--loading-->
    <div class="loading" v-show="detailLoading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="container">
      <div class="title_info">Type in one or more tags in the box below to add tags to the selected emails.</div>
      <div class="example_text">e.g. #tag1 #tag2 #tag3</div>
      <div class="input_wrapper">
        <InputTag 
          :tags="tags"
          :canAddNew="!definedTagsEnabled || p_tags"
          :getSuggestList="getTags"
          validate="tag"
          class="input-tags"
          ref="inputTag"
        />
      </div>
    </div>
    <div class="footer">
      <Button @click="close">Cancel</Button>
      <Button type="primary" @click="updateMail">Tag now</Button>
    </div>
  </Modal>
</template>

<script type="text/ecmascript-6">
import api from "api";
import InputTag from '@/pages/components/InputTag.vue';
import { mapGetters, mapState } from 'vuex';
import { of } from 'rxjs';

export default {
  props: {
    checkGroup: {
      type: Array
    }
  },
  data() {
    return {
      confirmShow: false,
      detailLoading: false,
      tags: []
    };
  },
  computed: {
    ...mapGetters(['setting_company', 'userInfo']),
    ...mapState(['configuredTags']),
    isAdmin() {
      return this.userInfo.user.user_type === "admin";
    },
    p_tags() {
      return this.isAdmin || this.checkPermission("add_edit_delete_configured_tags");
    },
    definedTagsEnabled() {
      return this.setting_company.limit_hashtag == '1';
    }
  },
  methods: {
    show() {
      this.confirmShow = true;
      this.$nextTick(_ => {
        this.$refs.inputTag && this.$refs.inputTag.focusNewTag();
      })
    },
    close() {
      this.confirmShow = false;
      this.tags = [];
      this.$refs.inputTag && this.$refs.inputTag.clearText();
      this.$refs.inputTag && this.$refs.inputTag.resetSearch();
    },
    updateMail() {
      if (this.tags.length === 0) {
        this.$Message.error("You have to type in one or more tags in the box.");
        this.$refs.inputTag && this.$refs.inputTag.focusNewTag();
        return;
      }
      let params = {
        tagable_type: "Email",
        tagable_ids: JSON.parse(JSON.stringify(this.checkGroup)),
        names: JSON.parse(JSON.stringify(this.tags))
      };
      this.confirmShow = false;
      this.$emit("massUpdateTags", this.tags);
      this.tags = [];
      api
        .post_tags(params)
        .catch(error => {
          console.log(error);
        });
    },
    getTags(name) {
      if(this.setting_company.limit_hashtag == '1') {
        const filteredList = this.configuredTags.filter(t => !!t.includes(name));
        return of(filteredList);
      }
      return api.suggest_tags({
        tagable_type: "Email",
        key: name
      });
    }
  },
  components: {
    InputTag
  },
  filters: {}
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.container {
  .title_info {
    font-size: 14px;
    color: #828d9d;
  }
  .example_text {
    font-size: 14px;
    color: #828d9d;
    margin-top: 14px;
  }
  .input_wrapper {
    margin-top: 21px;
  }
  .input-tags {
    min-height: 40px;
    line-height: 40px;
  }
}
.footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 8px;
  }
}
</style>
