import { Modal } from "iview";
import DOMPurify from "dompurify";

class SanitizedModal {
  constructor() {
    this.modalInstance = Modal;
  }

  sanitizeContent(config) {
    if (typeof config === "string") {
      return DOMPurify.sanitize(config);
    } else if (typeof config === "object" && config.content) {
      return { ...config, content: DOMPurify.sanitize(config.content) };
    }
    return config;
  }

  info(config) {
    this.modalInstance.info(this.sanitizeContent(config));
  }

  success(config) {
    this.modalInstance.success(this.sanitizeContent(config));
  }

  warning(config) {
    this.modalInstance.warning(this.sanitizeContent(config));
  }

  error(config) {
    this.modalInstance.error(this.sanitizeContent(config));
  }

  confirm(config) {
    this.modalInstance.confirm(this.sanitizeContent(config));
  }

  remove() {
    this.modalInstance.remove();
  }
}

export const sanitizedModal = new SanitizedModal();
