import appConstant from '@/common/constants/app.constant';
export default class FolderHelper {
  static buildFullpath(folders) {
    if (!folders || folders.length == 0) return '';
    let fullpath = '';
    let indicator = '';
    for (let i = 0; i < folders.length; i++) {
      indicator = (i === folders.length - 1) ? '' : ' > ';
      fullpath += folders[i].name + indicator;
    }
    return fullpath;
  }
  
  static findRootFolderName(personal) {
    return personal ? appConstant.directory.MY_WORKSPACE : appConstant.directory.SHARED;
  }

  static updateFolderChildren(folderId, children, list) {
    let existedFolders = FolderHelper.findFolders(folderId, list);
    existedFolders && existedFolders.forEach(folder => {
      folder && (folder.children = children)
    })
  }
  
  static findFolders(folderId, list) {
    if(!folderId)
      return []
      
    let results = [];
    find(folderId, list);
    return results;

    function find(folderId, list) {
      for(let i = 0; i < list.length; i++) {
        let folder = list[i];
        if(folder && folder.id == folderId) {
          results.push(folder);
          break;
        }
        
        if(folder && folder.children && folder.children.length > 0)
          find(folderId, folder.children);
      }
    }
  }

  static findFolderFromPath(paths, folders) {
    let folder;
    paths.forEach((path, index) => {
      folder = index === 0
        ? folders[path]
        : folder && folder.children[path] || undefined;
    })
    return folder;
  }
  
  static buildChildObject(parentId, childFolders) {
    let obj = {};
    obj[parentId] = childFolders;
    return obj;
  }

  static moveFolder(folder, fromList, toList, indexInToList) {
    const fromIndex = fromList.findIndex(f => f.id == folder.id);
    const toIndex = toList.findIndex(f => f.id == folder.id);
    fromIndex != -1 && fromList.splice(fromIndex, 1);
    toIndex != -1 && toList.splice(toIndex, 1);
    toList.splice(indexInToList || 0, 0, folder);
  }

  static sortFolders(folders) {
    folders.sort((f1, f2) => {
      return f1.name.toLowerCase() == f2.name.toLowerCase() ? 0 
        : f1.name.toLowerCase() < f2.name.toLowerCase() ? -1 
        : 1;
    });
  }
}