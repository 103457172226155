// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from "./App";
import VueRouter from 'vue-router';
import routes from './router/index'
import store from './vuex';
import VueRx from 'vue-rx'
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import '../my-theme/index.less';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import locale from 'iview/src/locale/lang/en-US';
import VueLazyload from 'vue-lazyload'
import util from 'util'
import utils from '@/utils'
import isFunction from 'lodash/isFunction';
import Cookies from 'js-cookie';
import storageConstant from '@/common/constants/local-storage.constant';

// simple websocket encapsulation
import ActionCable from 'actioncable'

import 'assets/iconfont/iconfont'; // iconfont 具体图标见iconfont
import IconSvg from 'components/Icon-svg';// iconfont svg 组件
import SVGSprite from "components/SVGSprite.vue";
import Footer from 'components/footer.vue';
import './utils/dateFilter';
require('font-awesome/css/font-awesome.css')
import { globalMixin } from "./mixins";
import {emailNotificationCache} from '@/vuex/modules/mails/mail';
import "./registerServiceWorker";

import 'vue2-datepicker/index.css';
import { sanitizedModal } from "@/utils/MTIModal"
const Cpackage = require("../package.json");
import * as Sentry from "@sentry/vue";

util.reSetAuth();

const router = new VueRouter({
  // mode: 'history',
  routes
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://0ec3dfb876424186bbc23e70fb6866b1@sentry.io/1368268",
    ignoreErrors: [/429/],
    release: Cpackage.version,
    autoSessionTracking: false,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    beforeSend(event, hint) {
      const error = hint.originalException || {};
      if (error.config?.url?.includes('/online_check')) return null;

      return event;
    }
  });
}

window.navigator && window.navigator.registerProtocolHandler && window.navigator.registerProtocolHandler("mailto", location.origin + "/#/sendMail?mailto=%s", "MarineTrafficInbox");

Vue.component('icon-svg', IconSvg);
Vue.component('svg-sprite', SVGSprite);
Vue.component('chartdesk-footer', Footer);

Vue.use(iView, {locale});
Vue.use(VueRouter);
Vue.use(VueRx);

const avatar = util.randomAvatar();

Vue.use(VueLazyload, {
  preLoad: 1,
  error: avatar,
  loading: avatar,
  attempt: 1,
});

Vue.mixin(globalMixin);
Vue.config.productionTip = false;
export const Toastr = {
  dispatch: (type, value) => {
    window.dispatchEvent(new CustomEvent("configToastr", { detail: {type, value},  }))
  }
};

Vue.prototype.$Toastr = Toastr
Vue.prototype.$Modal = sanitizedModal;
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err)
      return err
    }
    console.log
    // rethrow error
    return Promise.reject(err)
  })
}

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      console.warn(err)
      return err
    }
    console.log
    // rethrow error
    return Promise.reject(err)
  })
}


let WEBSOCKET_ADDRESS;

router.beforeEach((to, from, next) => {
  let token = util.getToken();
  if (to.path === '/login') {
    if (!token && !location.href.includes('login')) {
      console.log({beforeTK: token, path: location.href});
      Sentry.captureMessage("Auth Error");
    }
    localStorage.removeItem(storageConstant.TOKEN);
    localStorage.removeItem(storageConstant.MFA_CODE);
    localStorage.removeItem(storageConstant.TWO_FA_ENABLED);
    Cookies.remove(storageConstant.TOKEN)
    Cookies.remove(storageConstant.MFA_CODE)
    store.dispatch("resetUserData");
    if (Vue.prototype.$ActionCable) {
      Vue.prototype.$ActionCable.subscriptions.subscriptions.splice(0, 1);
    }
    next()
  } else if (typeof(token) !== 'string') {
    // public link for contact
    if (to.path === '/shares'
      || to.path === '/resetPassword'
      || to.path === '/loginSSO'
      || to.path === '/completeInvitation'
      || to.path.includes('/two-factor-verification'))
    {
      next();
    } else {
      next({path: '/login'})
    }
  } else if(to.path == '/sendMail') {
    next()
  } else {
    //webSocket
    handleBaseAddress();
    const tenantID = util.getTenantID();
    let address = tenantID ? util.appendTenantID(WEBSOCKET_ADDRESS, tenantID, true) : WEBSOCKET_ADDRESS;
    if (!Vue.prototype.$ActionCable || !Vue.prototype.$ActionCable.url.match(token) || (tenantID && !Vue.prototype.$ActionCable.url.match(tenantID))) {
      let url = `${address}?Auth-Token=${token}`;
      tenantID && (url += `&Tenant-Id=${tenantID}`)
      Vue.prototype.$ActionCable = ActionCable.createConsumer(url)
    }
    if (Vue.prototype.$ActionCable.subscriptions.subscriptions.length === 0) {
      const $events = Vue.prototype.$ActionCable.connection.events
      const sub = Vue.prototype.$ActionCable.subscriptions.create({
          channel: 'NotificationsChannel'
        }, {
          connected: function () {
            console.log('connected: ', new Date());
            sub && sub.perform("get_server_datetime");
            if (!isFunction(this.connected.mail)) return
            this.connected.mail()
          },
          received: function (data) {
            switch (data.type) {
              case "ServerDateTime":
                configureServerDateTime(data.message);
                break;
              case "CopyFolder":
                store.dispatch("loadChildFolders", {
                  parentId: data.message.parent_id,
                  category: data.message.personal ? "personal" : "share",
                  copy_folder_name: data.message.name,
                  copy_done: true,
                  no_cache: true
                });
                break;
              case "Email":
                if (!isFunction(this.received.mail)) return;
                data.message.forEach((item) => {
                  emailNotificationCache.put(item.id, structuredClone(item));
                  this.received.mail(item);
                  util.sendMessageToSw({
                    message: "saveEmail",
                    item,
                  });
                });
                break;
              case "MarkAsRead":
                if (!isFunction(this.received.markAsRead)) return;
                const ids = data.message[0] ? data.message[0].ids : []
                this.received.markAsRead(ids);
                break;
              case "Tag":
                if (!isFunction(this.received.tag)) return;
                this.received.tag(data);
                break;
              case "Notification":
              case "Permission":
                if (!isFunction(this.received.header)) return;
                this.received.header(data);
                break;
              case "Comment":
                if (!isFunction(this.received.comment)) return;
                this.received.comment(data);
                break;
              case "LineStatus":
                if (!isFunction(this.received.lineStatus)) return;
                data.message && this.received.lineStatus(data.message);
                break;
              case "UpdateUnreadCount":
                if (!isFunction(this.received.unreadCount)) return;
                this.received.unreadCount(data.message);
                break;
              case "Assignment":
                if (!isFunction(this.received.assignment)) return;
                data.message && this.received.assignment(data.message);
                break;
              case "UpdateCloseStatus":
                if (!isFunction(this.received.mailClosed)) return;
                data.message &&
                  data.message.length > 0 &&
                  data.message.map((item) => this.received.mailClosed(item));
                break;
              case "EmailFolder":
                if (!isFunction(this.received.emailFolder)) return;
                data.message && this.received.emailFolder(data.message);
                break;
              case "CompanyTag":
                if (!isFunction(this.received.companyTag)) return;
                data.message && this.received.companyTag(data.message);
                break;
              case "UpdateRoleDisableMention":
                if(!data.message) return;
                store.commit("SET_ROLE_DISABLE_MENTION", data.message)
                break;
              case "CalendarEvents":
                if(!data.message) return;
                store.dispatch("setCalendarEvents", data.message)
                break;
              case "ForceLogout":
                if (!isFunction(this.received.forceLogout) || !data.message)
                  return
                this.received.forceLogout(data.message)
                break;
              case "TrigonalEmailDraft":
                if (!isFunction(this.received.trigonalEmailDraft) || !data.message)
                  return
                this.received.trigonalEmailDraft(data.message)
                break;
              case "TrigonalFilingSuccess":
                if (!isFunction(this.received.trigonalFilingSuccess) || !data.message)
                  return
                this.received.trigonalFilingSuccess(data.message)
                break
              case "TrigonalPreviewEmail":
                if (!isFunction(this.received.trigonalPreviewEmail) || !data.message)
                  return
                this.received.trigonalPreviewEmail(data.message)
                break
              case "EmailStatusUpdate":
                if (!isFunction(this.received.emailStatusUpdate) || !data.message)
                  return
                this.received.emailStatusUpdate(data.message)
                break
              case "Folders":
                if (!isFunction(this.received.userFolderUpdate) || !data.message)
                  return
                this.received.userFolderUpdate(data.message)
                break
              default:
                break;
            }
          },
          disconnected: function () {
            console.log('GG: ', new Date());
            if (!isFunction(this.disconnected.mail)) return
            this.disconnected.mail()
          }
        },
      );
    }
    next()
  }
});

util.getDataFromSw("online", (data) => {
  store.commit("UPDATE_ONLINE_STATUS", data);
});

router.afterEach((to, from) => {
  updateDocumentTitle(to);
})

function updateDocumentTitle(to) {
  const { title } = to.meta;
  const documentTitle = typeof title === 'function' ? title(to) : title;
  document.title = documentTitle || 'MarineTraffic Inbox';
}

function configureServerDateTime(timeStp) {
  window.timeGap = +new Date(timeStp) - Date.now();
  localStorage.setItem("timeGap", JSON.stringify(window.timeGap));
}

window.timeGap = JSON.parse(localStorage.getItem("timeGap"));

function handleBaseAddress() {
  const savedBaseUrl = localStorage.getItem(storageConstant.BASE_URL) || ''

  if(utils.validateBaseUrl()) {
    WEBSOCKET_ADDRESS = `wss://${savedBaseUrl}/cable`;
  } else {
    const defaultUrl = utils.getDefaultBaseURL()
    WEBSOCKET_ADDRESS = `wss://${defaultUrl}/cable`;
  }
}

util.registerGoogleAnaytic();

new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: {App},
});
