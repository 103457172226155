<template>
  <resizable-block 
    class="nav-wrapper" 
    type="sidebarWidth" 
    :resizeWidth="true" 
    :defaultWidth="250"
  >
    <div class="nav">
      <div class="ctaButton">
        <slot name="ctaButton"></slot>
      </div>
      <div class="scrollable" ref="scrollableEl">
        <slot name="main" />
      </div>
    </div>
    <slot name="showHidden"></slot>
  </resizable-block>
</template>
<script>
import ResizableBlock from "./ResizableBlock.vue";

export default {
  components: {
    ResizableBlock
  }
};
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;

  .charkDesk {
    width: 100%;
    height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    i {
      font-size: 24px;
      color: var(--primary-color);
    }
  }
  .header_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 150px;
    height: 23px;
    .ch-menu-item {
      border-radius: 0;
      font-weight: normal;
      color: var(--on-side-menu-color);
      display: flex;
      &:first-child {
        margin-left: 0;
      }
      .iconfont,
      [class^="iconfont-"] {
        vertical-align: middle;
        font-size: 20px;
        transform: translateX(-20%);
      }
      .iconfont-ship {
        font-size: 18px;
      }
      &.router-link-active {
        text-decoration: none;
        color: var(--primary-color);
        font-weight: bold;

        .iconfont,
        [class^="iconfont-"] {
          color: var(--primary-color);
        }
      }
    }
    i {
      font-size: 20px;
    }
  }
}
.nav-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  max-width: 650px;
  background: var(--background-color);
  color: var(--on-side-menu-color);
  border-top: 1px solid var(--border-color);
  position: relative;
  .folder > div {
    overflow-x: auto;
  }
  .expand_but {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
.nav {
  width: 100%;
  position: relative;
  flex: 1;
  min-height: 0;
  padding: 20px 0px 10px 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .menu {
    width: 100%;
  }
  .scrollable {
    overflow: auto;
    overflow: overlay;
    flex: 1;
    min-height: 0;
    padding-right: 10px;
    padding-bottom: 30px;
  }
}
</style>
