import appConstant from '@/common/constants/app.constant';
export default class CommentHelper {
  static extractMentions(comment) {
    const { content } = comment;
    const userRgx = new RegExp(/@\[(.*?)\]\((\d+)\)(\{+user+\})/g);
    const teamRgx = new RegExp(/@\[(.*?)\]\((\d+)\)(\{+team+\})/g);
    const matches = content.match(userRgx) || content.match(teamRgx);
    if(!matches || matches.length == 0)
      return [];

    let mentions = [];
    matches.forEach(group => {
      let items = userRgx.exec(group) || teamRgx.exec(group);
      items 
        && items[1] 
        && items[2] 
        && items[3] 
        && mentions.push({
          created_at: comment.created_at,
          noticeable_id: comment.id,
          noticeable_type: "Comment",
          to_id: parseInt(items[2]),
          name: items[1],
          type: items[3].replace('{', '').replace('}', '')
        })
    })
    return mentions;
  }

  static parseMentions(mentions, teamMembers) {
    if(!mentions || mentions.length == 0)
      return [];
    mentions = mentions.filter(m => m.noticeable_type !== appConstant.noticeableType.ASSIGNMENT);
    return mentions.map(mention => {
      const user = teamMembers.find(m => m.id == mention.to_id);
      return user ? { ...mention, avatar: user.avatar, name: user.name } : mention;
    })
  }

  static extractTags(comment) {
    const { content } = comment;
    const tagRgx = new RegExp(/#\w+/g);
    const matches = content.match(tagRgx);
    if(!matches || matches.length == 0)
      return [];

    let tags = [];
    matches.forEach(item => {
      item && tags.push(item.substr(1));
    })
    return tags;
  }

  static html2format(html) {
    if(!html)
      return ''
      
    //含有team icon  @line
    let ans = html.replace(
      /<strong class=\"department\" data-uname=\"(.*?)\" data-userid=\"(\d+)\">@(.*?)<\/strong>/g,
      "@[$1]($2){team}"
    );
    //常规@ user
    ans = ans.replace(
      /<strong class=\"user\" data-userid=\"(\d+)\">@(.*?)<\/strong>/g,
      "@[$2]($1){user}"
    );
    // convert #
    ans = ans.replace(/<strong class=\"tag\">#(.*?)<\/strong>/g, "#[$1]");
    ans = ans.replace(/<[^>]+>/gi, "");
    // ans = ans.replace("&nbsp;", " ");
    ans = ans.split("&nbsp;").join("");
    return ans;
  }

  static format2html(format) {
    if(!format)
      return ''

    // {team} icon  @line
    let ans = format.replace(
      /@\[([^@\[\]\(\)\{\}]*)\](\(\d+\))(\{team\})/g,
      '<strong class="department" data-userid="$2">@$1<span style="display: inline-block;color: #fff; font-size:12px;transform:scale(0.8);font-weight:bolder;background: #66B1CB;border-radius: 2px;line-height:20px;"">&nbsp&nbspTEAM&nbsp&nbsp</span></strong>'
    );
    // @user{user}
    ans = ans.replace(
      /@\[([^@\[\]\(\)\{\}]*)\](\(\d+\))(\{user\})/g,
      '<strong class="user" data-userid="$2">@$1</strong>'
    );
    //匹配旧评论 没有{}
    ans = ans.replace(
      /@\[(.*?)\]\((\d+)\)/g,
      '<strong class="user" data-userid="$2">@$1</strong>'
    );
    // convert #
    ans = ans.replace(/#\[(.*?)\]/g, '<strong class="tag">#$1</strong>');
    return ans;
  }
}