<template>
  <Modal
    :value="inappAttachmentPreview.isPreviewing"
    class="dialog vertical-center-dialog mail-detail-attachment-preview"
    footer-hide
    header-hide
    :closable="false"
    :width="95"
    ref="attachmentPreview"
    @on-cancel="handleCloseInappAttachmentPreview">
    <template slot="header">
      <div class="attachment-preview-header">
        <span class="attachment-preview-header-text">{{ modalTitle }}</span>
        <div
          v-if="inappAttachmentPreview.mailDetailPreview"
          class="btn actionable-icon"
          :title="inappAttachmentPreview.maximized ? 'Minimize' : 'Maximize'"
          @click="toggleExpandedAttachmentPreview"
        >
          <svg-sprite
            :name="inappAttachmentPreview.maximized ? 'collapse' : 'expand'"
            :width="15"
            :height="15" />
        </div>
        <div
          class="btn actionable-icon"
          title="In new window"
          @click="previewInNewWindow"
        >
          <svg-sprite name="mt_new_window" :width="14" :height="14" />
        </div>
        <div
          class="btn actionable-icon"
          title="Close"
          @click="closeAttachmentPreviewModal"
        >
          <svg-sprite name="mt_close" :width="20" :height="20" />
        </div>
      </div>
    </template>
    <div class="attachment-preview-wrapper">
      <AttachmentPreviewTab
        :key="inappAttachmentPreview.key"
        :attachment="inappAttachmentPreview.attachment"
        v-if="inappAttachmentPreview.isPreviewing && inappAttachmentPreview.attachment" />
      <MailDetail
        v-if="inappAttachmentPreview.mailDetailPreview"
        :selectedEmail="mailDetailState.content"
        :previewEmailId="mailDetailState.content.id"
        :highlights="[]"
        :singleClick="true"
        :inAttachmentPreviewModal="true"
        :style="{ width: inappAttachmentPreview.maximized ? '0' : '620px', height: '100%!important;' }" />
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import util from "util";
import MailDetail from './mailDetail.vue';
import AttachmentPreviewTab from "@/pages/mails/detail/AttachmentPreviewTab.vue";

export default {
  computed: {
    ...mapGetters(["inappAttachmentPreview", "mailDetailState", "isOpeningInappWindow"]),
    modalTitle() {
      return this.inappAttachmentPreview.attachment ? this.inappAttachmentPreview.attachment.name : ''
    },
  },
  methods: {
    handleCloseInappAttachmentPreview() {
      this.$store.commit("UPDATE_INAPP_ATTACHMENT_PREVIEW", {isPreviewing: false, attachment: null, mailDetailPreview: false, maximized: false});
    },
    toggleExpandedAttachmentPreview() {
      this.$store.commit("UPDATE_INAPP_ATTACHMENT_PREVIEW", {maximized: !this.inappAttachmentPreview.maximized})
    },
    closeAttachmentPreviewModal() {
      if (!this.$refs.attachmentPreview) return;

      this.$refs.attachmentPreview.close()
      this.$store.commit("UPDATE_INAPP_ATTACHMENT_PREVIEW", {isPreviewing: false, attachment: null, mailDetailPreview: false, maximized: false});
    },
    previewInNewWindow() {
      const {external_preview_file_url, name} = this.inappAttachmentPreview.attachment
      // util.openWindow(external_preview_file_url, true, name);
      window.open(external_preview_file_url)
      this.closeAttachmentPreviewModal();
    },
  },
  components: {
    MailDetail,
    AttachmentPreviewTab
  }
}
</script>

<style lang="scss">
.mail-detail-attachment-preview {
  .attachment-preview-wrapper {
    width: 100%;
    height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    display: flex;
    border-top: 1px solid var(--border-color);
  }
  .attachment-preview-header {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 20px;
    gap: 10px;
    &-text {
      flex: 1;
      min-width: 0;
      min-height: 0;
      font-size: 12px;
      line-height: 18px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 30px;
        color: var(--text-color);
      }
    }
  }
  .ivu-modal-content {
    overflow: hidden;
  }
  .ivu-modal-body, .ivu-modal-header {
    padding: 0;
  }
}
</style>
