<template>
    <div class="popover" v-bind:class="{ open: isOpen, [name]: true }">
        <div class="popover__face" :aria-owns="id" v-on:click="onPopoverToggle">
            <slot name="face" >
                <a href="#">popover</a>
            </slot>
        </div>
        <div class="popover__container" :id="id" v-if="isOpen" v-on:click="onPopoverContentClick">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    props: {
        name: {
            type: String,
            required: false,
        },
        closeOnContentClick: {
            'default': true,
            type: Boolean,
            required: false
        }
    },
    data(){
        return {
            isOpen: false
        }
    },
    methods: {
        onPopoverToggle(e) {
            e.stopPropagation()
            if (this.isOpen){
                this.isOpen = false
                this.$emit('popover:close')
                return
            }
            this.isOpen = true
            document.documentElement.addEventListener('click', this.onDocumentClick, false)
            this.$emit('popover:open')
        },
        onDocumentClick(){
            this.isOpen = false
            this.$emit('popover:close')
        },
        onPopoverContentClick(e){
            e.stopPropagation()
            if (this.closeOnContentClick) {
                this.isOpen = false
                this.$emit('popover:close')
            }
        },
        removeDocumentEvent(){
            document.documentElement.removeEventListener('click', this.onDocumentClick, false)
        }
    },
    computed: {
        id(){
            return `popover-${this.name}`
        }
    },
    mounted(){
        this.$on('popover:close', this.removeDocumentEvent)
    },
    beforeDestroy(){
        this.removeDocumentEvent()
    }
}
</script>

<style lang="scss">
.popover {
    display: inline-block;
    user-select: none;
    cursor: pointer;
    position: relative;
    .popover__container {
        position: absolute;
        z-index: 5;
        right: 5px;
    }
}
</style>
