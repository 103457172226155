<template>
  <div class="alert-wrapper" :style="{ backgroundColor: item.bgColor }">
    <div class="alert-content" @click="$emit('select')">
      <svg-sprite fill="#fff" :name="item.icon" :width="22" :height="22" />
      <div class="content-main">
        <div v-if="startTime" class="calendar-title">
          CALENDAR
          <span v-if="beforeMinutes && beforeMinutes > 0">in {{ beforeMinutes }}m</span>
        </div>
        <div v-html="sanitizeTextHTML(content)"></div>
        <time v-if="startTime">
          Today at {{ startTime }}
          <br />
        </time>
        <time v-if="time">{{ convertTime }}</time>
      </div>
      <svg-sprite
        title="Close"
        class="alert-close-btn"
        @click.native.stop="$emit('close')"
        fill="#fff"
        name="mt_close"
        :width="20"
        :height="20" />
    </div>
  </div>
</template>
<script>
import appConstant from '@/common/constants/app.constant';
import util from 'util';

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    start: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      convertTime: '',
      beforeMinutes: 0
    }
  },
  computed: {
    item() {
      switch (this.type) {
        case appConstant.alertType.INFO:
          return {
            bgColor: 'var(--alert-info)',
            icon: 'mt_info'
          }
        case appConstant.alertType.ERROR:
          return {
            bgColor: 'var(--alert-error)',
            icon: 'mt_error'
          }
        case appConstant.alertType.WARNING:
          return {
            bgColor: 'var(--alert-warning)',
            icon: 'mt_warning'
          }
        default:
          return {
            bgColor: 'var(--alert-info)',
            icon: 'mt_info'
          }
      }
    },
    startTime() {
      if (!this.start) return '';

      return util.extractTime(this.start)
    },
  },
  created() {
    this.updateNow();
  },
  methods: {
    updateNow() {
      this.convertTime = util.relativeTime(this.time);
      this.beforeMinutes = this.beforeMinutes = Math.ceil((+new Date(this.start) - +new Date()) / 60000)
      setTimeout(() => {
        this.updateNow();
      }, 60*1000);
    }
  }
}
</script>

<style scoped lang="scss">
.alert-wrapper {
  width: 320px;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: var(--box-shadow-main);
}

.alert-content {
  padding: 8px 0;
  display: flex;
  gap: 12px;
  svg {
    flex: none;
  }
  .content-main {
    flex: 1;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding-right: 20px;
    word-break: break-word;

    .calendar-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 12px;
      }
    }

    time {
      margin-top: 8px;
      font-size: 12px;
      line-height: 1.58;
      text-align: left;
      color: rgb(130, 141, 157);
      color: #fff;
    }
  }

  .alert-close-btn {
    padding: 5px;
    box-sizing: content-box;
    position: relative;
    top: -5px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
}
</style>

<style lang="scss">
.alert-content .content-main {
  a {
    color: #fff;
    text-decoration: underline;
  }
}
</style>
