<template>
  <Row>
    <Row class="row group-border">
      <Col :span="rowName" class="row-name">
        <!-- <svg-sprite
          :width="20"
          :height="19"
          name="mt_single_contact"
        /> -->
        &nbsp;Group/s
      </Col>
      <Col :span="rowData" class="row-data" />
    </Row>
    <!--Groups-->
    <Row
      class="row"
      v-if="sharedAddressGroups && sharedAddressGroups.length > 0"
    >
      <Col :span="rowName" class="row-name">Shared Group/s</Col>
      <Col :span="rowData" class="row-data group-row">
        <Tag
          v-for="address in sharedAddressGroups"
          :closable="canEdit"
          :key="address.id"
          @on-close="() => handleRemoveFromGroup('shared', address)"
          class="group-item"
        >
          {{ address.name }}
        </Tag>
      </Col>
    </Row>
    <Row
      class="row"
      v-if="privateAddressGroups && privateAddressGroups.length > 0"
    >
      <Col :span="rowName" class="row-name">My Group/s</Col>
      <Col :span="rowData" class="row-data group-row">
        <Tag
          v-for="address in privateAddressGroups"
          :closable="canEdit"
          :key="address.id"
          @on-close="() => handleRemoveFromGroup('personal', address)"
          class="group-item"
        >
          {{ address.name }}
        </Tag>
      </Col>
    </Row>
  </Row>
</template>
<script>
import api from "api";

export default {
  data() {
    return {
      addressGroups: [],
    };
  },
  props: {
    contactId: {
      type: Number,
    },
    rowName: {
      type: Number,
      default: 8,
    },
    rowData: {
      type: Number,
      default: 16,
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sharedAddressGroups() {
      return this.addressGroups.filter((a) => !a.personal);
    },
    privateAddressGroups() {
      return this.addressGroups.filter((a) => a.personal);
    },
  },
  methods: {
    async getAddressGroups(id) {
      this.addressGroups = await api.get_address_groups(id);
    },
    async handleRemoveFromGroup(type, group) {
      if (!type || !group) return;

      this.$Modal.confirm({
        title: 'Confirmation',
        content: `<p style="margin-left:-42px">Are you sure to remove this contact from <b>${group.name}</b>?</p>`,
        onOk: async () => {
          this.addressGroups = this.addressGroups.filter((a) => a.id !== group.id);

          api.delete_contact_from_group({
            address_book_ids: [this.contactId],
            address_group_id: group.id,
          }).then((res) => {
            this.$Message.success(`Success`)
          })
        }
      });
    },
  },
  watch: {
    contactId: {
      immediate: true,
      handler(id) {
        id && this.getAddressGroups(id);
      },
    },
  },
};
</script>
