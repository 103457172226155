<template>
  <div class="inapp-compose">
    <div v-show="inAppState.visible" @click.stop class="inapp-compose-wrapper">
      <SendMail ref="mailCompose" :inAppState="inAppState" />
    </div>
  </div>
</template>

<script>
const SendMail = () => import('./sendMail.vue');

export default {
  props: ["inAppState"],
  components: {
    SendMail,
  }
}
</script>
  
<style lang="scss">
.inapp-compose {
  z-index: 102;
}

.inapp-compose-wrapper {
  min-width: 670px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  z-index: 2;
}

.compose-header {
  width: 100%;
  height: 40px;
  background-color: var(--background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.inapp-compose-header-btn {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
  }

  .icon-edit {
    font-size: 10px;
    padding: 0;
  }
}</style>
