<template>
  <div class="mail-tabs"
    ref="mailTabEl"
    v-if="canShowTab"
    @contextmenu="onContextMenu"
  >
    <div class="content" ref="mailTabContentEl">
      <div
        v-for="(tab, index) in tabs" :key="index"
        class="tab"
        :class="[
          { 'active': currentTabName == tab.name },
          {'blink': blinkTabs && blinkTabs.includes(tab.name) && tab.name !== 'mail-activity' }]"
        :ref="`tab${tab.name}`"
        @click="onTabClick(tab)"
        @dragover="dragMailOver($event, tab)"
        @dragenter="dragMailEnter($event, tab)"
        @dragleave="dragMailLeave($event, tab)"
        @drop="dragMailOnDrop($event, tab)"
      >
        <div class="content">
          <template v-if="tab.icon">
            <span v-if="tab.icon.includes('iconfont')" :class="tab.icon" />
            <svg-sprite v-else-if="tab.name_type === tabTypes.LIVE_FEED"
              :name="tab.icon"
              class="live-feed-icon"
              :width="17"
              :height="14"
            />
            <svg-sprite v-else
              name="ic_view_folder"
              :class="tab.icon"
              :width="18"
              :height="16"
              viewBox="0 0 22 18"
            />
          </template>
          <span :title="tab.label">{{ tab.label | truncateText(20) }}</span>
          <span
            v-if="warningTabs && warningTabs.findIndex(name => name == tab.name) > -1"
            class="icon-warning"
            title="Connection lost, the data may be out of date. Click to sync data."
          >
            !
          </span>
        </div>
        <Icon v-if="tab.closable" type="md-close" class="ic-remove-tab" @click.stop="onRemoveTab(tab)" />
      </div>
    </div>
    <ul class="tab-context-menu"
      v-if="showContextMenu"
      :style="{ left: menuPosition.left+'px', top: menuPosition.top+'px'}"
    >
      <li class="tab-context-menu-item" @click="closeAllTabs">Close all tabs</li>
    </ul>
  </div>
</template>

<script>
import Mails from './Mails.vue';
import appConstant from "@/common/constants/app.constant";
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import util from 'util';
import api from '../../fetch/api';
import { tabUtil } from '@/mixins';

export default {
  data() {
    return {
      currentTabName: '',
      showContextMenu: false,
      menuPosition: { top: 0, left: 0 },
      blinkTabs: [],
      tabTypes: appConstant.tabTypes,
      warningTabs: []
    }
  },
  mixins: [ tabUtil ],
  computed: {
    ...mapState(['mailDragged']),
    ...mapGetters(['setting_company', 'openingTabs', 'activeTab', 'liveFeeds', 'setting_mails', 'autoBookmark']),
    canShowTab() {
      return this.openingTabs.length > 0;
    },
    tabs() {
      const folderId = this.$route.query.folder_id
      const folderName = this.$route.query.folder_name
      const folderType = this.$route.query.type

      const liveFeed = this.liveFeeds.find(l => l.id == this.$route.query.live_feed_id)
      const liveFeedName = liveFeed ? liveFeed.name : ''
      
      const page = {
        name: this.pageName,
        label: folderName || liveFeedName || document.title,
        closable: false,
        name_type: appConstant.tabTypes.PAGE,
        data: folderId ? { id: folderId, name: folderName, folder_type: folderType } : {}
      }
      return [page].concat(this.openingTabs.filter(t => t.name != page.name))
    },
    currentTab() {
      return this.tabs.find(t => t.name == this.currentTabName);
    },
    pageName() {
      if(this.$route.query.folder_id)
        return appConstant.tabPrefixes.FOLDER + this.$route.query.folder_id;

      if(this.$route.query.live_feed_id)
        return appConstant.tabPrefixes.LIVE_FEED + this.$route.query.live_feed_id;

      if(!this.$route.query.line_id)
        return util.convertToKebabCase(document.title);

      const box = this.$route.query.box && this.$route.query.box.toLowerCase();
      const status = this.$route.query.status && this.$route.query.status.toLowerCase();
      const archived = this.$route.query.archived;
      let parentId = box && box.toLowerCase() || 'mails';
      parentId = box == 'sent' && status == 'pending' ? 'outbox'
          : status == 'sent' ? 'sent'
          : parentId;
      return `${appConstant.tabPrefixes.LINE}${this.$route.query.line_id}_${parentId}`;
    }
  },
  mounted() {
    this.handleRouteChanged();
    document.documentElement.addEventListener('click', this.closeContextMenu)
  },
  filters: {
    truncateText: (value, limit) => {
      if (!value || typeof(value) !== "string") return value;

      const arr = value.split("");
      if (arr.length <= limit) return value;

      arr.length = limit;
      return arr.join("") + "...";
    }
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('click', this.closeContextMenu)
  },
  methods: {
    ...mapMutations(['REMOVE_OPEN_TAB', 'RESET_OPEN_TABS', 'SET_LIVE_FEEDS']),
    ...mapActions(['removeBookmark']),
    onTabClick(tab) {
      this.removeIncomeAlert(tab);
      this.removeWarning(tab);
      if(!tab || this.currentTabName == tab.name)
        return;
      this.setActiveAndGotoTab(tab);
    },
    onRemoveTab(tab) {
      if(!tab || !tab.closable) return;
      api.remove_tab(tab.id)
      this.REMOVE_OPEN_TAB({ tabName: tab.name });
      this.removeIncomeAlert(tab);

      [this.bookmark].forEach(bookmark => {
        if (!bookmark) return;
        if ((bookmark.params.current_path || '').includes(tab.name)) {
          this.removeBookmark(bookmark);
        }
      })

      if(tab.name == this.currentTabName) {
        this.currentTabName = this.pageName;
        this.setActiveAndGotoTab(this.currentTab);
      }
    },
    handleRouteChanged() {
      const tabName = this.$route.query && this.$route.query.tab;
      this.currentTabName = tabName
        || this.openingTabs.length > 0 && this.pageName
        || null;
    },
    onContextMenu(e) {
      if(!this.openingTabs || this.openingTabs.length == 0)
        return;
      e.preventDefault();
      this.showContextMenu = true;
      this.menuPosition = {
        top: e.clientY,
        left: e.clientX
      }
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    closeAllTabs() {
      api.remove_all_tab()
      this.blinkTabs = this.blinkTabs.filter(tab => {
        return !this.openingTabs.some(t => t.name === tab.name)
      });
      if (this.openingTabs.some(t => t.name === this.currentTabName)) {
        this.currentTabName = 'mail-activity';
      }

      this.openingTabs.forEach(tab => {
        [this.bookmark, this.autoBookmark].forEach(bookmark => {
          if (!bookmark) return;
          if ((bookmark.params.current_path || '').includes(tab.name)) {
            this.removeBookmark(bookmark);
          }
        })
      })

      this.RESET_OPEN_TABS();
      this.setActiveAndGotoTab(this.currentTab);
    },
    handleIncomeAlert(tabName) {
      if (this.currentTabName === tabName) return;

      let tab = this.tabs.find(t => t.name == tabName)
      if(!tab) return;
      const existed = this.blinkTabs.findIndex(t => t == tab.name) > -1;
      !existed && this.blinkTabs.push(tab.name);
    },
    removeIncomeAlert(tab) {
      const index = this.blinkTabs.findIndex(t => t == tab.name)
      index > -1 && this.blinkTabs.splice(index, 1);
    },
    handleWarning() {
      if(this.tabs && this.tabs.length > 1) {
        const tabs = this.tabs.filter(t => t.name != this.currentTabName)
        this.warningTabs = tabs.map(t => t.name)
      }
    },
    removeWarning(tab) {
      const index = this.warningTabs.findIndex(t => t == tab.name)
      index > -1 && this.warningTabs.splice(index, 1);
    },
    /**
     * Drag and drop email
     */
    dragMailOver(e, tab) {
      if(!this.dragMailValidate(tab))
        return;
      e.preventDefault();
      this.$refs[`tab${tab.name}`][0].classList.add('drag-over');
    },
    dragMailEnter(e, tab) {
      if(!this.dragMailValidate(tab))
        return;
      this.$refs[`tab${tab.name}`][0].classList.add('drag-over');
    },
    dragMailOnDrop(e, tab) {
      if(!this.dragMailValidate(tab))
        return;
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      this.$refs[`tab${tab.name}`][0].classList.remove('drag-over');
      const emailCount = this.mailDragged.length;
      const param = {
        folder_id: tab.data.id,
        email_ids: [...this.mailDragged],
      };
      api.add_email_to_folder(param).then(_ => {
        this.$Message.success(`Added ${emailCount} email${emailCount > 1 ? 's' : ''} to ${tab.data.name} successfully.`);
        util.resetCachedEmails(`folder_id=${param.folder_id}`);
      })
      this.$emit('dragDone', this.mailDragged);
    },
    dragMailLeave(e, tab) {
      this.$refs[`tab${tab.name}`][0].classList.remove('drag-over');
    },
    dragMailValidate(tab) {
      if (this.mailDragged.length === 0)
        return false;
      if(tab.name_type != appConstant.tabTypes.FOLDER && !tab.data.folder_type)
        return false;
      if (tab.data.folder_type == appConstant.folderType.DYNAMIC)
        return false;
      if(!this.$refs[`tab${tab.name}`] || !this.$refs[`tab${tab.name}`][0])
        return false;
      return true;
    }
    /** End drag and drop */
  },
  watch: {
    activeTab: {
      immediate: true,
      handler(val) {
        val && this.onTabClick(val);
        if (!val)
          return;
        const tab = {...val, active: true, data: JSON.stringify(val.data)}
        tab.id && api.update_active_tab(tab)
      }
    },
    openingTabs: {
      immediate: true,
      handler(tabs) {
        tabs && tabs.length > 0 && this.$nextTick(_ => {
          util.horizontalScroll(this.$refs.mailTabContentEl);
        })
      }
    },
    '$route.query'() {
      this.handleRouteChanged()
    },
    "setting_mails": {
      immediate: true,
      handler(val) {
        let liveFeeds = [];

        if (val.live_feed) {
          liveFeeds = typeof val.live_feed === 'string' ? JSON.parse(val.live_feed) : val.live_feed;
        }

        this.SET_LIVE_FEEDS(liveFeeds);
      }
    }
  },
  components: {
    Mails
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.mail-tabs {
  display: flex;
  flex-direction: column;
  > .content {
    display: flex;
    align-items: center;
    padding: 4px 16px 0 16px;
    overflow-x: auto;
    overflow-x: overlay;
    overflow-y: hidden;
    background-color: var(--menu-color);
    gap: 4px;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 3px !important;
      height: 3px !important;
    }
    .tab {
      display: flex;
      align-items: center;
      font-size: 13px;
      position: relative;
      padding: 0 10px;
      border-radius: 4px;
      &+.tab {
        margin-left: 4px;
      }
      svg {
        fill: var(--label-color);
      }
      svg.live-feed-icon path{
        fill: #FF5A5A;
      }
      &:hover {
        color: var(--primary-color);
        cursor: pointer;
        .ic-remove-tab {
          display: block;
        }
        svg {
          fill: var(--primary-color);
        }
      }
      &.active {
        font-weight: bold;
        color: var(--blue-main);
        svg {
          fill: var(--primary-color);
        }

        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background-color: var(--blue-main);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      &.blink {
        background-color: #0097fb;
        color: white;
        animation-name: flash !important; /* IE 10+, Fx 29+ */
        animation-duration: 1s !important;
        animation-iteration-count: 3 !important;

        svg.live-feed-icon path{
          fill: currentColor;
        }
      }
      &.drag-over {
        background: var(--hover-color);
      }
      @keyframes flash {
        0%		{ background-color: rgba(0, 151, 251, 1);}
        50%		{ background-color: rgba(0, 151, 251, 0.5);}
        100%	{ background-color: rgba(0, 151, 251, 0.25);}
      }
      .content {
        display: flex;
        align-items: center;
        padding: 5px 0;
        white-space: nowrap;
        gap: 5px;

        [class^="iconfont-"], [class^="ic_"] {
          font-size: 18px;
        }
        .iconfont-inbox, .iconfont-archived, .iconfont-folder {
          font-size: 15px;
        }
        .icon-warning {
          color: #eb5a46;
          padding: 0 4px;
        }
        span {
          line-height: 19px;
        }
      }
      .ic-remove-tab {
        position: absolute;
        top: -4px;
        right: -5px;
        display: none;
        color: var(--text-color-disable);
        &:hover {
          color: var(--text-color);
          background-color: var(--text-color-disable);
          border-radius: 50%;
          opacity: 0.5;
        }
      }
    }
  }
  .tab-context-menu {
    position: fixed;
    background-color: var(--component-color);
    color: var(--on-component-color);
    list-style: none;
    z-index: 1;

    .tab-context-menu-item {
      padding: 8px 16px;
      font-size: 13px;
      border: 1px solid var(--border-color);
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
