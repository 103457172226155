<template>
  <footer class="ch-footer">
    <div class="left-container">
      <slot name="left-content" />
    </div>
    <div class="right-container">
      v {{version}}
    </div>
  </footer>
</template>

<script>
const Cpackage = require("../../package.json");

export default {
  name: 'chartdesk-footer',
  data() {
    return {
      version: Cpackage.version
    }
  }
}
</script>

<style lang="scss" scoped>
.ch-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  border-top: 1px solid var(--border-color);
  height: 30px;
  color: var(--text-secondary);
  background-color: var(--menu-color);

  .left-container {
    padding: 0 8px;
    font-size: 13px;
  }

  .right-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 8px;
  }
}
</style>

<style lang="scss">
footer {
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  letter-spacing: normal;
  p {
    text-align: right;
    margin-right: 4px;
    justify-content: flex-end;
    flex: 1;
  }
}
</style>
