export const ruleEngineMixin = {
  methods: {
    parseRulesInfo(rules, ruleTemplates) {
      if(!rules || rules.length == 0)
        return rules;
  
      return rules.map(rule => {
        if(!rule)
          return rule;
  
        const ruleTemplate = ruleTemplates.find(r => 
          r.field == rule.field 
          || (r.field == 'from' && ['to', 'from', 'bcc', 'cc'].includes(rule.field))
        );
        const strategyTemplate = ruleTemplate 
          && ruleTemplate.strategies 
          && ruleTemplate.strategies.find(s => s.value == rule.strategy);
        rule = { 
          ...ruleTemplate, 
          ...rule, 
          strategy: strategyTemplate ? strategyTemplate.label : rule.strategy 
        };
        switch(rule.field) {
          case 'assignment':
          case 'user_id':
            if(rule.content_type == 'range') {
              const userIds = rule.content && rule.content.split(',') || []
              const users = userIds.map(id => this.teamMembers.find(m => m.id == id))
              rule.content = users && users.map(u => u && u.name).join(', ') || ''
            }
            break;
          case 'received_at':
            if (rule.content_type == 'range') {
              let content = rule.content.split(",");
              rule.firstDate = new Date(content[0]);
              rule.lastDate = new Date(content[1]);
            }
            break;
        }
        return rule;
      })
    }
  }
}