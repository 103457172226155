<template>
  <div>
    <Modal v-model="showModal"
           class="dialog save_advance"
           :styles="{top: modalTop}"
           :scrollable="false"
           :mask-closable="false"
           width="770"
           :ok-text="'Save'"
           @on-ok="save">
      <div class="loading" v-show="loading">
        <Spin size="large" fix></Spin>
      </div>
      <div class="dialog-header" slot="header">
        <h2>{{modalTitle}}</h2>
      </div>
      <div class="dialog-contain">
        <div class="name-input-wrapper">
          <div class="name-text">Name</div>
          <div class="inputBox">
            <Input v-model.trim="groupName" placeholder="" class="formInput" @on-enter="save"></Input>
          </div>
          <div class="sharedBox">
            <Checkbox v-model="isShared">
              <span class="selectName">Shared Folder</span>
            </Checkbox>
          </div>
        </div>
        <div class="teammate-wrapper" v-show="isShared">
          <div class="teammate-info">
            {{checkGroup.length}} teammate(s) allowed to view this folder
          </div>
          <usersTable ref="usersTable"
                      :linesDate="linesDate"
                      :usersDate="usersDate"
                      :checkedGroup="checkedGroup"
                      v-if="!loading"
                      @updateCheckGroup="updateCheckGroup"></usersTable>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import usersTable from '../../components/userHybridTable.vue'
  import api from 'api'

  export default{
    props: {},
    data() {
      return {
        showModal: false,
        loading: true,
        butLoading: false,
        modalTitle: 'Save advanced search',
//--------------------------input
        groupName: '',
        isShared: false,
//--------------------------user table
        linesDate: [],//lines
        usersDate: [],//all users
        checkGroup: [],
        checkedGroup: []
      }
    },
    methods: {
      ...mapActions([
        'saveShipAdvance'
      ]),
//---------------------------显示
      show(){
        Object.assign(this.$data, this.$options.data());
        this.showModal = true;
        this.getUsers();
      },
      getUsers(){
        this.loading = true;
        const userId = this.userInfo.user.id;
        api.get_line_users().then((res) => {
          let lines = res.lines;
          let users = res.users;
          lines.forEach(item => {
            const userList = item.users;
            userList && userList.splice(userList.findIndex(item => item.id === userId), 1);
          });
          users.splice(users.findIndex(item => item.id === userId), 1);
          this.linesDate = lines;
          this.usersDate = users;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
//---------------------------更新checkGroup
      updateCheckGroup(arr){
        this.checkGroup = arr;
      },
      save(){//->shipping nav
        if (this.groupName === '') {
          this.$Message.error("The name cannot be empty");
          return
        }
        this.butLoading = true;
//        整理advance search 数据
        let advanceRules = [];
        const rules = this.shipAdvance.rules;
        let nameContent = '';
        for (let item of rules) {
//              ex_name/name 转换
          if (item.field === 'name') {
            nameContent = item.content;
          }
          if (item.field === 'ex_name' && !item.content) {
            item.content = ''
          }
          if (item.field === 'ex_name' && item.content) {
            item.content = nameContent
          }
//            content为空不传
          if (item.show && item.content !== '') {
            let {field, content} = item;
            advanceRules.push({field, content});
          }
        }
        let advance = {rules: JSON.stringify(advanceRules)};
        let isPublic = (this.$route.query.name === 'MarineTraffic');
//        参数
        const params = {
          ship_group: {
            'name': this.groupName,
            'group_type': 'dynamic',
//            'personal': !this.isShared,
            'public': this.isShared
          },
          'user_ids': this.checkGroup,
          ...advance,
        };
        if (!this.isShared) {
          delete params.user_ids
        }
        api.create_ship_group(params).then((res) => {
          this.$emit('updateGroup');
          this.showModal = false;
          this.butLoading = false;
        }).catch((error) => {
          this.butLoading = false;
          console.log(error);
        });

      },
    },
    computed: {
      ...mapGetters([
        'userInfo',
        'shipAdvance',
      ]),
      modalTop(){
        if (this.isShared) {
          return 'calc(50vh - 290px)'
        } else {
          return 'calc(50vh - 150px)'
        }
      },
    },
    watch: {},
    components: {
      usersTable
    },
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  .dialog {
    .dialog-header {
      h2 {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 16px;
      }
    }
    .dialog-contain {
      margin-top: 0;
      padding: 0;
      min-height: 120px;
      overflow: auto;
      overflow: overlay;
      background: var(--surface-color);
      color: var(--text-color);
      border: none;
      .name-input-wrapper {
        .name-text {
          font-size: 14px;
          font-weight: bold;
          color: #828D9D;
          margin-bottom: 2px;
        }
        .sharedBox {
          margin-top: 18px;
          margin-bottom: 16px;
          .selectName {
            font-weight: bold;
            font-size: 14px;
            margin-left: 8px;
          }
        }
      }
      .teammate-wrapper {
        .teammate-info {
          font-size: 14px;
          color: #828D9D;
          margin-bottom: 6px;
        }
      }
    }
  }

</style>

