<template>
  <Modal v-model="dialogShow"
         width="583" :styles="{top: 'calc(50vh - 280px)',marginRight:'calc(50% - 389px)'}"
         class="dialog select_filter_dialog"
         :closable="false"
         :mask-closable="true"
         :scrollable="false"
         :ok-text="'Apply filters'"
         @on-ok="applyFilter">
    <section>
      <div class="dialog_info">
        Advanced Search » Select filters
      </div>
      <div class="dialog-contain">
        <!---------------------left tab--------------------->
        <aside class="leftSide">
          <div class="tabContain" v-for="(item, index) in tabList" :key="index"
               @click="handleTab(item.value)"
               :class="{'active':selectedTab==item.value}">
            <div class="tabItem searchInput" v-if="item.value=='find'">
              <Input v-model.trim="searchQuery" icon="ios-search-strong" placeholder="Find..."
                     style="width: 100%"></Input>
            </div>
            <div class="tabItem" v-else>
              <span v-show="item.value!=='Tankers'&&item.value!=='Dry'">{{item.value}}</span>
              <span v-show="item.value=='Tankers'">Capacity<br>(Tankers)</span>
              <span v-show="item.value=='Dry'">Capacity<br>(Dry)</span>
            </div>
          </div>
        </aside>
        <!--------------------right select------------------>
        <aside class="rightSide">
          <!------------------------find----------------------->
          <div class="side_contain search_nothing" v-show="searchQuery==''&&selectedTab=='find'">
            Type in the search box to find a filter
          </div>
          <!------------------------default----------------------->
          <div class="side_contain default" v-show="showDefault">
            <p class="default_item" v-for="(item, index) in  defaultList" :key="index" v-show="selectedTab==item.tab">
              <span class="shape"><i class="iconfont ch-icon-Shape"></i></span>
              {{item.label}}
            </p>
          </div>
          <!------------------------select----------------------->
          <div class="filterTitle" v-show="selectedTab=='find'&&searchQuery!=''">
            {{filterList.length}} filters matched your search
          </div>
          <div class="side_contain select" v-show="!(selectedTab=='find'&&searchQuery=='')">
            <p class="select_item" v-for="(item, index) in filterList" :key="index"
               v-show="selectedTab==item.tab||(selectedTab=='find'&&searchQuery!='')">
              <!--<Checkbox-group v-model="checkGroup" size="large"-->
              <!--class="checkGroup">-->
              <!--<Checkbox :label="item.value">-->
              <!--<span class="selectName" v-html="item.label"></span>-->
              <!--&lt;!&ndash;<span class="selectName">{{item.label}}</span>&ndash;&gt;-->
              <!--</Checkbox>-->
              <!--</Checkbox-group>-->
              <Checkbox v-model="item.show" class="checkGroup">
                <span class="selectName" v-html="item.filterName"></span>
              </Checkbox>
            </p>
          </div>
        </aside>
      </div>
    </section>
  </Modal>
</template>
<script type="text/ecmascript-6">
  import api from '../../../../fetch/api.js';
  import util from 'util';
  import {mapGetters, mapState} from 'vuex';

  export default{
    props: {
//      email: {
//        type: Object,
//      },
//      stats: {
//        type: Object,
//      },
    },
    data() {
      return {
        dialogShow: false,
        searchQuery: '',
//        left side
        tabList: [
          {value: 'find'},
          {value: 'Identification'},
          {value: 'Tonnage'},
          {value: 'Hull'},
          {value: 'General'},
          {value: 'Ownership'},
          {value: 'Tankers'},
          {value: 'Dry'},
        ],
        selectedTab: 'Identification',
//        right contain
        checkGroup: [],
        defaultList: [
          {label: 'Name', value: 'name', tab: 'Identification'},
          {label: 'IMO', value: 'lrimo', tab: 'Identification'},
          {label: 'Ex Name', value: 'ex_name', tab: 'Identification'},
          {label: 'Status', value: 'ship_status', tab: 'Identification'},
          {label: 'Year of Build', value: 'year_of_build', tab: 'Identification'},
          {label: 'Type', value: 'type_level2', tab: 'Identification'},
          {label: 'Category', value: 'type_level5', tab: 'Identification'},
          {label: 'DWT', value: 'deadweight', tab: 'Tonnage'},
          {label: 'Owner', value: 'group_beneficial_owner', tab: 'Ownership'},
          {label: 'Grain', value: 'grain_capacity', tab: 'Dry'},
          {label: 'Bale', value: 'bale_capacity', tab: 'Dry'},
        ],
        selectList: [
//          Identification
          {label: 'Call Sign', filterName: 'Call Sign', value: 'call_sign', show: false, tab: 'Identification'},
          {label: 'Flag', filterName: 'Flag', value: 'flag_name', show: false, tab: 'Identification'},
          {label: 'Class', filterName: 'Class', value: 'classification_society', show: false, tab: 'Identification'},
          {
            label: 'ICE',
            filterName: 'ICE',
            value: 'ice_capability_descriptive_narrative',
            show: false,
            tab: 'Identification'
          },
//          Tonnage
          {label: 'GT', filterName: 'GT', value: 'gross_tonnage', show: false, tab: 'Tonnage'},
          {label: 'NT', filterName: 'NT', value: 'net_tonnage', show: false, tab: 'Tonnage'},
          {label: 'PCNT', filterName: 'PCNT', value: 'panama_cana_net_tonnage', show: false, tab: 'Tonnage'},
          {label: 'SCNT', filterName: 'SCNT', value: 'suez_canal_net_tonnage', show: false, tab: 'Tonnage'},
//          Hull
          {label: 'Depth', filterName: 'Depth', value: 'depth', show: false, tab: 'Hull'},
          {label: 'Draught', filterName: 'Draught', value: 'draught', show: false, tab: 'Hull'},
          {label: 'LOA', filterName: 'LOA', value: 'length_overall_loa', show: false, tab: 'Hull'},
          {label: 'LBP', filterName: 'LBP', value: 'length_between_perpendiculars_lbp', show: false, tab: 'Hull'},
          {label: 'BEAM', filterName: 'BEAM', value: 'breadth', show: false, tab: 'Hull'},
          {label: 'Speed', filterName: 'Speed', value: 'speed', show: false, tab: 'Hull'},
          {label: 'Hull Type', filterName: 'Hull Type', value: 'hull_type', show: false, tab: 'Hull'},
          {label: 'TPCI', filterName: 'TPCI', value: 'tonnes_per_centimetre_immersion_tpci', show: false, tab: 'Hull'},
          {label: 'Decks', filterName: 'Decks', value: 'number_of_decks', show: false, tab: 'Hull'},
//          General
          {
            label: 'Cargo Desc',
            filterName: 'Cargo Desc',
            value: 'cargo_capacities_narrative',
            show: false,
            tab: 'General'
          },
          {
            label: 'Gear Desc',
            filterName: 'Gear Desc',
            value: 'gear_descriptive_narrative',
            show: false,
            tab: 'General'
          },
//          Ownership
          {label: 'Manager', filterName: 'Manager', value: 'ship_manager', show: false, tab: 'Ownership'},
          {label: 'Operator', filterName: 'Operator', value: 'operator', show: false, tab: 'Ownership'},
          {label: 'Last Updated', filterName: 'Last Updated', value: 'last_update_date', show: false, tab: 'Ownership'},
//          Dry
          {label: 'Holds', filterName: 'Holds', value: 'number_of_holds', show: false, tab: 'Dry'},
          {
            label: 'Holds Desc',
            filterName: 'Holds Desc',
            value: 'holds_descriptive_narrative',
            show: false,
            tab: 'Dry'
          },
          {label: 'Hatches', filterName: 'Hatches', value: 'number_of_hatches', show: false, tab: 'Dry'},
          {
            label: 'Hatches Desc',
            filterName: 'Hatches Desc',
            value: 'hatches_descriptive_narrative',
            show: false,
            tab: 'Dry'
          },
          {label: 'Gearless', filterName: 'Gearless', value: 'gearless', show: false, tab: 'Dry'},
          {label: 'TEU', filterName: 'TEU', value: 'teu', show: false, tab: 'Dry'},
//          Tankers
          {label: 'No. of Tanks', filterName: 'No. of Tanks', value: 'number_of_tanks', show: false, tab: 'Tankers'},
          {
            label: 'Tanks Desc',
            filterName: 'Tanks Desc',
            value: 'tanks_descriptive_narrative',
            show: false,
            tab: 'Tankers'
          },
          {label: 'No. of Pumps', filterName: 'No. of Pumps', value: 'number_of_pumps', show: false, tab: 'Tankers'},
          {label: 'Tank (cbm)', filterName: 'Tank (cbm)', value: 'liquid_capacity', show: false, tab: 'Tankers'},
          {label: 'Tank Coated', filterName: 'Tank Coated', value: 'cargo_tank_coatings', show: false, tab: 'Tankers'},
          {label: 'Coat Desc', filterName: 'Coat Desc', value: 'tank_coatings_narrative', show: false, tab: 'Tankers'},
          {label: 'Slop (cbm)', filterName: 'Slop (cbm)', value: 'slop_tank_capacity', show: false, tab: 'Tankers'},
          {label: 'Slop Coated', filterName: 'Slop Coated', value: 'slop_tank_coatings', show: false, tab: 'Tankers'},
          {
            label: 'Special Tanks Desc',
            filterName: 'Special Tanks Desc',
            value: 'special_tanks_descriptive_narrative',
            show: false,
            tab: 'Tankers'
          },
          {label: 'Gas (cbm)', filterName: 'Gas (cbm)', value: 'gas_capacity', show: false, tab: 'Tankers'},
          {
            label: 'SBT (cbm)',
            filterName: 'SBT (cbm)',
            value: 'segregated_ballast_capacity',
            show: false,
            tab: 'Tankers'
          },
          {label: 'CBT (cbm)', filterName: 'CBT (cbm)', value: 'clean_ballast_capacity', show: false, tab: 'Tankers'},
          {label: 'COW', filterName: 'COW', value: 'crude_oil_washing_cow', show: false, tab: 'Tankers'},
          {label: 'IGS', filterName: 'IGS', value: 'inert_gas_system_igs', show: false, tab: 'Tankers'},
        ]
      }
    },
    created () {
    },
    methods: {
//-------------------------显示dialog
      show(form){
//        Object.assign(this.$data, this.$options.data());
        this.dialogShow = true;
        for (let item of form) {
          for (let updateItem of this.selectList) {
            if (updateItem.label == item.label) {
              updateItem.show = item.show
            }
          }
        }
      },
      hide(){
        this.dialogShow = false;
      },
//----------------------------------------init
      dataInit(){
      },
      searchInit(){
      },
//---------------------------------点击右侧Tab
      handleTab(val){
        this.selectedTab = val
        if (val !== 'find') {
          this.searchQuery = '';
        }
      },
//---------------------------------updateFilter
      applyFilter(){
        console.log(this.selectList);
        this.$emit('updateFilter', this.selectList);
        this.dialogShow = false;
      }
    },
    computed: {
//        default show
      showDefault(){
        if (this.selectedTab === 'Identification' || this.selectedTab === 'Tonnage' || this.selectedTab === 'Ownership' || this.selectedTab === 'Dry') {
          return true
        }
      },
//-------------------------------------动态过滤
      filterList: function () {
//        深拷贝
//        let list = this.selectList.slice(0);
//        list = list.map((e) => {
//          if (typeof e === 'object') {
//            return Object.assign({}, e)
//          } else {
//            return e
//          }
//        });
        let list = this.selectList.slice(0);
        list.forEach(item => {
          item.filterName = item.label;
        });
        let searchKey = this.searchQuery && this.searchQuery.toLowerCase();
        if (this.searchQuery !== '') {
          let list2 = list.filter(function (item) {
            return item.filterName.toLowerCase().indexOf(searchKey.toLowerCase()) != -1;
          });
          return list2.map(item => {
            let filterName = item.filterName;
            let validation = new RegExp("(" + searchKey + ")", "i");
            let replaceLabel = validation.exec(filterName);
            if (replaceLabel) {
              item.filterName = filterName.replace(new RegExp("(" + searchKey + ")", "i"), "<span style='text-decoration-line: underline;font-weight: bold'>" + replaceLabel[0] + "</span>");
            }
            return item
          })
        } else {
          return list
        }
      }
    },
    watch: {
      'searchQuery'()
      {

      }
      ,
    },
    components: {}

  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  /*结构*/
  .select_filter_dialog {
    section {
      position: relative;
      height: 547px;
      background: var(--surface-color);
      color: var(--text-color);
      .dialog_info {
        height: 40px;
        width: 100%;
        line-height: 40px;
        color: #fff;
        font-size: 14px;
        background: var(--primary-color);
        padding-left: 18px;
      }
      .dialog-contain {
        display: flex;
        margin-top: 0;
        padding: 0;
        background-color: var(--surface-color);
        border-top: none;
        border-bottom: none;
        .leftSide {
          width: 133px;
          height: 507px;
          background: var(--component-color);
          box-shadow: inset -3px 0px 4px rgba(0, 0, 0, 0.05);
          .tabContain {
            font-size: 14px;
            color: var(--label-color);
            cursor: pointer;
            transition: all .2s;
            border-bottom: 1px solid var(--border-color);
            &.active {
              background: var(--primary-color);
              color: var(--on-primary-color);
            }
            .tabItem {
              padding: 11px 18px;
              &.searchInput {
                padding: 4px 2px;
              }
            }

          }
        }
        .rightSide {
          width: 450px;
          height: 454px;
          padding: 13px 26px;
          overflow: auto;
          overflow: overlay;
          .side_contain {
            width: 100%;
            &.search_nothing {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 426px;
              font-size: 18px;
              color: var(--primary-color);
            }
            &.default {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 30px;
              padding-top: 17px;
              border-top: 1px solid #ddd;
              .default_item {
                width: 50%;
                height: 19px;
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: bold;
                .shape {
                  display: inline-block;
                  font-size: 12px;
                  font-weight: lighter;
                  vertical-align: middle;
                  margin-right: 6px;
                  margin-left: -2%;
                  transform: scale(0.5);
                }

              }
            }
            &.select {
              display: flex;
              flex-wrap: wrap;
              padding-top: 19px;
              border-top: 1px solid #ddd;
              .select_item {
                width: 50%;
                margin-bottom: 15px;
                .checkGroup {
                  .selectName {
                    font-size: 14px;
                    color: #828D9D;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          .filterTitle {
            font-size: 16px;
            color: #666666;
          }
        }
      }
    }
  }


</style>
